import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Footer from '../Components/Footer';
import { GetAllNotificationHooks, ReadOneNotificationHooks } from '../Hooks/UserHooks';
import { GetUserCode } from '../Hooks/UseWallet';


function Notifications() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        fetchNotification()
    }, []);

    //dev-murugavel

    const [notification , setNotification] = useState([]);
    const [skip , setSkip] = useState(0);

    const fetchNotification = async() => {
        try{
            let payload = {
                userid : GetUserCode(),
            }
            let result = await GetAllNotificationHooks(payload);
            if(result?.type == "Success"){
                setNotification(result?.record);
            }
            
        }
        catch(e){
            console.log("Error on fetch notification" , e);
        }
    }

    const ReadOneNotification = async(id) => {
        try{
            let result = await ReadOneNotificationHooks({id : id});
            if(result?.type == "Success"){
                fetchNotification()
            }
        }
        catch(e){
            console.log("Error on read one notification" , e);
        }
    }

    const loadmore = async() => {
        try{
            let payload = {
                userid : GetUserCode(),
                skip : 0,
                limit : 10
            }
            let result = await GetAllNotificationHooks(payload);
            if(result?.type == "Success"){
                setSkip(parseInt(skip) + 10);
                setNotification(notification.concat(result?.record));
            }
        }
        catch(e){
            console.log("Error on loadore" , e);
        }
    }

    const GetTime = (date) => {
        let hours = new Date(parseInt(date)).getHours();
        let min = new Date(parseInt(date)).getMinutes();
        let noon = ""
        if(hours >= 12){
          noon = " PM"
        }
        else {
          noon = " AM";
        }
        return hours?.toString()?.padStart(2 , 0)+":"+min?.toString()?.padStart(2 , 0) + noon;
      }

    return (
        <>
            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages pb-5'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center mb-5'>NOTIFICATIONS</h2>
                        {notification?.map((data , i) => 
                            
                            <div className='messageborder rounded-3 mt-4 py-3 px-3'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    {/* <img src={Images.imgicon} className='micon me-1 me-sm-2' alt='image' /> */}
                                    <h5 className='whtclr poppins mb-0'>{data?.title}</h5></div>
                                    <div className='text-end'>
                                    <p className='mb-0 vioclr poppins'>{new Date(data?.createdAt)?.toLocaleDateString() +" "+ GetTime(data?.createdAt)}</p>
                                    {!data?.isRead  &&<span className='markread' onClick={() => ReadOneNotification(data?._id)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path d="M374.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 178.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l80 80c12.5 12.5 32.8 12.5 45.3 0l160-160zm96 128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 402.7 86.6 297.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l256-256z"></path></svg> Mark as read</span>}
                                    </div>
                               
                            </div>
                            <p className='whtclr poppins mb-0 mt-2'>{data?.description}</p>
                        </div>
                        )}

                        

                        {/* <div className='messageborder readback rounded-3 mt-4 py-3 px-3'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'><img src={Images.imgicon} className='micon me-1 me-sm-2' alt='image' />
                                    <h5 className='whtclr poppins mb-0'>John</h5></div>
                                <p className='mb-0 vioclr poppins'>3hrs ago</p>
                            </div>
                            <p className='whtclr poppins mb-0 mt-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                        </div> */}

                    </div>

                </div>

                <Footer />
            </Container>

        </>

    )
}

export default Notifications