import React, { useEffect, useState, useContext, useRef } from "react";
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { BsFillSendFill } from "react-icons/bs";
import { useLocation, useParams } from 'react-router-dom';
import { IoMdAttach } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import image1 from "../Assets/Images/attach.png";
import SocketContext from "../Context/SocketContext";
import { getSingleSupportInfo, getAdminInfo } from "../Hooks/contactus-supportHooks";

import config from '../Config/env'
import { GetUserCode } from "../Hooks/UseWallet";
import { JoinRoom, SendSupportMessage } from "../Config/SocketConnectivity";

const Detailhistory = () => {

  // const location = useLocation();

  // const detail = location.state?.detail;

  const socketContext = useContext(SocketContext);

  const { id } = useParams();

  console.log("DETAIL", id);

  const [details, setDetails] = useState({
    assetName: '',
    createdAt: '',
    image: '',
    imagePath: '',
    refundAmount: '',
    refundStatus: '',
    status: '',
    supportMessage: '',
    title: '',
    transactionHash: '',
    type: '',
    updatedAt: '',
  })

  const userId = GetUserCode();

  const [messages, setMessages] = useState([]);

  const [chat, setChat] = useState("");

  const [errors, setErrors] = useState("");

  const [image, setImage] = useState();

  const [supportImg, setSupportImg] = useState();

  const [adminId, setAdminId] = useState("");
  const chatContainerRef = useRef(null);

  useEffect(() => {

    JoinRoom(id);

    socketContext.socket.on('NEWSUPPORTMESSAGE', (data) => {
      console.log("DATA---", data?.messages);
      setMessages(data?.messages)
    })

  }, [])


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });


    // setMessages(detail.messages);

    getSupportInfo();

    getAdminId();




  }, []);


  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }

  }, [messages]);




  const getAdminId = async () => {

    try {

      var result = await getAdminInfo();

      if (result.status) {
        setAdminId(result.data._id);
      }

      console.log('Admin', result);

    } catch (e) {
      console.log(e);
    }

  }

  const getSupportInfo = async () => {

    try {

      let payload = {
        id: id,
      }

      const result = await getSingleSupportInfo(payload);

      console.log("REST", result.data[0].messages);

      if (result.status) {
        // setDetails(result.data);

        setDetails((prev) => ({
          ...prev,
          assetName: result.data[0].assetName,
          createdAt: result.data[0].createdAt,
          image: result.data[0].image,
          imagePath: result.data[0].imagePath,
          refundAmount: result.data[0].refundAmount,
          refundStatus: result.data[0].refundStatus,
          status: result.data[0].status,
          supportMessage: result.data[0].supportMessage,
          title: result.data[0].title,
          transactionHash: result.data[0].transactionHash,
          type: result.data[0].type,
          updatedAt: result.data[0].updatedAt,
        }));
        setMessages(result.data[0].messages)
      }



    } catch (e) {
      console.log(e);
    }

  }


  const handleChatSupport = () => {

    if (chat !== "" || image) {
      const payload = {
        from: GetUserCode(),
        to: adminId,
        message: chat,
        supportid: id,
        image: image ? image : '',
      };

      console.log("__PAY", payload);

      setChat("");

      setImage(null);
      setSupportImg(null);

      SendSupportMessage(payload);
    }

    else {
      setErrors("Chat can't be Empty");
    }

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default action (e.g., form submission)
      handleChatSupport(); // Call the chat support function
    }
  };

  const isValidImageType = (file) => {
    const validTypes = ['image/png', 'image/jpeg'];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    e.target.value = null; // Clear the file input value
    if (file) {
      if (isValidImageType(file)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImage(file);
          setSupportImg(reader.result); // Set the image data URL in the state
          setErrors(""); // Clear errors if file is valid
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        setErrors("Upload Only PNG and JPG Image");
      }
    }
  };

  const handleImageClose = () => {
    setImage(null);
    setSupportImg(null);
  }
  return (
    <>
      <Container fluid className="p-0 common_bg">
        <Header />
        <div className="innerpages pb-3">
          <div className="container">
            <h2 className="whtclr vendin text-center pt-5 pt-sm-0 mb-4 mb-sm-5">History</h2>

            <div className="row">
              <div className="col-lg-5 col-xl-5 col-xxl-3 mt-4">
                <h4 className="whtclr vendin mb-3">Support Details</h4>
                <div className="instrctborder history rounded-3 px-3 py-4">
                {details.type &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Type
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.type}
                      </p>
                    </div>
                  }
                  {details.assetName &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Asset Name
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.assetName}
                      </p>
                    </div>
                  }


                  {details.supportMessage &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Support
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.supportMessage}
                      </p>
                    </div>
                  }

                  {details.updatedAt &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Time Stamp
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.updatedAt}
                      </p>
                    </div>
                  }
                  {details.status &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Status
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.status}
                      </p>
                    </div>
                  }
                  {details.refundStatus &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Refund Status
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.refundStatus}
                      </p>
                    </div>
                  }

                  {details.refundAmount &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Refund Amount
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.refundAmount}
                      </p>
                    </div>
                  }
                  {details.transactionHash &&
                    <div>
                      <p className="whtclr poppins mb-1 violettext">
                        Transaction Hash
                      </p>
                      <p className="whtclr poppins mb-0">
                        {details.transactionHash}
                      </p>
                    </div>
                  }
                </div>
              </div>
              <div className="col-lg-7 col-xl-7 col-xxl-9 mt-4">
                <h4 className="whtclr vendin mb-3">Chat</h4>
                <div className='py-2 px-2 chatbg' >
                <div className='backchatbg px-2 py-3' ref={chatContainerRef}>

                  {messages.map((data, i) => (

                    <>

                      {data.image &&

                        <div className={data.from === userId ? 'viotxtimg py-4 px-3 mt-2 ms-auto d-flex align-items-center justify-content-center ' : 'blacktxtimg py-4 px-3 mt-2 me-auto d-flex align-items-center justify-content-center'}>
                          <a href={config.API_URL + data.image} target="_blank">
                            <img src={config.API_URL + data.image} className='img-fluid wimg' />
                          </a>
                        </div>

                      }
                      {data.message &&

                        <div className={data.from === userId ? 'viotxtimg py-4 mt-2 px-3 ms-auto' : ' blacktxtimg py-4 pe-3 mt-2 px-3 me-auto'}>
                          <p className="whtclr poppins mb-0">
                            {data.message}
                          </p>
                        </div>

                      }
                    </>

                  ))}

                  {/* <div className="viotxtimg py-4 px-3 ms-auto mt-4">
                    <p className="whtclr poppins pe-3 pe-sm-5 mb-0">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div> */}
</div>
{supportImg &&
                  <div className='mt-3 mb-1 pos widthsets'>
                    <img src={supportImg} className='img-fluid himg1' />
                    <IoClose className='closesvg cursor' onClick={handleImageClose} />
                  </div>
                }

                <div className="pt-2">
                  <div class="input-group mb-3">

                    <div className='pos attach'>
                      <input type='file' className='form-control setimg' onChange={handleFileChange} />
                      {/* <button class="btn allbtn allbtns setattach" type="button" id="button-addon2"> */}
                        <IoMdAttach />
                      {/* </button> */}
                    </div>

                    <input
                      type="text"
                      className="form-control msg_input poppins"
                      placeholder="Message"
                      value={chat}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        setChat(e.target.value);
                        setErrors("");
                      }}
                    />
                    <button
                      className="btn btn-secondary sendbtn"
                      type="button"
                      id="button-addon2"
                      onClick={handleChatSupport}
                    >
                      <BsFillSendFill />
                    </button>
                  </div>
                  {errors !== "" && <p className='text-danger fs-6'>{errors}</p>}
                </div>
                </div>
              
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default Detailhistory;
