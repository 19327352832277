import axios from "axios"
import config from "../Config/env";

import { SocialMediaApi } from "../Routes/UserRoutes";

export const getSocialMediaList = async () => {
    try{
        let result = await axios.get(config?.API_URL+SocialMediaApi)
        
        return result?.data;
    }
    catch(e){
        console.log("Error on register new user hooks" , e);
    }
}