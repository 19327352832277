import axios from "axios"
import config from "../Config/env";

import store from "../Redux/Store/Store";
import { DecryptString, EncryptString, GenerateSecretKey } from "./Authenticate";
import { FilterGameDetailListApi, GetAllGameItemListApi, GetGameDetailListApi, GetSingleGameDetailApi, GetSingleGameItemApi, GetSingleOrderDetailApi, GetgameItemListApi, OwnerApproveorRejectApi, UpdateContactaddressApi, UpdateOrderStatusApi, UpdateOrderwithdrawStatusApi, UserAvialableBalanceApi } from "../Routes/GameRoutes";
import { RestoreQuantityApi } from "../Routes/UserRoutes";

export const GetGameItemHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetgameItemListApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const GetSingleGameItemHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetSingleGameItemApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const GetGameDetailListHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetGameDetailListApi,{params : data});
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const FilterGameDetailListHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+FilterGameDetailListApi,{params : data});
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const GetSingleGameDetailHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetSingleGameDetailApi,{params : data});
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}



export const GetAllGameItemHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetAllGameItemListApi);
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const OwnerApproveorRejectHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+OwnerApproveorRejectApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const GetSingleOrderDetailHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetSingleOrderDetailApi , {params : data});
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const UpdateContractAddressHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+UpdateContactaddressApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const RestoreQuantityHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+RestoreQuantityApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const UpdateOrderStatusHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+UpdateOrderStatusApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const UpdateOrderWithdrawStatusHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+UpdateOrderwithdrawStatusApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}


export const GetUserAvialableBalanceHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+UserAvialableBalanceApi , {params : data});
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}