import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Accordion, Button, Col, Container, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Nofificationmodal from '../Components/notificationmodal';
import Footer from '../Components/Footer';
import Approvemodal from "../Components/approvemodal";
import { GetSingleGameItemHooks } from '../Hooks/GameHooks';
import { GetDeliveryMethodHooks } from '../Hooks/UserHooks';
import { ValidateFile } from '../Validation/UserValidation';
import { AddNewGameItems } from '../Hooks/UploadGameItems';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { GetUserAcccount, GetUserCode } from '../Hooks/UseWallet';
import { GetDeployfee } from '../Hooks/UseContract';

function Uploadimg() {
    const userdata = useSelector((state) => state?.User?.data[0]);

    const [imgerror, setImgerror] = useState({});
    const [selectfilter, setSelectfilter] = useState({ name: "Auto Delivery" });
    const [pricefilter, setPricefilter] = useState([
        { name: "Auto Delivery" }, { name: "Manual Delivery" }
    ]);
    const [filess, setFiless] = useState();

    const [image, setImage] = useState();


    const isValidImageType = (file) => {
        const validTypes = ['image/png', 'image/jpeg'];
        return validTypes.includes(file.type);
    };
    
    const isValidImageSize = (file) => {
        const maxSizeInBytes = 500 * 1024; // 500 KB
        return file.size <= maxSizeInBytes;
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        e.target.value = null; // Clear the file input value
    
        if (file) {
            if (!isValidImageType(file)) {
                setErrors((prev) => ({
                    ...prev,
                    Image: "Upload Only PNG and JPG Image"
                }));

                return; // Exit the function early if the type is invalid
            }
    
            if (!isValidImageSize(file)) {
                setErrors((prev) => ({
                    ...prev,
                    Image: "Image size should be less than or equal to 500 KB"
                }));
                return; // Exit the function early if the size is invalid
            }
    
            // If both type and size are valid, read the file
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(file);
                setFiless(reader.result); // Set the image data URL in the state
                setErrors({ ...errors, Image: "" }); // Clear errors if file is valid
            };
            reader.readAsDataURL(file); // Read the file as a data URL
        }
    };
    

    // function handleChange(e) {
    //     console.log(e.target.files);
    //     let validate = ValidateFile(e?.target?.files[0]);
    //     if (validate) {

    //     }
    //     setErrors({ ...errors, Image: "" });
    //     setImage(e.target.files[0])
    //     setFiless(URL.createObjectURL(e.target.files[0]));
    // }


    const [approvemodal, setApprovemodal] = useState(false);
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);



    //dev-murugavel
    const [category, setCategory] = useState("");
    const [deliverymethodlist, setDeliverymethodlist] = useState([]); //{ name: "Digital Code" }, { name: "Cooridinated Transfer" }
    const [selecteddeliverymethod, setSelecteddeliverymethod] = useState({}); //name: "Digital Code" 
    const [itemdetail, setItemdetail] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState("");
    const [deliverydays, setDeliverydays] = useState(0);
    const [percentage , setPercentage] = useState(0);

    const [errors, setErrors] = useState({});
    const [initialdata, setInitialdata] = useState({
        name: "",
        description: "",
        price: "",
        itemdetail: "",
        quantity: null
    })
    // deliverydays: null,
    useEffect(() => {
        console.log(" window?.location?.href?.split", window?.location?.href?.split("/")[4]);
        if (window?.location?.href?.split("/")[4]) {
            fetchdata();
        }
    }, [])

    const fetchdata = async () => {
        let result = await GetSingleGameItemHooks({ id: window?.location?.href?.split("/")[4] })
        if (result?.type == "Success") {
            setCategory(result?.record);
        }
        let deliver = await GetDeliveryMethodHooks();
        if (deliver?.type == "Success") {
            setDeliverymethodlist(deliver?.record);
            setSelecteddeliverymethod(deliver?.record[0]);
        }
        let percent = await GetDeployfee();
        setPercentage(percent);
    }

    console.log("category", category);

    const HandleSubmit = async () => {

        let error = {};
        let pricepercent = parseFloat(initialdata.price) + ((parseFloat(initialdata.price)/100)*parseFloat(percentage))
        console.log("price" , pricepercent?.toString()?.split(".")[1]?.length , pricepercent?.toString() , pricepercent?.toString()?.split(".") , pricepercent);
        if (initialdata.name == "")
            error.name = "Name Required";

        if (initialdata.description == "")
            error.description = "Description Required"

        if (!initialdata.price)
            error.price = "Price Required"

        if(initialdata.price && initialdata.price <= 0)
            error.price = "Invalid Price"

        // if (!initialdata.itemdetail)
        //     error.itemdetail = "Game Item Required"

        if(pricepercent?.toString()?.split(".")[1]?.length > 7){
            error.price = "Price is Very low"
        }

        if (selectfilter.name == "Manual Delivery" && !initialdata.deliverydays)
            error.deliverydays = "Delivery Days Required"

        if (!initialdata.quantity)
            error.quantity = "Quantity Required"

        if(initialdata.quantity && initialdata.quantity <= 0)
            error.quantity = "Invalid Quantity"

        if (!filess)
            error.Image = "Image Required"


        setErrors(error);

        try {
            if (Object.keys(error).length == 0) {

                let payload = {
                    category: category.name,
                    category_id: category._id,
                    name: initialdata.name,
                    gameDescription: initialdata.description,
                    price: initialdata.price,
                    createrId: userdata._id,
                    image: image,
                    gameDetail: initialdata.itemdetail,
                    quantity: initialdata.quantity,
                    chainId: "",
                    createrwallet: GetUserAcccount()
                }

                // deliveryMethod: selecteddeliverymethod?.name,
                // deliveryType: selectfilter.name,
                // deliveryDays: initialdata.deliverydays,

                var resp = await AddNewGameItems(payload);

                if (resp?.status) {
                    toast.success(resp.msg)
                    setApprovemodal(true);
                }
                else return toast.error(resp.msg)

                console.log("PAYload", resp);
            }
        }
        catch (e) {
            console.log("Error on handle submit", e);
        }
    }

    const OnChange = (e) => {
        try {
            let { name, value } = e?.target;
    
            console.log("ITEME ", name, value);
    
            let filteredvalue;
    
            if (name === "quantity" || name === "deliverydays") {
                // Allow only numbers or empty strings
                filteredvalue = value.replace(/[^0-9]/g, '');
            } 
            else if (name === "price") {
                // Allow numbers and decimal points
                filteredvalue = value.replace(/[^0-9.]/g, '');
                // Ensure only one decimal point is allowed
                if (filteredvalue.split('.').length > 2) {
                    filteredvalue = filteredvalue.slice(0, -1);
                }
            } 
            else {
                filteredvalue = value;
            }
            
            setInitialdata({ ...initialdata, [name]: filteredvalue });
        } catch (e) {
            console.log("Error on on change", e);
        }
    };
    

    const mod = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            ['image', 'code-block'],
        ],
    }


    return (

        <>
            {approvemodal && <Approvemodal onDismiss={() => setApprovemodal(false)} />}
            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='container'>
                        <h3 className='whtclr vendin pt-5 pt-sm-0'>Upload</h3>
                        <p className='whtclr poppins'>PNG, JPG and MAX 500kb</p>

                        <div className='row pb-5'>
                            <div className='col-lg-6 col-xxl-5 mt-4'>
                                {filess ?
                                    <div className='dragupload py-4 px-4 text-center'>
                                        <input type="file" className='imginput' onChange={handleFileChange} />
                                        <img src={filess ? filess : Images.cardimg} className='img-fluid image_card w-100' />
                                    </div>
                                    :
                                    <div className='dragupload py-4 px-4 h-100 text-center'>
                                        <input type="file" className='imginput' onChange={handleFileChange} />
                                        <img src={Images.folder} className='img-fluid' />
                                        <h4 className='lightvio fw-300 poppins mt-3'>Upload Files</h4>
                                        <h3 className='lightvio fw-600 poppins'>Drag your items to upload</h3>
                                        <p className='lightvio poppins'>PNG, JPG and MAX 500kb</p>
                                    </div>
                                }
                                {errors.Image && <p className='text-danger fs-6'>{errors.Image}</p>}
                            </div>
                            <div className='col-lg-6 col-xxl-7 mt-4'>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Category</h6>
                                    <input type='text' autoComplete='off' className='form-control g_input supportinput poppins' placeholder='Category' value={category?.name} />
                                </div>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Name of the game item</h6>
                                    <input type='text' autoComplete='off' className='form-control g_input supportinput poppins' placeholder='Game name'
                                        value={initialdata?.name}
                                        name="name"
                                        onChange={(e) => {
                                            OnChange(e)
                                            setErrors({ ...errors, name: "" })
                                        }}
                                    />
                                    {errors.name && <p className='text-danger fs-6'>{errors.name}</p>}
                                </div>
                                {/* <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Delivery Method</h6>
                                    <Dropdown className='upload_drop pos'>
                                        <DropdownToggle className="w-100 text-start poppins">{selecteddeliverymethod?.name}</DropdownToggle>
                                        <DropdownMenu className='w-100 poppins'>
                                            {deliverymethodlist?.map((e, i) =>
                                                <DropdownItem onClick={() => setSelecteddeliverymethod(e)}>{e?.name}</DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Delivery Type</h6>
                                    <Dropdown className='upload_drop'>
                                        <DropdownToggle className="w-100 text-start poppins">{selectfilter.name}</DropdownToggle>
                                        <DropdownMenu className='w-100 poppins'>
                                            {pricefilter.map((e, i) =>
                                                <Dropdown.Item onClick={() => setSelectfilter(e)}>{e.name}</Dropdown.Item>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                {selectfilter.name == "Manual Delivery" && <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Delivery Days</h6>
                                    <input type='text' min={0} className='form-control g_input supportinput poppins' placeholder='Description'
                                        value={initialdata?.deliverydays}
                                        name="deliverydays"
                                        onChange={(e) => {
                                            OnChange(e)
                                            setErrors({ ...errors, deliverydays: "" })
                                        }}

                                    />
                                    {errors.deliverydays && <p className='text-danger fs-6'>{errors.deliverydays}</p>}
                                </div>} */}

                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Game Description</h6>
                                    <input type='text' autoComplete='off' className='form-control g_input supportinput poppins' placeholder='Description'
                                        value={initialdata?.description}
                                        name="description"
                                        onChange={(e) => {
                                            OnChange(e)
                                            setErrors({ ...errors, description: "" })
                                        }}

                                    />
                                    {errors.description && <p className='text-danger fs-6'>{errors.description}</p>}
                                </div>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Price</h6>
                                    <input type='text' autoComplete='off' className='form-control g_input supportinput poppins' placeholder='Enter Price'
                                        min={0}
                                        value={initialdata?.price}
                                        name="price"
                                        onChange={(e) => {
                                            OnChange(e)
                                            setErrors({ ...errors, price: "" })
                                        }}

                                    />
                                    {errors.price && <p className='text-danger fs-6'>{errors.price}</p>}
                                </div>

                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Selling Quantity</h6>
                                    <input type='text' autoComplete='off' min={0} className='form-control g_input supportinput poppins' placeholder='Enter quantity'
                                        value={initialdata?.quantity}
                                        name="quantity"
                                        onChange={(e) => {
                                            OnChange(e)
                                            setErrors({ ...errors, quantity: "" })
                                        }}

                                    />
                                    {errors.quantity && <p className='text-danger fs-6'>{errors.quantity}</p>}
                                </div>

                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Game Detail</h6>
                                    {/* <ReactQuill theme="snow" name="itemdetail" value={initialdata.itemdetail}                                     onChange={(e) => {
                                        OnChange(e)
                                        setErrors({...errors, itemdetail: ""})
                                    }}
                                    /> */}

                                    <ReactQuill theme="snow"
                                        // modules = {mod}
                                        value={initialdata.itemdetail} onChange={(e) => {
                                            // setItemdetail(e)
                                            setInitialdata({ ...initialdata, itemdetail: e })
                                            // if (e) {
                                            //     setErrors({ ...errors, ["detail"]: "" })
                                            // }
                                            // else {
                                            //     setErrors({ ...errors, ["detail"]: "Field is required" })
                                            // }
                                        }} />
                                </div>
                                {/* {errors.itemdetail && <p className='text-danger fs-6'>{errors.itemdetail}</p>} */}
                                <button className='header__connectBtn rounded-3 poppins px-5 fw-600 mt-2' onClick={() => {
                                    HandleSubmit()
                                    // setApprovemodal(true)
                                }}>Sell Now</button>

                            </div>

                        </div>


                    </div>

                </div>

                <Footer />

            </Container>


        </>

    )
}

export default Uploadimg;