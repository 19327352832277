import {useWallets} from '@privy-io/react-auth';
import Web3 from "web3"
import { RegisterNewUserHooks } from './UserHooks';


export const ConnectPrivy = (data) => {
    try{console.log("Walletdata" , data);
        if(!GetUserAcccount() && data?.length > 0){
            localStorage.setItem("WalletKey" , data[0]?.address?.toString());
            localStorage.setItem("WalletType" , data[0]?.walletClientType);
            // window.location.reload();
        }
        let payload = {
            walletaddress : data[0]?.address?.toString(),
            userid : GetUserCode()
          }
        let register =  RegisterNewUserHooks(payload).then((res) => {
            console.log("register result" , register);
          });
        
    }
    catch(e){
        console.log("Error on connect privy" , e);
    }
}

export const DisconnectPrivy = () => {
    try{
        localStorage.removeItem("WalletKey");
        localStorage.removeItem("WalletType");
        window.location.reload();
    }
    catch(e){
        console.log("Error on disconnect privy" , e);
    }
}

export const GetUserAcccount = () => {
    try{
        let user = localStorage.getItem("WalletKey");
        if(user){
            return user;
        }
        else{
            return "";
        }
    }
    catch(e){
        console.log("Error on get user account" , e);
    }
}

export const UseWeb3 = async(data) => {
    try{console.log("provider" , data);
        if(data){
            
            // const wallet = data; 
            // const provider = await wallet.getEthereumProvider();
            const web3 = new Web3(data);
            return web3;
        }
        else{
            const web3 = new Web3(window.ethereum);
            return web3;
        }
    }
    catch(e){
        console.log("Error on UseWeb3" , e);
    }
}

export const SwitchChain = async() => {
    try{}
    catch(e){
        console.log("Error on switch chain" , e);
    }
}

export const GetChain = () => {
    if(localStorage.getItem("CHAIN")){
        return localStorage.getItem("CHAIN");
    }
    else{
        localStorage.setItem("CHAIN" , "0");
        return 0
    }
}

export const SetChain = (data) => {
    localStorage.setItem("CHAIN" , data);
}

export const GetUserEmail = () => {
    return localStorage.getItem("email") ?  localStorage.getItem("email") : ""
}

export const GetUserCode = () => {
    return localStorage.getItem("usercode") ? localStorage.getItem("usercode") : ""
}


export const GetBalance = async(data) => {
    try {
        const web3 = new Web3(data) ;//UseWeb3(data);
        console.log("datadata" , data);
        const account = GetUserAcccount();
        console.log("amount", account);

        if (account) {
            const balance = await web3.eth.getBalance(account);
            console.log("balance",balance );
            return parseInt(balance) / 10 ** 18;
        }
        else {
            return null;
        }
    }
    catch(e){
        console.log("Error on get balance" , e);
    }
}

export const Logout = () => {
    localStorage.clear("email");
    localStorage.clear("usercode");
    localStorage.clear("WalletKey")
}