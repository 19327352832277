const BASE_URL = "contactus-support";
const Admin_URL = "admin"
 
export const AddContactusApi = BASE_URL + "/add-contactus";

export const CreateSupport = BASE_URL + "/create-new-support";

export const GetSupportList = BASE_URL + "/get-user-support-list";

export const GetSingleSupportInfo = BASE_URL + "/get-support-info";

export const GetChatHistoryList = BASE_URL + "/get-chathistory-list";

export const GetAdminId = Admin_URL + '/getAdmin';