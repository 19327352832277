import {React, useState} from "react";
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";

const CancelModal = (props) => {

    const [canmodal, setCanmodal] = useState(true);

  return (
    <>
      <Modal
        show={canmodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="py-5 px-4">
          <span className="closebtn" onClick={() => props.onDismiss()}>
            <IoCloseSharp />
          </span>

          <h3 className="vendin whtclr text-center">
            Do you want to cancel the buy request ?
          </h3>
          <div className="d-flex justify-content-center gap-3 flex-wrap mt-3">
            <button
              className="header__connectBtn rounded-2 vendin"
              onClick={() => props.onDismiss()}
            >
              Cancel
            </button>
            <button
              className="header__connectBtn rounded-2 vendin"
              onClick={() => props.onDismiss()}
            >
              Confirm
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CancelModal;
