import React, { useState, useEffect, useContext, useRef } from 'react';
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Countdown from 'react-countdown';
import StepProgressBar from 'react-step-progress';
import 'react-step-progress/dist/index.css';
import Stepprogress from '../Components/stepprogress';
import { BsFillSendFill } from "react-icons/bs";
import Footer from '../Components/Footer';
import { Link, useLocation } from 'react-router-dom';
import Stepper from '../Components/stepper';
import Timemodal from '../Components/timemodal';
import { GetSingleOrderDetailHooks, RestoreQuantityHooks } from '../Hooks/GameHooks';
import { CHAINS } from '../Config/Network.';
import { GetChain, GetUserCode } from '../Hooks/UseWallet';
import SupportModal from '../Components/SupportModal';
import SocketContext from '../Context/SocketContext';
import { IoMdAttach } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { CreateRoom, JoinRoom, Refresh, SendMessage } from '../Config/SocketConnectivity';
import config from "../Config/env";
import { CreateGame, Expandtime, GetGameInfo, UserRaiseRefund } from '../Hooks/UseContract';
import { GetUserdataHooks } from '../Hooks/UserHooks';
import { MdInfo } from "react-icons/md";
import Instrctmodal from '../Components/instmodal';
import { useWallets } from '@privy-io/react-auth';


function Chating() {
    const { wallets } = useWallets();
    const[chat , setChat] = useState([]);
    const socketContext = useContext(SocketContext);
    const location = useLocation();
    {console.log("asasd",location);}     
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            fetchorderdetail()
            // Render a completed stateYou are good to go
            return <span>Expired!</span>;
        } else {
            // Render a countdown
            return <span className='redclr'>{hours}H : {minutes}M : {seconds}S</span>;
        }
    };
    const [timesmodal, setTimesmodal] = useState(false);
    const [openstep, setOpenstep] = useState(false);
    const[instmodals, setInstmodals] = useState(false);
    const [acknowledge , setAcknowledge] = useState(false);

    const chatContainerRef = useRef(null);
    useEffect(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    
      }, [chat]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });


        fetchorderdetail();
    }, []);



    //dev-murugavel
    const [orderdetails , setOrderdetails] = useState({});
    const [ordergamedetails , setOrdergamedetails] = useState({});
    const [supportmodal , setSupportModal] = useState(false);
    const [newmessage , setNewmessage] = useState("");
    const [image , setImage] = useState("");
    const [imageblob , setImageblob] = useState("");
   
    const [saledetail , setSaledetail] = useState();
    const [opponent , setOpponent] = useState({});

    // const Getuserdata = async() => {
    //     if(GetUserCode()){
    //       let result = await GetUserdataHooks({id : GetUserCode()});
    //     }
    //   }


    
    const fetchorderdetail = async() => {
        try{
            let payload = {
                id : window.location.href?.split("/")[4]
            }
            let result = await GetSingleOrderDetailHooks(payload);
            if(result?.type == "Success"){
                setOrderdetails(result?.record?.orderdetail);
                setOrdergamedetails(result?.record?.ordergamedetail);
                setChat(result?.record?.orderdetail?.message);
                setOpponent(result?.record?.oppdetail);
                if(result?.record?.orderdetail?.contractAddress){
                    setOpenstep(true);
                    fetchsaledetail(result?.record?.orderdetail?.contractAddress);
                }
                //socket join
                let payload = {
                    roomid : result?.record?.orderdetail?.roomId, 
                }
                JoinRoom(result?.record?.orderdetail?.roomId);
            }

        }
        catch(e){
            console.log("Error on fetch order detail" , e);
        }
    }

    const fetchsaledetail = async(address) => {
        let result = await GetGameInfo(address);
        console.log("result in fetch sale" , result);
        setSaledetail(result);
    }


    //socket
    useEffect(() => {
        socketContext.socket.on('NEWMESSAGE', (newmessage) => {console.log("REceive new messge");
            setNewmessage("");
            setImage("");
            setImageblob("");
            // setTradechat(newmessage);
            setChat(newmessage?.message)
        });

        socketContext.socket.on('REFRESHUI' , (data) => {
            if(data == GetUserCode())
                fetchorderdetail();
        })

    } , [socketContext]);


    const handleSend = () =>{
        if(newmessage || image){
          let payload = {
            from : GetUserCode(), //user?.userId,
            to : orderdetails?.createrID == GetUserCode() ? orderdetails?.buyerId : orderdetails?.createrID,
            message : newmessage,
            time : Date.now()?.toString(),
            roomid : orderdetails?.roomId,
            image : image
          };
          SendMessage(payload);
        }
      } 

    const Cretesale = async() => {
        setAcknowledge(true);
        const wallet = wallets[0];
        const provider = await wallet.getEthereumProvider();
        console.log("inside provider" , provider);
        let address = [orderdetails?.buyerWallet , orderdetails?.createrWallet , "0x7cC8781E4A67C3997AEA6174D42bC9bdE8aa07C7"]
        let result = await CreateGame(ordergamedetails?.price , orderdetails?._id , address , provider);
        console.log("create game result" , result);
        if(result?.type == "Success"){
            setOpenstep(true) 
            let data = orderdetails?.createrID == GetUserCode() ?  orderdetails?.buyerId : orderdetails?.createrID
            Refresh(data);
            fetchorderdetail()
        }
    }

    const handleRequestRefund = async() => {
        try{
            const wallet = wallets[0];
            const provider = await wallet.getEthereumProvider();
            let result = await UserRaiseRefund(saledetail?._gameAddress ,provider)
            let data = orderdetails?.createrID == GetUserCode() ?  orderdetails?.buyerId : orderdetails?.createrID
            Refresh(data);
            fetchorderdetail()
            if(result){
                let res = await RestoreQuantityHooks({id : ordergamedetails?._id})
            }
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }

    const handleExpandtime = async() => {
        try{
            const wallet = wallets[0];
            const provider = await wallet.getEthereumProvider();
            let result = await Expandtime(saledetail?._gameAddress , provider)
            if(result){
                let data = orderdetails?.createrID == GetUserCode() ?  orderdetails?.buyerId : orderdetails?.createrID
                fetchsaledetail(saledetail?._gameAddress);
                Refresh(data);
            }
            
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }






    return (

        <>
         {timesmodal && <Timemodal onDismiss={()=>setTimesmodal(false)}/>}
         {supportmodal && <SupportModal 
         gamedetail = {ordergamedetails}
         orderdetail = {orderdetails} type="chat" onDismiss={() => {
                setSupportModal(false)
            }} />}
            {instmodals && <Instrctmodal onDismiss={() => {
                setInstmodals(false)
            }} />}
            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages pb-3'>
                    <div className='container'>
                        {/* <h2 className='whtclr vendin text-center mb-3'>Chatting</h2> */}
                        {/* {location?.state?.data != "no" &&  */}
                        {saledetail?._expiry && <p className='whtclr poppins text-end'>Time left : <Countdown date={parseFloat(saledetail?._expiry)*1000} renderer={renderer} /></p>}
                        {/* } */}

                        <div className='row'>
                            {location?.state?.data != "no" && 
                            <div className='col-lg-5 col-xl-4 col-xxl-3 mt-4'>
                                <h4 className='whtclr vendin mb-3'>user instruction<MdInfo className='cursor fillcolor ms-1' onClick={()=>setInstmodals(true)}/> </h4>
                               
                                <div className='instrctborder rounded-3 px-3 py-4'>
                                <ul className='inst mb-0'>
                                <li><p class="label poppins">Title</p><p class="value poppins">{ordergamedetails?.name}</p></li>
                                <li><p class="label poppins">Category</p><p class="value poppins">{ordergamedetails?.category} </p></li>
                                <li><p class="label poppins">Price</p><p class="value poppins">{ordergamedetails?.price} {CHAINS[GetChain()]?.nativeCurrency.symbol}</p></li>
                                    </ul>


                                        {openstep ?
                                            <div className='assetback'>
                                                <Stepper orderdetail={orderdetails} ordergamedetail={ordergamedetails}
                                                    saledetail={saledetail} refresh={fetchorderdetail}
                                                />
                                            </div> :
                                            <></>}


                                    {/* <p className='whtclr poppins'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        <br /> <br />
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    </p> */}
                                    <div className='d-flex gap-1 justify-content-center align-items-center'>
                                        <button className='header__connectBtn raisebtn rounded-3 poppins mt-3 px-2' onClick={() => setSupportModal(true)}><img src={Images.raise} className='img-fluid me-1' />Raise Dispute</button>
                                        {!openstep && orderdetails?.buyerId == GetUserCode() && <button className='header__connectBtn rounded-3 poppins px-2 mt-3 ms-2 ms-sm-0' 
                                        disabled = {openstep || acknowledge}
                                        onClick={() => Cretesale()}><img src={Images.knowledge} className='img-fluid me-1' />I Acknowledged</button>}

                                    {openstep && orderdetails?.buyerId == GetUserCode() || saledetail?.isRefund &&  <button className='header__connectBtn rounded-3 poppins px-2 mt-3 ms-2 ms-sm-0' 
                                        disabled = {saledetail?.isTransferredAsset || saledetail?.isRefund}
                                        onClick={() => handleRequestRefund()}><img src={Images.knowledge} className='img-fluid me-1' />Request to Refund</button>}
                                    </div>
                                </div>
                                {!saledetail?.isWithdraw && <button className='header__connectBtn rounded-3 poppins mt-4 px-3' onClick={handleExpandtime}>Expand Time 30 Mins</button>}
                            </div>
                          } 
                            <div className='col-lg-7 col-xl-8 col-xxl-9 mt-4 mx-auto'>

                                <div className='d-flex align-items-center pb-3'>
                                    <img src={
                                        orderdetails?.createrID == GetUserCode() ?  opponent?.buyerId?.profileImage ? config.API_URL +opponent?.buyerId?.profilePath+opponent?.buyerId?.profileImage 
                                        : Images.pimg : 
                                        ordergamedetails?.createrId?.profileImage ? config.API_URL +ordergamedetails?.createrId?.profilePath+ ordergamedetails?.createrId?.profileImage : Images.pimg

                                    } className='micon me-1 me-sm-2' alt='image' />
                                    <div>
                                        {console.log("ordergamedetails" , ordergamedetails)}
                                        <h5 className='whtclr poppins mb-1'>{
                                    orderdetails?.createrID == GetUserCode() ?  opponent?.buyerId?.name : ordergamedetails?.createrId?.name
                                    }</h5>
                                        <p className='whtclr poppins mb-0'></p>
                                    </div>
                                </div>

                                <div className='py-2 px-2 chatbg'>

                                <div className='backchatbg px-2 py-3' ref={chatContainerRef}>
                                    {chat && chat?.length > 0 && chat?.map((data , i) => {
                                        if(data?.from != GetUserCode()){
                                            return(<div className='blacktxtimg py-4 px-3 me-auto mt-2'>
                                                <img src={Images.curvebimg} className='img-fluid curveimgs'/>
                                                {data?.image && <a href = {data?.image ? config?.API_URL +"/"+ data?.image : ""} target="_blank">
                                                <img src={data?.image ? config?.API_URL +"/"+ data?.image : ""} className='img-fluid himg'/></a> }
                                                <p className='whtclr poppins ps-1 mb-0'>{data?.message}</p>
                                            </div>)
                                        }
                                        else if(data?.from == GetUserCode()){
                                            return(<div className='viotxtimg py-4 px-3 ms-auto mt-2'>
                                                  <img src={Images.curvevimg} className='img-fluid curvevimgs'/>
                                               {data?.image && <a href = {data?.image ? config?.API_URL +"/"+ data?.image : ""} target="_blank">
                                               <img src={data?.image ? config?.API_URL +"/"+ data?.image : ""} className='img-fluid himg'/></a> }
                                                <p className='whtclr poppins pe-3 pe-sm-5 mb-0'>{data?.message}</p>
                                            </div>)
                                        }
                                    })}
                                    
                                    
                                    {/* <div className='blacktxtimg py-4 px-3 me-auto mt-4'>
                                        <p className='whtclr poppins ps-3 ps-sm-5 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    <div className='viotxtimg py-4 px-3 ms-auto mt-4'>
                                        <p className='whtclr poppins pe-3 pe-sm-5 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>
                                    <div className='blacktxtimg py-4 px-3 me-auto mt-4'>
                                        <p className='whtclr poppins ps-3 ps-sm-5 mb-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div> */}
                                     </div>
                                     {imageblob && <div className='mt-3 mb-3 pos widthsets'>
                                  <img src={imageblob ? imageblob : ""} className='img-fluid himg1'/>
                                  <IoClose className='closesvg cursor' onClick={() => {
                                    setImage("");
                                    setImageblob("");
                                  }}/>
                                 </div>}

                                {/* {location?.state?.data != "no" &&  */}
                               {saledetail ? (parseFloat(saledetail?._expiry)*1000) > Date.now() && ordergamedetails?.status == "New" &&  <div className='pt-0 mt-3'>
                                    <div class="input-group mb-3">
                                        <div className='pos attach'>
                                        <input class="btn btn-secondary sendbtn fzs" type="file" id="button-addon2"
                                        onChange ={(e)=>{
                                            setImage(e?.target?.files[0]); 
                                            setImageblob(URL.createObjectURL(e?.target?.files[0]))}}
                                        />
                                        <IoMdAttach />
                                            </div>
                                   

                                        <input type="text" class="form-control msg_input poppins" 
                                        value={newmessage}
                                        placeholder="Message" onChange={(e) => setNewmessage(e?.target?.value)} />
                                        <button class="btn btn-secondary sendbtn" type="button" id="button-addon2" onClick={handleSend}><BsFillSendFill /></button>
                                    </div>
                                </div> :   
                                

                                <div className='pt-0 mt-2'>
                                    <div class="input-group mb-3">
                                        <div className='pos attach'>
                                        <input class="btn btn-secondary sendbtn fzs" type="file" id="button-addon2"
                                        onChange ={(e)=>{
                                            setImage(e?.target?.files[0]); 
                                            setImageblob(URL.createObjectURL(e?.target?.files[0]))}}
                                        />
                                        <IoMdAttach />
                                            </div>
                                   

                                        <input type="text" class="form-control msg_input poppins" 
                                        value={newmessage}
                                        placeholder="Message" onChange={(e) => setNewmessage(e?.target?.value)} />
                                        <button class="btn btn-secondary sendbtn" type="button" id="button-addon2" onClick={handleSend}><BsFillSendFill /></button>
                                    </div>
                                </div>
                                
                                }

                                </div>

                              


                                {/* } */}


                            </div>
                        </div>

                        {/* {openstep ?
                            <div className='assetback mt-5 mb-5 py-5 px-4'>
                 
                                <Stepper orderdetail = {orderdetails} ordergamedetail = {ordergamedetails} 
                                    saledetail = {saledetail} refresh = {fetchorderdetail}
                                />
                            </div> :
                            <></>} */}

                    </div>

                </div>

                <Footer />

            </Container>

        </>

    )
}

export default Chating;