import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function Approvemodal(props) {
    const [approvemodal, setApprovemodal] = useState(true);
    const navigate = useNavigate();

    return (
        <div>
            <Modal show={approvemodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>

                    {props.action == "Delete" ?
                        <>
                            <h3 className='vendin whtclr text-center'>Are you sure you want to delete this item?</h3>

                            <div className='text-center mt-3'>
                                <button className='header__connectBtn rounded-2 vendin' onClick={props.deletefn} >Yes</button>
                                <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => props.onDismiss()}>No</button>
                            </div>
                        </>


                        :

                        <>
                            <span className='closebtn' onClick={() => { navigate("/profile"); props.onDismiss() }}><IoCloseSharp /></span>

                            <h3 className='vendin whtclr text-center'>Awaiting Super Admin Approval</h3>

                            <div className='text-center mt-3'>
                                <button className='header__connectBtn rounded-2 vendin' onClick={() => {
                                    navigate("/profile");
                                    props.onDismiss();
                                }}>Ok</button>
                                {/* <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => props.onDismiss()}>Close</button> */}
                            </div>
                        </>

                    }





                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Approvemodal;
