import { React, useState } from 'react';
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { Images } from "../Components/Images";
import ReactStars from "react-rating-stars-component";
import CancelModal from '../Components/CancelModal';
import { Link } from 'react-router-dom';
import { PiSealCheckFill } from "react-icons/pi";
import EditCardModal from './EditCardModal';
import { TiCancel } from "react-icons/ti";
import { toast } from 'react-toastify';

import { FaRegClock } from "react-icons/fa";

import { ChangeGameStatus, ChangeUserOrderStatus } from '../Hooks/UserHooks';

import config from '../Config/env'
import { CHAINS } from '../Config/Network.';
import { GetChain, GetUserCode } from '../Hooks/UseWallet';
import { MdOutlinePendingActions } from "react-icons/md";
import { toFixedNumber } from '../Hooks/UseContract';

import Approvemodal from './approvemodal';

function Cardlist(props) {

    const [canmodal, setCanmodal] = useState(false);
    const [delmodal, setDelmodal] = useState(false);


    console.log("Card Details12", props);//props?.datas?.createrId?.review?

    const values = props?.datas;

    const data = {
        id: values?._id,
        approve: values?.approveStatus,
        name: values?.name,
        // ratings: 3,
        price: values?.price,
        quantity: values?.quantity,
        isDeleted: values?.isDeleted,
        Image: `${config?.API_URL}` + values?.imagePath + values?.image
    }

    const gameData = {
        Image: `${config?.API_URL}` + values?.gameId?.imagePath + values?.gameId?.image,
        name: values?.gameId?.name,
        id: values?._id,
    }

    const handleDeleteOk = async () => {
        setDelmodal(true);
    }

    const handleStateChange = async () => {
        
        setDelmodal(false);

        try {
            const payload = {
                id: data.id,
                isDeleted: "true"
            }

            const resp = await ChangeGameStatus(payload);

            if (resp?.status) {
                toast.success(resp.msg);
                props.onItemDelete(data.id);
                // props.fn();
            } else {
                toast.error(resp.msg);
            }
        } catch (e) {
            console.log("Error on handle approve", e);
        }


    }

    console.log("gameData", gameData);

    const handleApproveChange = async (status) => {

        console.log("appdata", status);

        try {
            const payload = {
                id: values?._id,
                ownerAccepted: status
            }

            const resp = await ChangeUserOrderStatus(payload);

            if (resp?.status) {
                toast.success(resp.msg);
                props.onItemDelete(gameData?.id);
                // props.fn();
            } else {
                toast.error(resp.msg);
            }
        } catch (e) {
            console.log("Error on handle approve", e);
        }
    }

    return (


        <>
            {canmodal && <CancelModal onDismiss={() => setCanmodal(false)} />}
            {delmodal && <Approvemodal action={"Delete"} deletefn={handleStateChange}  onDismiss={() => setDelmodal(false)} />}

            <div className='cardlist'>
                {
                    props.content !== "listing" ?
                        <div className='text-center'>
                            <Link to={`/carddetail/${values._id}`}>
                                {props.content == "yourorder" ?
                                    <img src={gameData.Image} className='img-fluid cardimg' alt='image' />
                                    :
                                    <img src={data.Image} className='img-fluid cardimg' alt='image' />
                                }
                            </Link>
                        </div> :
                        <>
                            <div className='verifybox'>
                                {props.content == "yourorder" ?
                                    <a href={gameData.Image} target='_blank'>
                                        <img src={gameData.Image} className='img-fluid cardimg' alt='image' />
                                    </a>
                                    :
                                    <a href={data.Image} target='_blank'>
                                        <img src={data.Image} className='img-fluid cardimg' alt='image' />
                                    </a>

                                }
                                {data.approve == "Approved" &&
                                    <span className='verifyicon'><PiSealCheckFill /></span>
                                }
                                {data.approve == "Rejected" &&
                                    <span className='verifyicon reject'><TiCancel /></span>
                                }
                                {data.approve == "Pending" &&
                                    <span className='verifyicon pending'><MdOutlinePendingActions /></span>
                                }
                            </div>
                        </>
                }

                <div className={(props.explore == "explore" || props.content == "yourorder") ? 'mt-4' : 'mt-4 d-flex align-items-center justify-content-between'}>
                    {props.explore == "explore" ?
                        <div>
                            <Link to={`/carddetail/${values._id}`}>
                                <h5 className='whtclr fw-600 poppins'>{data.name}</h5>
                            </Link>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <p className='whtclr poppins mb-0'>{data?.price?.toString()?.split('.')[1]?.length > 10 ? parseFloat(data.price)?.toFixed(10) : data.price} {CHAINS[GetChain()]?.nativeCurrency.symbol}</p>
                                </div>
                                <div>
                                    {console.log("data in regawgdgfasdfgsadgsagas", props?.datas)}
                                    <ReactStars value={props?.datas?.createrId?.review?.reduce((accumulator, currentValue) => accumulator + currentValue?.rating, 0,) / props?.datas?.createrId?.review?.length} count={5} size={24} edit={false} isHalf={true} activeColor="#FFBB0D" />
                                </div>
                            </div>
                        </div> :
                        <div>
                            <Link to={`/carddetail/${values._id}`}>
                                {props.content == "yourorder" ?

                                    <h5 className='whtclr fw-600 poppins'>{gameData?.name}</h5>
                                    :
                                    <h5 className='whtclr fw-600 poppins'>{data.name}</h5>
                                }
                                {/* <h5 className='whtclr fw-600 poppins'>{data.name}</h5> */}
                            </Link>
                            <div>
                                <p className='whtclr poppins mb-0'>{parseFloat(data.price)?.toFixed(10)} {CHAINS[GetChain()]?.nativeCurrency.symbol}</p>
                                <ReactStars value={props?.datas?.createrId?.review?.reduce((accumulator, currentValue) => accumulator + currentValue?.rating, 0,) / props?.datas?.createrId?.review?.length} count={5} size={24} edit={false} isHalf={true} activeColor="#FFBB0D" />
                            </div>
                        </div>
                    }
                    {props.content == "yourorder" &&
                        <div className='mt-2'>
                            <button type='button' className='header__connectBtn rounded-4 poppins' onClick={() => handleApproveChange("Approve")}>Approve</button>
                            <button type='button' className='header__connectBtn rounded-4 poppins ms-2' onClick={() => handleApproveChange("Rejected")}>Cancel</button>
                        </div>
                    }
                    {
                        props.explore == "explore" && GetUserCode() ? props?.datas?.createrId?._id != GetUserCode()?
                        <button type='button' className='header__connectBtn rounded-4 poppins mt-2'>Buy Now</button> :
                        <button type='button' className='header__connectBtn rounded-4 poppins mt-2'>View</button> :
                        <button type='button' className='header__connectBtn rounded-4 poppins mt-2'>Buy Now</button>
                    }
                </div>
                {
                    props.content == "listing" &&
                    <>

                        {data.isDeleted == "false" &&
                            <>

                                {data.approve == "Approved" &&
                                    <div className='d-flex justify-content-between align-items-center mt-3'>
                                        {/* <Link to="/carddetail" className='header__connectBtn rounded-3 poppins w-50 me-3 d-block text-center'>Edit</Link> */}
                                        <button type='button' className='header__connectBtn rounded-3 poppins w-50' onClick={handleDeleteOk}>Delete</button>
                                    </div>

                                }
                                {data.approve == "Rejected" &&
                                    <div className='mt-3'>
                                        <Link to={`/carddetail/${values._id}`} className='header__connectBtn rounded-3 poppins w-50 d-block text-center'>Edit</Link>
                                    </div>
                                }
                                {/* {data.approve == "Pending" &&
                            <div className='mt-3'>
                                <Link to="/carddetail" className='header__connectBtn rounded-3 poppins w-50 d-block text-center'>Edit</Link>
                            </div>
                        } */}
                            </>
                        }
                    </>
                }
            </div>
        </>



    )
}

export default Cardlist;