import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import ReactStars from "react-rating-stars-component";
import { GetUserCode } from "../Hooks/UseWallet";
import { AddReviewHooks, EditReviewHooks } from "../Hooks/UserHooks";
import { toast } from "react-toastify";
import StarRatings from 'react-star-ratings';




const Reviewmodal = (props) => {

  const[reviewmodals, setReviewmodals] = useState(true);

  
  //dev-murugavel
  const [rating , setRating] = useState(0);
  const [comment , setComment] = useState("");
  const [ref , setRef] = useState("")

  useEffect(() => {
    if(props?.data){
      setRating(props?.data?.rating);
      setComment(props?.data?.comment);
      setRef(props?.data?.ref);
    }
  } , [props?.data])


  const ratingChanged = (newRating) => {
    console.log(newRating , "rating");
    setRating(newRating)
  };

  const HandleAddreview = async() => {
    try{
      let payload = {
        userid : GetUserCode(), 
        rating : rating , 
        comment : comment, 
        vendorid : props?.vendorid
      }
      let result = await AddReviewHooks(payload);
      if(result?.type == "Success"){
        toast.success("Review Posted Successfully");
        props.fn();
        props.onDismiss();
      }
    }
    catch(e){
      console.log("Error on handle add review" , e);
    }
  }

  const HandleEditreview = async() => {
    try{
      let payload = {
        // id : GetUserCode(), 
        rating : rating , 
        cmd : comment, 
        id : props?.vendorid,
        ref : ref
      }
      let result = await EditReviewHooks(payload);
      if(result?.type == "Success"){
        toast.success("Review Edited Successfully");
        props.fn();
        props.onDismiss();
      }
    }
    catch(e){
      console.log("Error on handle add review" , e);
    }
  }

    return (
        <>
          <Modal
            show={reviewmodals}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
              <Modal.Body className="py-5 px-4">
          <span className="closebtn" onClick={() => props.onDismiss()}>
            <IoCloseSharp />
          </span>

          <h3 className="vendin whtclr text-center">
            Review
          </h3>

           <div className="mb-3">
            <h5 className="whtclr">Rating *</h5>
           {/* <ReactStars value={rating} count={5} size={24} edit={true} isHalf={false} activeColor="#FFBB0D" 
           onChange={ratingChanged}
           
           /> */}
              <StarRatings
                rating={rating}
                starRatedColor="gold"
                numberOfStars={5}
                name='rating'
                starDimension="20px"
                starSpacing="5px"
                changeRating={ratingChanged}
              />

           </div>

           <div>
           <h5 className="whtclr">Comment</h5>
            <textarea className="form-control g_input supportinput" rows={4} value={comment}
            onChange={(e) => setComment(e?.target?.value)}
            ></textarea>
           </div>

           <div className="d-flex align-item-center justify-content-center">
            {props?.data && <button className="header__connectBtn rounded-3 px-3 vendin mt-4 me-2" 
             disabled = {!rating}
            onClick={HandleEditreview}>Edit</button>}
             {!props?.data && <button className="header__connectBtn rounded-3 px-3 cursor vendin mt-4" 
             disabled = {!rating}
            // disabled
             onClick={HandleAddreview}>Post</button>}
           </div>


          </Modal.Body>
      </Modal>
    </>
  );
};

export default Reviewmodal;