import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import { IoSearch } from "react-icons/io5";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { GetGameItemHooks } from "../Hooks/GameHooks";

import config from "../Config/env";


function Gamelist() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        fetchdata();
    }, []);
    const [gamelistimg, setGamelistimg] = useState([])

    //dev-murugavel
    const [skip , setSkip] = useState(0);
    const [limit , setLimit] = useState(10);
    const [count , setCount] = useState(0);
    const [search , setSearch] = useState(0);

    const [searchstatus , setSearchstatus] = useState(false);
    const [searchcount , setSearchcount] = useState(0);
    const [searchgame , setSearchgame] = useState([]);
    const [searchskip , setSearchskip] = useState(0);

    const [initialloading , setInitialloading] = useState(false);
    const [loadmoreloading , setLoadmoreloading] = useState(false);
    

    const fetchdata = async() => {
        try{
            setInitialloading(true)
            let result = await GetGameItemHooks({skip : 0 , limit : 10});
            if(result?.type == "Success"){
                setGamelistimg(result?.record);
                setCount(result?.count);
                setSkip(10);
                setInitialloading(false)
            }
        }
        catch(e){
            console.log("Error on fetchdata" , e);
        }
    }

    const Loadmore = async() => {
        try{
            setLoadmoreloading(true)
            let data = {skip : skip , limit : 10};
            let result = await GetGameItemHooks(data);
            if(result?.type == "Success"){
                setGamelistimg(gamelistimg?.concat(result?.record));
                setSkip(skip + limit);
                setLoadmoreloading(false)
            }
        }
        catch(e){
            console.log("Error on load more" , e);
        }
    }

    const Handlesearch = async(e) => {
        try{
            if(e?.target?.value?.length > 0){
                setSearchstatus(true)
                setSearch(e?.target?.value)
                // if (e.key === 'Enter'){
                    setInitialloading(true)
                    let data = {
                        search : e?.target?.value,
                        skip : 0,
                        limit : 10
                    }
                    let result = await GetGameItemHooks(data);
                    if(result?.type == "Success"){
                        setSearchgame(result?.record);
                        setSearchcount(result?.count);
                        setSearchskip(10);
                        setInitialloading(false)
                    }
                // }
            }
            else{
                setSearchstatus(false);
            }
        }
        catch(e){
            console.log("Error on handle search" , e);
        }
    }

    const SearchLoadmore = async() => {
        try{
            setLoadmoreloading(true)
            let data = {
                search : search,
                skip : searchskip,
                limit : 10
            }
            let result = await GetGameItemHooks(data);
            if(result?.type == "Success"){
                setSearchgame(searchgame.concat(result?.record));
                setSearchcount(result?.count);
                setSearchskip(10);
                setLoadmoreloading(false)
            }
        }
        catch(e){
            console.log("Errror on search loadmore" , e);
        }
    }



    return (

        <>
          
            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center mb-5'>Select Game</h2>

                        <div className="row">
                            <div className="col-md-9 col-lg-8 col-xl-6 col-xxl-4 ms-auto pos">
                                <input type="search" className="form-control gamesearch poppins" placeholder="search" onChange={(e) => Handlesearch(e)}/>
                                <IoSearch className="searches" />
                            </div>
                        </div>
                        {!searchstatus && <>
                        <div className="gridall pt-5 pb-5">
                            {gamelistimg.map((e, i) =>
                            <Link to={`/upload/${e?._id}`}>
                                <div className="gameborder py-3 text-center">
                                    <img src={config.API_URL + e?.imagePath + e?.image} className="img-fluid gameimg" alt="image" />
                                    <h6 className="whtclr vendin mt-2 mt-sm-3 mb-2">{e?.name}</h6>
                                </div>
                                </Link>
                            )}

                            {initialloading && <p>Loading...</p>}
                        </div>

                        {count > 0  && count > gamelistimg?.length &&<div className="text-center pb-5">
                            <button className='header__connectBtn rounded-3 poppins px-4 fw-600 mt-2' 
                            onClick={Loadmore}
                            >{loadmoreloading ? "Loading..." : "Load More"}</button>
                        </div>}
                        </>}


                        {searchstatus && <>
                        <div className="gridall pt-5 pb-5">
                            {searchgame.map((e, i) =>
                            <Link to="/upload">
                                <div className="gameborder py-3 text-center">
                                    <img src={config.API_URL + e?.imagePath + e?.image} className="img-fluid gameimg" alt="image" />
                                    <h6 className="whtclr vendin mt-2 mt-sm-3 mb-2">{e?.name}</h6>
                                </div>
                                </Link>
                            )}
                            {initialloading && <p>Loading...</p>}
                        </div>

                        {searchcount > 0  && searchcount > searchgame?.length &&<div className="text-center pb-5">
                            <button className='header__connectBtn rounded-3 poppins px-4 fw-600 mt-2'
                            onClick={SearchLoadmore}
                            >{loadmoreloading ? "Loading..." : "Load More"}</button>
                        </div>}
                        </>}

                    </div>

                </div>

                <Footer />
            </Container >

        </>
    )
}

export default Gamelist;