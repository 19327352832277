// import package
import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SocketContext from '../Context/SocketContext';
import { RemoveSocketUser, createSocketUser } from '../Config/SocketConnectivity';


const HelperRoute = () => {
    const dispatch = useDispatch();
    const socketContext = useContext(SocketContext);

    const userdata = useSelector((state) => state?.User?.data[0]);
    

    // useEffect(() => {
    //     getMedia(dispatch)
    //     if (!(currencyOption && currencyOption.length > 0)) {
    //         getCurrency(dispatch);
    //     }

    //     socketContext.socket.on('notice', (result) => {
    //         FetchunReadNotice(dispatch, result)
    //     })

    //     socketContext.socket.on('FORCE_LOGOUT', (result) => {
    //         let token = localStorage.getItem('user_token');
    //         if(result && token != result){
    //             logout(history, dispatch)
    //         }
    //     })
    // }, [])

    useEffect(() => {
        if(userdata?._id){
            createSocketUser(userdata?._id)
        }
    } , [userdata])

    useEffect(() => {
       console.log(document.visibilityState , "document.visibilityState");
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
            //   setUserActive();
            } else { 
                if(userdata?._id){
                    RemoveSocketUser(userdata?._id);
                }
            }
          };
      
          const handleBeforeUnload = () => {
            if(userdata?._id){
                RemoveSocketUser(userdata?._id);
            }
          };
      
          document.addEventListener('visibilitychange', handleVisibilityChange);
          window.addEventListener('beforeunload', handleBeforeUnload);
          window.addEventListener('blur', handleBeforeUnload);
      
          // Cleanup event listeners on component unmount
          return () => {
            RemoveSocketUser(userdata?._id);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('blur', handleBeforeUnload);
          };
    } , [document.visibilityState])


    // useEffect(() => {
        
    // } , [])

    return <div/>
}

export default HelperRoute;