import React, { useState, useEffect, useRef } from 'react';
import { useWallets } from '@privy-io/react-auth';
import { useSelector } from 'react-redux';
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import { MdCameraAlt } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import Verifymodal from '../Components/verifymodal';
import Changemodals from '../Components/changemodal';
import Changenamemodals from '../Components/changenamemodal';
import { IoSearchSharp } from "react-icons/io5";
import Orders from '../Components/orders';
import Cardlist from '../Components/cardlist';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import ChatHistoryTable from '../Components/ChatHistoryTable';
import { IoNotifications } from "react-icons/io5";
import { GetBalance, GetChain, GetUserAcccount, GetUserCode } from '../Hooks/UseWallet';
import { CHAINS } from '../Config/Network.';
import { ValidateFile } from '../Validation/UserValidation';
import { toast } from 'react-toastify';
import { GetNotificationHooks, RegisterUserEmailHooks, UpdateUserdataHooks, GetGameItemHooks, GetOrderItemHooks } from '../Hooks/UserHooks';
import config from "../Config/env";
import { GetUserAvialableBalanceHooks } from '../Hooks/GameHooks';

function Profile() {
    const userdata = useSelector((state) => state?.User?.data[0]);
    const { wallets } = useWallets();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [file, setFile] = useState();
    const [file1, setFile1] = useState();

    const [verifymodal, setVerifymodal] = useState(false);
    const [changemodal, setChangemodal] = useState(false);
    const [activeTab, setActiveTab] = useState('listing');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const [changenamemodal, setChangenamemodal] = useState(false);

    const [cardlisted, setCardlisted] = useState([
        { id: "1", img: Images.cardimg, approve: "approved", ratings: 3.5 },
        { id: "2", img: Images.cardimg1, approve: "rejected", ratings: 5 },
        { id: "3", img: Images.cardimg2, approve: "approved", ratings: 4.5 },
        { id: "4", img: Images.cardimg3, approve: "approved", ratings: 3 },
        { id: "5", img: Images.cardimg1, approve: "rejected", ratings: 3 },
    ]);

    const [gameList, setGameList] = useState([]);
    const [orderList, setOrderList] = useState([]);

    const skip = useRef(0);
    const limit = useRef(10);
    const [add, setAdd] = useState(10); //as like limit
    const [count, setCount] = useState(0);

    //Buyer Request
    const skip1 = useRef(0);
    const limit1 = useRef(10);
    const [add1, setAdd1] = useState(10); //as like limit
    const [count1, setCount1] = useState(0);


    const [listingsearch, setListingsearch] = useState('');
    const [balance, setBalance] = useState(0);
    const [expired, setExpired] = useState(0);
    const [notification, setNotification] = useState([]);
    const [sitebalance , setSitebalance] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        Getnotify();
        if (wallets[0]) {
            Getbal();
        }
    }, [wallets]);

    useEffect(() => {
        GetGameItemDetails();
        AvialableBalance();
        GetOrderList();

    }, []);

    const Getbal = async () => {
        const wallet = wallets[0];
        const provider = await wallet.getEthereumProvider();
        let bal = await GetBalance(provider);
        setBalance(bal);
    }

    const handleChange = async (e) => {
        let validate = ValidateFile(e?.target?.files[0]);
        if (validate) {
            toast.error(validate);
        } else {
            setFile(URL.createObjectURL(e.target.files[0]));
            let formdata = new FormData();
            formdata.append("email", userdata?.email);
            formdata.append("cover", e?.target?.files[0])
            let result = await UpdateUserdataHooks(formdata);
            if (result?.type === "Success") {
                toast.success("Cover Image Changed Successfully!");
            } else if (result?.type === "Failed") {
                toast.error(result?.message);
            }
        }
    }
    const handleChange1 = async(e) => {
        console.log(e.target.files);
        let validate = ValidateFile(e?.target?.files[0]);
        if (validate) {
            toast.error(validate);
        }
        else {
            setFile1(URL.createObjectURL(e.target.files[0]));

            let formdata = new FormData();
            formdata.append("email", userdata?.email);
            formdata.append("prof", e?.target?.files[0])
            let result = await UpdateUserdataHooks(formdata);
            if (result?.type === "Success") {
                toast.success("Profile Changed Successfully!");
            } else if (result?.type === "Failed") {
                toast.error(result?.message);
            }
        }
    }

    const Getnotify = async () => {
        try {
            let result = await GetNotificationHooks({ userid: GetUserCode() });
            if (result?.type === "Success") {
                setNotification(result?.record);
            }
        } catch (e) {
            console.log("error on get notify", e);
        }
    }

    const GetGameItemDetails = async () => {
        try {
            console.log("Fetching game items with skip:", skip.current, "and limit:", limit.current);
    
            const payload = {
                userid: GetUserCode(),
                skip: skip.current,
                limit: limit.current,
            };
    
            const result = await GetGameItemHooks(payload);
    
            console.log("API Result:", result);
    
            if (result?.type === "Success") {
                console.log("Data fetched:", result?.data);
                console.log("Total count from API:", result?.count);
    
                setGameList(prevList => {
                    console.log("Previous gameList:", prevList);
                    const newList = [...prevList, ...result?.data];
                    console.log("Updated gameList:", newList);
                    return newList;
                });
                setCount(result?.count);
            } 
            // else {
            //     toast.error("Error fetching game items.");
            // }
        } catch (error) {
            console.error("Error fetching game items:", error);
        }
    };

    const GetOrderList = async () => {
        try {
            console.log("Fetching game items with skip:", skip1.current, "and limit:", limit1.current);
    
            const payload = {
                userid: GetUserCode(),
                skip: skip1.current,
                limit: limit1.current,
            };
    
            const result = await GetOrderItemHooks(payload);
    
            console.log("API Result:Order", result);
    
            if (result?.type === "Success") {
                console.log("Data fetched:", result?.data);
                console.log("Total count from API:", result?.count);
    
                setOrderList(prevList => {
                    console.log("Previous gameList:", prevList);
                    const newList = [...prevList, ...result?.data];
                    console.log("Updated gameList:", newList);
                    return newList;
                });

                // setOrderList(prevList => [...prevList, ...result?.data]);

                setCount1(result?.count);
            } else {
                // toast.error("");
            }
        } catch (error) {
            console.error("Error fetching game items:", error);
        }
    }
    

    const updateFunction = () => {
        GetGameItemDetails();
    }

    const handleItemDelete = (itemId) => {
        setGameList(prevList => prevList.filter(item => item._id !== itemId));
    };

    const handleOrderDelete = (itemId) => {
        setOrderList(prevList => prevList.filter(item => item._id !== itemId));
    };


    console.log("countskip", count, skip.current, limit.current);

    const loadmore = async () => {
        console.log("Loading more items. Current skip:", skip.current, "Current limit:", limit.current);
    
        setAdd(prev => prev + 10);
        skip.current += 10;
    
        console.log("New skip:", skip.current, "New limit:", limit.current);
    
        const payload = {
            userid: GetUserCode(),
            skip: skip.current,
            limit: limit.current,
        };
    
        try {
            const result = await GetGameItemHooks(payload);
    
            if (result?.type === "Success") {
                setGameList(prevList => [...prevList, ...result?.data]);
                setCount(result?.count);
            } 
            // else {
            //     toast.error("Error fetching game items.");
            // }
        } catch (error) {
            console.error("Error fetching game items:", error);
        }
    };

    
//Buyer Request
    const loadmore1 = async () => {
        console.log("Loading more items. Current skip:", skip.current, "Current limit:", limit.current);
    
        setAdd1(prev => prev + 10);
        skip1.current += 10;
    
        console.log("New skip:", skip1.current, "New limit:", limit1.current);
    
        const payload = {
            userid: GetUserCode(),
            skip: skip1.current,
            limit: limit1.current,
        };
    
        try {
            const result = await GetOrderItemHooks(payload);
    
            console.log("API Result:Order", result);
    
            if (result?.type === "Success") {
                console.log("Data fetched:", result?.data);
                console.log("Total count from API:", result?.count);
                
                setOrderList(prevList => [...prevList, ...result?.data]);

                setCount1(result?.count);
            } 
            // else {
            //     toast.error("Error fetching game items.");
            // }
        } catch (error) {
            console.error("Error fetching game items:", error);
        }
    };
    

    const handleSearchChange = (e) => {
        const searchValue = e.target.value;
        console.log("Search input changed to:", searchValue);
        setListingsearch(searchValue);
    };
    

    const filteredGameList = gameList.filter(item =>
        item.name.toLowerCase().includes(listingsearch.toLowerCase())
    );


    const AvialableBalance = async() => {
        try{
            let bal = await GetUserAvialableBalanceHooks({userid : GetUserCode()})
            if(bal?.type == "Success"){
                setSitebalance(bal?.balance);
            }
        }
        catch(e){
            console.log("Error on avialable balance" , e);
        }
    }

    return (
        <>
            {verifymodal && <Verifymodal  email ={userdata?.email ? userdata?.email : ""} expired = {expired} onDismiss={() => setVerifymodal(false)} />}
            {changemodal && <Changemodals email ={userdata?.email ? userdata?.email : ""} onDismiss={() => setChangemodal(false)} />}
            {changenamemodal && <Changenamemodals name = {userdata?.name ? userdata?.name : ""} onDismiss={() => setChangenamemodal(false)} />}


            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='pos1'>
                        {userdata?.coverImage && !file ?
                           <img src={config?.IMAGE_URL + userdata?.coverPath + userdata?.coverImage} className="img-fluid w-100 fileimg" alt="img" />
                           :
                        <img src={file ? file : Images.newbanner} className="img-fluid w-100 fileimg" alt="img" /> 
                         
                           
                        }

                        <input type="file" className='toppos cover' onChange={handleChange} />
                        <button className='home__outlineBtn rounded-5 home__btnOutlineHider poppins toppos cover1'><MdCameraAlt /> Change Cover Photo</button>
                    </div>

                    <div className='container'>
                        <div className='row mup'>
                            <div className='col-md-12 col-lg-10 col-xl-8 d-block d-md-flex edit_sec'>
                                <div className='me-0 me-md-4'>
                                    <div className='pos wd'>
                                        {userdata?.profileImage && !file1 ?
                                        <img src={config?.IMAGE_URL + userdata?.profilePath + userdata?.profileImage} className="img-fluid memberimg" alt="img" /> :
                                            <img src={file1 ? file1 : Images.pimg} className="img-fluid memberimg" alt="img" />
                                        }

                                        <input type="file" className='toppos cover' onChange={handleChange1} />
                                        <button className='home__outlineBtn rounded-5 home__btnOutlineHider poppins px-3 toppos cover1'><MdCameraAlt /></button>
                                    </div>
                                    <h5 className="m-0 whtclr vendin text-center mt-3">Your Code :</h5>
                                    <h5 className='vioclr fw-600 text-center mt-2'>{userdata?.userCode}</h5>
                                </div>

                                <div className='text-center text-md-start bg'>
                                    <h3 className="m-0 whtclr vendin mt-3 mb-2 d-flex align-items-center justify-content-between">
                                        <div>
                                            {userdata?.name ? userdata?.name + " " : "Not-provided "} <FaEdit className='vioclr' style={{ cursor: "pointer" }} onClick={() => { setChangenamemodal(true) }} />
                                        </div>
                                        <div>
                                            <Link to="/notification" className='text-end'>
                                                <IoNotifications className='vioclr' />
                                            </Link>
                                        </div>
                                    </h3>
                                    <p className='whtclr text-uppercase poppins'>Member since {userdata?.createdAt && `${months[new Date(userdata?.createdAt).getMonth()]}  ${new Date(userdata?.createdAt).getFullYear()}`}</p>
                                    <p className='whtclr text-uppercase poppins'>Email: {userdata?.email ? userdata?.email : "Not-Provided"} <span className='dblock'>
                                        {userdata?.emailVerified === "Verified" ? <span></span> : <button className='btn verify ms-2' onClick={async () => {
                                            let payload = {
                                                email: userdata?.email,
                                            }
                                            let register = await RegisterUserEmailHooks(payload);
                                            if (register.type === "Success") {
                                                setExpired(register?.expired);
                                                toast.success(register?.message);
                                                setVerifymodal(true);
                                            } else {
                                                toast.error("Error On Server");
                                            }
                                        }}>Verify</button>}
                                        <button className='btn changebtn ms-2' onClick={() => setChangemodal(true)}>Change</button></span></p>
                                    <p className='whtclr text-uppercase poppins'>Wallet Address : {GetUserAcccount() ? `${GetUserAcccount()?.substring(0, 5)}...${GetUserAcccount()?.substring(38, 42)}` : "Not Connected"}</p>
                                    <p className='whtclr text-uppercase poppins'>
                                        Available Balance: {parseFloat(sitebalance).toFixed(4)}
                                        <span className='text-uppercase ps-2'>{CHAINS[GetChain()]?.nativeCurrency.symbol}</span>
                                    </p>

                                    <p className='whtclr text-uppercase poppins'>
                                        Connected Wallet Balance: {parseFloat(balance).toFixed(4)}
                                        <span className='text-uppercase ps-2'>{CHAINS[GetChain()]?.nativeCurrency.symbol}</span>
                                    </p>
                                    {/* <button type='button' className="header__connectBtn rounded-3 poppins mt-2">Withdraw Button</button> */}

                                </div>
                            </div>
                        </div>

                        <ul className="tablist poppins pt-5 mb-4">
                            <li className={activeTab === 'listing' ? 'active' : 'tabtext'} onClick={() => handleTabClick('listing')}><span>Listings</span></li>
                            <li className={activeTab === 'completeorder' ? 'active' : 'tabtext'} onClick={() => handleTabClick('completeorder')}><span>Complete Orders</span></li>
                            <li className={activeTab === 'yourorder' ? 'active' : 'tabtext'} onClick={() => handleTabClick('yourorder')}><span>Buyer Request</span></li>
                        </ul>

                        <div className='pb-4'>
                            {activeTab === 'listing' &&
                                <>
                                    <div className="input-group mb-3 wfull mt-4">
                                            <>
                                                <input type="search" className="form-control searchinput" placeholder="Search" name='listingsearch' value={listingsearch} onChange={handleSearchChange} aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                                                <div className="input-group-append">
                                                    <button className="btn btn-secondary" type="button"><IoSearchSharp /></button>
                                                </div>
                                            </>
                                    </div>
                                    <div className=''>
                                        <div className={filteredGameList.length>0 ?'filterdatabox mt-5':"d-flex align-items-center justify-content-center pt-5"}>
                                            {filteredGameList.length>0 ?
                                            <>
                                            {filteredGameList.map((e, i) =>
                                                <div key={i}>
                                                    <Link to=""><Cardlist key={e._id} datas={e} onItemDelete={handleItemDelete} content={activeTab} /></Link>
                                                </div>
                                            )}
                                            </> :
                                            <h5 className='whtclr vendin text-center'>No Items Found</h5> }
                                        </div>
                                    </div>
                                    
                                    {count && filteredGameList.length > 0 && count > filteredGameList.length &&
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button type='button' className="header__connectBtn rounded-3 poppins mt-2" onClick={loadmore}>Load More</button>
                                        </div>
                                    }
                                </>
                            }
                            {activeTab === 'completeorder' &&
                                <>
                                    <div className=''><Orders /></div>
                                </>
                            }
                            {activeTab === 'yourorder' &&
                                <>
                                    {/* <div className="input-group mb-3 wfull mt-4">
                                        <input type="search" className="form-control searchinput" placeholder="Search" name='search' aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                        <div className="input-group-append">
                                            <button className="btn btn-secondary" type="button"><IoSearchSharp /></button>
                                        </div>
                                    </div> */}
                                    <div className=''>
                                        
                                        <div className={orderList.length>0 ?'filterdatabox mt-5':"d-flex align-items-center justify-content-center pt-5"}>
                                        {orderList.length>0 ?
                                        <>
                                            {orderList.map((e, i) =>
                                                <div key={i}>
                                                    <Cardlist datas={e} onItemDelete={handleOrderDelete} content={activeTab} />
                                                </div>
                                            )}
                                            </> :  <h5 className='whtclr vendin text-center'>No Items Found</h5> }
                                        </div>
                                    </div>
                                    {count1 && count1 > add1 &&
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <button type='button' className="header__connectBtn rounded-3 poppins mt-2" onClick={loadmore1}>Load More</button>
                                        </div>
                                    }
                                </>
                            }
                        </div>


                    </div>
                </div>

                <Footer />
            </Container>
        </>
    )
}

export default Profile;
