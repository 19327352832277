import React, { useState, useEffect } from 'react';
import { Container, Dropdown, Table, Offcanvas } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Cardlist from '../Components/cardlist';
import Tablelist from '../Components/tablelist';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import Filterpage from '../Components/filterpage';
import Switch from "react-switch";
import { AiOutlineTable } from "react-icons/ai";
import { IoIosList } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import { FilterGameDetailListHooks, GetGameDetailListHooks } from '../Hooks/GameHooks';

function Explore() {

    const [cardlisted, setCardlisted] = useState([
        { id: "1", img: Images.cardimg, approve: "approved", ratings: 3.5 }, { id: "2", img: Images.cardimg1, approve: "rejected", ratings: 5 }, { id: "3", img: Images.cardimg2, approve: "approved", ratings: 4.5 }, { id: "4", img: Images.cardimg3, approve: "approved", ratings: 3 }, { id: "5", img: Images.cardimg1, approve: "rejected", ratings: 3 },
        { id: "6", img: Images.cardimg2, approve: "rejected", ratings: 2 }, { id: "7", img: Images.cardimg, approve: "approved", ratings: 3.7 }, { id: "8", img: Images.cardimg3, approve: "approved", ratings: 2.8 }, { id: "9", img: Images.cardimg1, approve: "approved", ratings: 1 }, { id: "10", img: Images.cardimg2, approve: "rejected", ratings: 5 },
    ]);



    const [selfilter, setSelfilter] = useState([]);
    const [accordionOpenss, setAccordionOpenss] = useState();
    const toggleAccordion = (i) => {
        setAccordionOpenss(i === accordionOpenss ? null : i);
    };
    const [accordiontabless, setAccordiontabless] = useState([
        { cat: "Class", filters: [{ name: "Miner", checked: false, num: "3404" }, { name: "Engineer", checked: false, num: "648" }], no: "5" },
        { cat: "Clothes", filters: [{ name: "Jacks", checked: false, num: "48" }, { name: "Polo", checked: false, num: "478" }], no: "31" },
        { cat: "Collection", filters: [{ name: "Printed", checked: false, num: "48" }, { name: "Checked", checked: false, num: "478" }], no: "3" },
        { cat: "Facial Feature", filters: [{ name: "Garnier", checked: false, num: "48" }, { name: "Axen", checked: false, num: "478" }], no: "7" },
        { cat: "Gender", filters: [{ name: "Male", checked: false, num: "48" }, { name: "Female", checked: false, num: "478" }], no: "22" },
        { cat: "Hair Color", filters: [{ name: "Brown", checked: false, num: "48" }, { name: "Black", checked: false, num: "478" }], no: "14" },
        { cat: "Hair Style", filters: [{ name: "Curly", checked: false, num: "48" }, { name: "straightening", checked: false, num: "478" }], no: "9" },
        { cat: "Headwear", filters: [{ name: "Top", checked: false, num: "48" }, { name: "Bottom", checked: false, num: "478" }], no: "18" },
        { cat: "Special Item", filters: [{ name: "Costly", checked: false, num: "48" }, { name: "Low", checked: false, num: "478" }], no: "10" },
    ]);
    { console.log("kkk", accordiontabless); }
    const handlechange = (name, cat, e) => {
        { console.log("ggg", e.target.checked, name, cat); }
        let array = accordiontabless
        let hari = array.map((list) => {
            if (list.cat == cat) {
                list.filters.map((names, i) => {
                    if (names.name == name) {
                        list.filters[i].checked = e.target.checked
                        if (e.target.checked == true) {
                            setSelfilter([...selfilter, { ...list.filters[i], cat: list.cat }])
                        }
                    }
                })
                return list
            }
            else {
                return list
            }
        }

        )
        setAccordiontabless(hari)
    }
    { console.log(selfilter, 'lll'); }
    const close = (name, cat) => {
        let array = accordiontabless
        let hari = array.map((list) => {
            if (list.cat == cat) {
                list.filters.map((names, i) => {
                    if (names.name == name) {
                        list.filters[i].checked = false
                        setSelfilter([...selfilter.filter((s) => s.name != name)])
                        console.log("ooo", [...selfilter.filter((s) => s.name != name)]);
                    }
                })
                return list
            }
            else {
                return list
            }
        }

        )
        setAccordiontabless(hari)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const [checked, setChecked] = useState(false);
    
    const handleChange = nextChecked => {
        setChecked(nextChecked);
    };

    const [showdatas, setShowdatas] = useState(true);

    // const filtertext = ["Category", "Game", "Delivery method", "price", "payment","availability", "additional filter"];
    const [filtertext, setFiltertext] = useState([
        {
            filhead: "Asset Type",
            filters: [
                 "Game items",
                 "Gift cards",
                 "Player",
            ]
        },
        {
            filhead: "Category",
            filters: [
                 "Roblex",
                 "Fallout",
                 "pubg",
                 "war frame",
                 "Gta",
            ]
        },
        {
            filhead: "price",
            filters: [
                "$10 & under",
                "$11 - $20",
                "$21 - $40",
                "$41 - $60"
           ]
        },
        {
            filhead: "availability",
            filters: [
                "Any",
                "On sale",
                "Sold"
           ]
        }
    ]);

    const navigate = useNavigate();

    const nextpagefunc = () => {
        navigate("/carddetail");
    }

    const [explore, setExplore] = useState("explore");

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleresize = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleresize);
        return () => {
            window.removeEventListener("resize", handleresize);
        }
    }, []);


//dev-murugavel
const [gamedetaillist , setgamedetaillist] = useState([]);
const [totalgamedetail , setTotalgamedetail] = useState(0);
const [loading , setLoading] = useState(false);
const [skip , setSkip] = useState(0);
const [limit , setLimit] = useState(10);
const [filter , setFilter] = useState(false);
const [filtergamedetaillist , setFiltergamedetaillist] = useState([]);
const [filterskip , setFilterskip] = useState(0);
const [filterlimit , setFilterlimit] = useState(10);
const [filpayload , setFilayload] = useState({}); 


const fetchgamedetaillist = async() => {
    let payload = {
        skip : 0,
        limit : 10
    }
    let result = await GetGameDetailListHooks(payload);
    if(result?.type == "Success"){
        setgamedetaillist(result?.record);
        setSkip(skip + limit);
        setTotalgamedetail(result?.count)
    }
}

const Loadmore  = async() =>{
    try{
        setLoading(true);
        let payload = {
            skip : skip,
            limit : limit
        }
        let result = await GetGameDetailListHooks(payload);
        if(result?.type == "Success"){
            setgamedetaillist(gamedetaillist?.concat(result?.record));
            setLoading(false);
            setSkip(parseInt(skip) + parseInt(limit));
        }
    }
    catch(e){
        console.log("Error on loadmore " , e);
    }
}

const FiltergamedetailList = async(data) => {
    try{
        let payload = {
            skip : 0,
            limit : limit,
            category : data?.selectedcategory,
            minprice : data?.selectedprice?.val[0], 
            maxprice : data?.selectedprice?.val[1], 
            available : data?.availability?.value,
        }
        setFilayload(payload);
        let result = await FilterGameDetailListHooks(payload);
        if(result?.type == "Success"){
            // setFiltergamedetaillist(filtergamedetaillist?.concat(result?.record));
            // setFilterskip(filterskip+filterlimit);
            setgamedetaillist(result?.record);
            setSkip(0 + limit);
            setTotalgamedetail(result?.count)
        }
    }
    catch(e){
        console.log("Error on gilete game detail list" , e);
    }
}

const Loadmorefilter = async() => {
    try{
        let payload = filpayload;
        payload.skip = skip;
        payload.limit = limit;
        let result = await FilterGameDetailListHooks(payload);
        if(result?.type == "Success"){
            // setFiltergamedetaillist(filtergamedetaillist?.concat(result?.record));
            // setFilterskip(filterskip+filterlimit);
            setgamedetaillist(gamedetaillist?.concat(result?.record));
            setSkip(skip + limit);
        }
    }
    catch(e){
        console.log("Error on gilete game detail list" , e);
    }
}

const SortgamedetailList = async(data) => {
    try{
        let payload = filpayload;
        payload.sorttype = data;
        setFilayload(payload);
        let result = await FilterGameDetailListHooks(payload);
        if(result?.type == "Success"){
            // setFiltergamedetaillist(filtergamedetaillist?.concat(result?.record));
            // setFilterskip(filterskip+filterlimit);
            setgamedetaillist(result?.record);
            setSkip(0 + limit);
            setTotalgamedetail(result?.count)
        }
    }
    catch(e){
        console.log("Error on gilete game detail list" , e);
    }
}


useEffect(() => {
    const query = new URLSearchParams(window.location?.search);
    console.log("query" , query);
    // fetchgamedetaillist()
},[])






    return (

        <>

            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages pb-4'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center pt-5 pt-sm-0 mb-5'>Explore</h2>

                        <div className='row align-items-center mb-4'>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4 mb-sm-0'>
                                <h4 className='whtclr vendin mb-0'>Game Items <span className='vendin all'></span></h4>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 col-lg-8 col-xl-9'>
                                <div className='flexbox topopt'>
                                    <div>
                                        <div className='d-flex justify-content-sm-end flex-wrap gap-3'>
                                            <div className='bglightbox'>
                                                <div className='d-flex align-items-center justify-content-between gap-4'>
                                                    <div>
                                                        <Dropdown>
                                                            <Dropdown.Toggle className='rtext text-white'>Sort</Dropdown.Toggle>
                                                            <Dropdown.Menu className='mt-2'>
                                                                <Dropdown.Item className='vendin' onClick={() => SortgamedetailList(1)}>Low to High</Dropdown.Item>
                                                                <Dropdown.Item className='vendin' onClick={() => SortgamedetailList(-1)}>Hign to Low</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div>
                                                        <div className='sicons'>
                                                            <span onClick={() => setShowdatas(true)}>
                                                                <AiOutlineTable />
                                                            </span>
                                                            <span className='ps-2' onClick={() => setShowdatas(false)}>
                                                                <IoIosList />
                                                            </span>
                                                        </div>                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            { width < 767 && 
                                                <div className='bglightbox d-flex align-items-center'>
                                                    <span className='text-white' onClick={() => setShow(true)}>
                                                        <FaFilter />
                                                    </span>
                                                </div>
                                            }                                            
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            { width > 767 &&
                                <div className='col-12 col-sm-12 col-lg-4 col-xl-3 mb-4 mb-lg-0'>     
                                    <div className='filterback px-3 py-3'>
                                        <Filterpage 
                                        filter = {(data) => FiltergamedetailList(data)}
                                        list={accordiontabless} toggleAccordion={toggleAccordion} handlechange={handlechange} datas={filtertext} />
                                    </div>       
                                </div>
                             } 
                            <div className='col-12 col-sm-12 col-lg-8 col-xl-9'>

                                { showdatas == true && 
                                    <div className={gamedetaillist?.length > 0 ?  "filterdatabox" : "d-flex align-items-center justify-content-center pt-5"}>                                
                                        {gamedetaillist?.length > 0 ? gamedetaillist?.map((e, i) =>
                                            <div>
                                                {console.log("data in regawgdgfasdfgsadgsagas" , e)}
                                               <Link to={`/carddetail/${e?._id}`}><Cardlist datas={e} explore={explore}  /></Link>
                                            </div>
                                        ) : <h5 className='whtclr vendin text-center '>No Items Found</h5>}
                                    </div>
                                }
                                {showdatas == true && totalgamedetail > gamedetaillist?.length && <div className='text-center'><button className='header__connectBtn rounded-3 px-4 vendin mt-4 ms-2' onClick={Loadmorefilter}>Load More</button></div>}
                               
                                { showdatas == false && 
                                    <div className='showtable'>
                                        <Tablelist cardlist={gamedetaillist} explore={explore} nextpage={nextpagefunc} />
                                        {gamedetaillist?.length <= 0 && <h5 className='whtclr vendin text-center pt-5'>No Items Found</h5>}
                                    </div>   
                                                                     
                                }
                                {showdatas == false && totalgamedetail > gamedetaillist?.length && <div className='text-center'><button className='header__connectBtn rounded-3 px-4 vendin mt-4 ms-2' onClick={Loadmorefilter}>Load More</button></div>}
                            </div>
                        </div>


                      

                    </div>

                </div>

                <Footer />
            </Container>

            {/* filter canvas */}
            <Offcanvas show={show} onHide={handleClose} className='header__canvas' placement='end' backdrop="static">
                <Offcanvas.Body>
                    <div className='header__canvasHead d-flex justify-content-between align-items-center'>
                        <img src={Images.logo} className='img-fluid footer__logo' alt='' />
                        <button className='header__transBtn' onClick={handleClose}><IoClose /></button>
                    </div>
                    <div className='header__canvaBody mt-5'>
                        <div className='filterback px-3 py-3'>
                            <Filterpage list={accordiontabless} toggleAccordion={toggleAccordion} handlechange={handlechange} datas={filtertext} />
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {/* end of filter canvas */}
        </>
    )
}

export default Explore;