import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Dropdown, DropdownMenu, DropdownToggle, DropdownItem } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Footer from '../Components/Footer';

function Messagepage() {

    const[msglist, setMsglist] = useState([
        {id:1},{id:2},{id:3}
    ])

    return (

        <>
            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='container'>
                    <h2 className='whtclr vendin text-center mb-3'>MESSAGING</h2>
                    <p className='whtclr poppins text-center mb-5'>Messaging allows you to directly contact other G2G users. You currently have messaging disabled.</p>
                  
                    {msglist.map((e,i)=>
                     <div className='messageborder d-flex align-items-center justify-content-between py-3 px-2 px-sm-3 mt-4'>
                        <div className='d-flex'>
                         <img src={Images.imgicon} className='micon me-1 me-sm-2' alt='image'/> 
                         <div>
                            <h5 className='whtclr poppins mb-1'>John</h5>
                            <p className='whtclr poppins mb-0'>Online</p>
                         </div>
                        </div>
                        <p className='whtclr poppins mb-0'>Chat Ended</p>
                        <Dropdown>
                            <DropdownToggle className='px-0'><HiOutlineDotsVertical/></DropdownToggle>
                            {/* <DropdownMenu>
                                <DropdownItem></DropdownItem>
                            </DropdownMenu> */}
                        </Dropdown>
                     </div>)}
                                   
                    </div>

                </div>

                <Footer/>

            </Container>

        </>

    )
}

export default Messagepage