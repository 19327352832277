import React from 'react';
import { Toaster } from 'react-hot-toast'
import { Provider, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import { PrivyProvider } from '@privy-io/react-auth';
import {base, baseGoerli, mainnet, sepolia, polygon, polygonMumbai} from 'viem/chains';
import { CHAINS } from './Config/Network.';
import { RegisterNewUserHooks } from './Hooks/UserHooks';
import store from "./Redux/Store/Store";
import { GetUserCode } from './Hooks/UseWallet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <PrivyProvider
              appId="clxoj6uho08k7fnll28dbl348"
              config={{
                loginMethods: ['email', 'wallet' , 'google', 'apple', 'farcaster' , 'sms' , 'twitter', 'discord', 'github', 'linkedin', 'spotify', 'instagram', 'tiktok'],
                // Customize Privy's appearance in your app
                appearance: {
                  theme: 'light',
                  accentColor: '#676FFF',
                },
                // Create embedded wallets for users who don't have a wallet
                embeddedWallets: {
                  createOnLogin: 'users-without-wallets',
                },
                defaultChain : CHAINS[0], // polygon,
                supportedChains:  CHAINS  //[polygon, polygonMumbai , base, baseGoerli, mainnet, sepolia] 
              }}

              onSuccess={async(e) => {
                console.log("privy success" , e);
                localStorage.setItem("WalletKey" , e?.wallet?.address);
                localStorage.setItem("WalletType" , e?.wallet?.walletClientType);
                let payload = {
                  walletaddress : e?.wallet?.address,
                  userid : GetUserCode()
                }
                let register = await RegisterNewUserHooks(payload);
                window.location.reload();
                console.log("Register " , register);
              }}
            >
    <BrowserRouter>
    <Provider store={store}>
    <App />
    <div><Toaster/></div>
  </Provider>
    
    </BrowserRouter>
      </PrivyProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
