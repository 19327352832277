import { React, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Footer from "../Components/Footer";

import { getCMScontentList } from "../Hooks/Home";

const Partnership = () => {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        getCMScontent();

    }, []);

    const [homeContent, setHomeContent] = useState([]);


    const getCMScontent = async () => {
        try {
            const payload = {
                page: "partnership"
            }
            const resp = await getCMScontentList(payload);

            console.log("RESP DATA_", resp);

            if (resp?.status) {
                setHomeContent(resp.data);
            } else {
                console.error('Failed to fetch CMS content.');
            }
        } catch (error) {
            console.error('An error occurred while fetching CMS content:', error); // Also stop loading if there's an error
        }
    };


    const removeAllTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };

    return (
        <>
            <Container fluid className="p-0 common_bg">
                <Header />
                <div className="innerpages pb-5">
                    <div className="container">
                        <h2 className="whtclr vendin text-center pt-5 pt-sm-0 mb-4 mb-sm-5">Partnership</h2>
                        <div className="mt-4">

                            {homeContent.map((data, i) => (

                                <>
                                    <div key={i}>
                                        {data.question == 'partnership_top' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }
                                        {data.question == 'partnership_1' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }
                                        {data.question == 'partnership_2' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }
                                        {data.question == 'partnership_3' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }
                                        {data.question == 'partnership_4' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }
                                        {data.question == 'partnership_5' &&
                                            <p className='poppins whtclr'>
                                                {removeAllTags(data.description)}
                                            </p>
                                        }

                                    </div>
                                    <br /><br />
                                </>


                            ))}


                        </div>
                    </div>
                </div>
                <Footer />
            </Container>
        </>
    );
};

export default Partnership;
