import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Marquee from "react-fast-marquee";
import { allData } from "../Components/Data";
import CreatorCard from "../Components/CreatorCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Walletmodal from "../Components/walletmodal";
import AOS from "aos";
import "aos/dist/aos.css";
import { IoIosArrowForward, IoIosArrowUp } from "react-icons/io";
import { getFaqListItems } from "../Hooks/FAQ";
import { getCMScontentList } from "../Hooks/Home";
import { GetLandingdataHooks } from "../Hooks/UserHooks";
import config from "../Config/env";

const Home = () => {
  const [accordionOpen, setAccordionOpen] = useState(null);
  const [homeContent, setHomeContent] = useState([]);
  const [accordionall, setAccordionall] = useState([]);
  const [walletmodal, setWalletmodal] = useState(false);
  const [landingdata , setLandingdata] = useState();

  const toggleAccordion = (i) => {
    setAccordionOpen(i === accordionOpen ? null : i);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    AOS.init({ once: true, disable: "phone" });
    getFaqList();
    getCMScontent();
    getlandingdata();
  }, []);

  const getFaqList = async () => {
    try {
      const resp = await getFaqListItems();
      if (resp?.status) {
        setAccordionall(resp.data);
      } else {
        console.error('Failed to fetch FAQ list.');
      }
    } catch (error) {
      console.error('An error occurred while fetching FAQ list:', error);
    }
  };

  // const removePTags = (html) => {
  //   const tempDiv = document.createElement('div');
  //   tempDiv.innerHTML = html;
  //   const paragraphs = tempDiv.querySelectorAll('p');
  //   paragraphs.forEach(p => {
  //     const text = p.textContent || p.innerText;
  //     p.replaceWith(text);
  //   });
  //   return tempDiv.innerHTML;
  // };

  const removeAllTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };
  

  const getCMScontent = async () => {
    try {
      const payload ={
        page: "home"
    } 
    const resp = await getCMScontentList(payload);

      console.log("RESP DATA ", resp.data);

      if (resp?.status) {
        setHomeContent(resp.data);
      } else {
        console.error('Failed to fetch CMS content.');
      }
    } catch (error) {
      console.error('An error occurred while fetching CMS content:', error); // Also stop loading if there's an error
    }
  };

  const getlandingdata = async() => {
    try{
      let data = await GetLandingdataHooks();
      setLandingdata(data);
    }
    catch(e){
      console.log("Error on get landing data" , e);
    }
  }

  const findContent = (key) => {
    const data = homeContent.find(data => data.question === key)?.description || "";

    return removeAllTags(data);
  };

  return (
    <>
      {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
      <Container fluid className="p-0 common_bg homeheader">
        <Header />
        <div className="home_banner">
          <Container className="home__bannerSec">
            <Row>
              <Col md={7} lg={6} xxl={6}>
                <h1 className="m-0 home__bannerTitle stxt vendin text-left mt-4">
                  {findContent('homepage_heading', 'Everything Gaming Buy And Sell Safely')}
                </h1>
                <p className="m-0 poppins home__bannerHint mt-3">
                  {findContent('homepage_description')}
                </p>
                <Link to="/explore"><button className="home__outlineBtn rounded-5 poppins mt-4">Let's Explore</button></Link>
              </Col>
            </Row>
          </Container>
          <img src={Images.spaceman} className="spaceman" />
          <img src={Images.underworld} className="underworld" />
          <img src={Images.bliss} className="bliss1" />
          <img src={Images.bliss} className="bliss" />
          <img src={Images.shake} className="shake" />
          <img src={Images.glitch} className="glitch" />
          <img src={Images.glitch} className="glitchup" />
        </div>
               <Container className="home__bannerSec">
          <div className="mt-4">
           <Marquee direction="right">
              {allData.homeMarquee.map((item) =>
                <img src={item.img} className="img-fluid home__marqueeImg mx-4" alt="" />
              )}
            </Marquee>

            <Marquee direction="left">
              {allData.homeMarquee.map((item) =>
                <img src={item.img} className="img-fluid home__marqueeImg mx-4" alt="" />
              )}
            </Marquee>
          </div>
        </Container>

        <div className="pos blur pt-4 pb-3">
          <img src={Images.glitch} className="sec_glitch" />
          <img src={Images.glitch} className="sec_glitch1" />
          <img src={Images.widestar} className="widestar" />
          <Container className="home__bannerSec">
            <Row className="flex-column justify-content-center align-items-center mt-5 mb-5 pt-sm-5">
              <Col md={8} sm={10}>
                <h4 className="m-0 home__bannerSubTitle vendin text-center">
                {findContent('section1_heading')}
                </h4>
              </Col>
              <Col md={8} sm={8} xs={10}>

                <p className="m-0 poppins home__bannerHint text-center mt-2">
                  {findContent('section1_desc')}
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} sm={12} lg={10} xl={10} xxl={9}>
                <Row className="align-items-center pb-5 centerboxrow">
                  <Col lg={6} className="order-1 order-lg-0">
                    {/* <img src={Images.walletimg} className="img-fluid" /> */}
                    <div className="centerbox">
                      <img src={Images.walletimgicon} className="img-fluid centerimg" />
                    </div>
                  </Col>
                  <Col lg={6} className="order-2 order-lg-0">
                    <h4 className="m-0 home__bannerSubTitle vendin mb-4">
                {findContent('section1_subheading1')}

                      {/* Setup and Connect your Wallet. */}
                    </h4>
                    <p className="m-0 poppins home__bannerHint mt-2">
                    {findContent('section1_subdesc1')}
                      {/* We are a community based collection project featuring
                      3D artwork. 3D sneaker Heads comes in all kinds of evil
                      shapes, traits and sizes with a few surprise along
                      the way */}
                    </p>
                  </Col>
                  <Col lg={6} className="order-4 order-lg-0">
                    <h4 className="m-0 home__bannerSubTitle vendin mb-4">
                    {findContent('section1_subheading2')}
                      {/* Select your sell Game. */}
                    </h4>
                    <p className="m-0 poppins home__bannerHint mt-2">
                    {findContent('section1_subdesc2')}
                      {/* We are a community based collection project featuring
                      3D artwork. 3D sneaker Heads comes in all kinds of evil
                      shapes, traits and sizes with a few surprise along
                      the way */}
                    </p>
                    <Link to="/sellingtab"><button className="home__outlineBtn rounded-5 poppins mt-4">Sell Game item</button></Link>
                  </Col>
                  <Col lg={6} className="order-3 order-lg-0">
                    {/* <img src={Images.sellimg} className="img-fluid" /> */}
                    <div className="centerbox">
                      <img src={Images.sellimgicon} className="img-fluid centerimg" />
                    </div>
                  </Col>
                  <Col lg={6} className="order-5 order-lg-0">
                    {/* <img src={Images.profit} className="img-fluid" /> */}
                    <div className="centerbox">
                      <img src={Images.profiticon} className="img-fluid centerimg" />
                    </div>
                  </Col>
                  <Col lg={6} className="order-6 order-lg-0">
                    <h4 className="m-0 home__bannerSubTitle vendin mb-4">
                    {findContent('section1_subheading3')}
                      {/* Get your profit */}
                    </h4>
                    <p className="m-0 poppins home__bannerHint mt-2">
                    {findContent('section1_subdesc3')}
                     
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="img_sec pos pb-4">
          <img src={Images.glitch} className="sec_glitch" />
          <img src={Images.glitch} className="sec_glitch_l" />
          <Container className="home__bannerSec">
            <Row className="flex-column justify-content-center align-items-center mt-5">
              <Col md={8} sm={10}>
                <h4 className="m-0 home__bannerSubTitle vendin text-center">
                {findContent('section2_heading')}
                  {/* ROBLOX: PETS, ROBUX AND MORE... */}
                </h4>

              </Col>
              <Col md={6} sm={8} xs={10} className="d-flex flex-column align-items-center">

                <p className="m-0 poppins home__bannerHint text-center mt-2">
                {findContent('section2_desc')}
                </p>
                <Link to="/explore"><button className="home__outlineBtn rounded-5 home__btnOutlineHider poppins mt-4">Let's Explore</button></Link>
              </Col>
            </Row>
            {/* <Row className="mt-5">
              <Col md={12} lg={6} className="mb-4 mb-md-0 mt-3">
                <div className="borders"><img src={Images.dino} className="img-fluid dinoimg" alt="" /></div>             
              </Col>
              <Col md={12} lg={6} className="mt-3">
                <Row>
                  <Col lg={6} xs={12} className="mb-4">
                  <div className="borders">
                    <img src={Images.messi} className="img-fluid smallimg" alt="" /></div>
                  </Col>
                  <Col lg={6} xs={12} className="mb-4">
                  <div className="borders">
                    <img src={Images.himan} className="img-fluid smallimg" alt="" /></div>
                  </Col>
                  <Col lg={12} xs={12}>
                  <div className="borders">
                    <img src={Images.barbie} className="img-fluid barbieimg" alt="" /></div>
                  </Col>
                </Row>

              </Col>
            </Row> */}
            <div className="mt-5">
              <Marquee direction="right" className="mb-4">
                {allData.categoryimgs.map((item) =>
                  <div className="borders mx-2">
                    <img src={item.cimg} className="img-fluid home__bannerSec_img" alt="" />
                  </div>
                )}
              </Marquee>

              <Marquee direction="left">
                {allData.categoryimgs.map((item) =>
                  <div className="borders mx-2">
                    <img src={item.cimg} className="img-fluid home__bannerSec_img" alt="" />
                  </div>
                )}
              </Marquee>
            </div>
          </Container>
        </div>

        <div className="item_gold pos pt-5 pb-5">
          <img src={Images.glitch} className="sec_glitch1" />
          <Container className="home__bannerSec">
            <Row className="flex-column justify-content-center align-items-center mt-5">
              <Col md={8} sm={10}>
                <h4 className="m-0 home__bannerSubTitle vendin text-center">
                {findContent('section3_heading')}
                </h4>

              </Col>
              <Col md={6} sm={8} xs={10}>

                <p className="m-0 poppins home__bannerHint text-center mt-2 mb-2">
                {findContent('section3_desc')}
                </p>
              </Col>
            </Row>
            <div className="item_back rounded-2 py-5 mt-4">
              <Row className="mt-0 align-items-center">
                <Col sm={4} className="mb-5 mb-sm-0">
                  <h3 className="m-0 orbitron home__counterTitle text-center">4.5/5</h3>
                  <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">APP STORE RATING</h5>
                  <p className="m-0 orbitron home__counterHint text-center mt-2">22K+ REVIEWS</p>
                </Col>
                <Col sm={4} className="mb-5 mb-sm-0">
                  <h3 className="m-0 orbitron home__counterTitle text-center">{landingdata?.ordercount}</h3>
                  <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">SOLD</h5>
                </Col>
                <Col sm={4} className="mb-5 mb-sm-0">
                  <h3 className="m-0 orbitron home__counterTitle text-center">{landingdata?.user}</h3>
                  <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">REGISTERED USERS</h5>
                </Col>
              </Row>
            </div>

          </Container></div>

        <div className="swipe_sec pos pt-5 pb-5">
          <img src={Images.glitch} className="sec_glitch" />
          <Container className="home__bannerSec">
            <Row className="flex-column justify-content-center align-items-center mt-0">
              <Col md={8} sm={10}>
                <h4 className="m-0 home__bannerSubTitle vendin text-center">
                {findContent('section4_heading')}
                </h4>

              </Col>
              <Col md={6} sm={8} xs={10}>

                <p className="m-0 poppins home__bannerHint text-center mt-2">
                {findContent('section4_desc')}
                </p>
              </Col>
            </Row>
            <div className="mt-5">
              <Swiper
                loop={true}
                pagination={{
                  dynamicBullets: true,
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  450: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper home__gameSwiper"
              >
                {landingdata?.item?.map((item) =>
                  <SwiperSlide><img src={config.API_URL + item?.imagePath + item?.image} className="img-fluid" alt="" /></SwiperSlide>
                )}

              </Swiper>
            </div>
          </Container></div>

        <div className="faq_sec pos pt-5 pb-5">
          <img src={Images.glitch} className="sec_glitch_l" />
          <Container className="home__bannerSec">
            <Row className="flex-column justify-content-center align-items-center mt-0">
              <Col md={8} sm={10}>
                <h4 className="m-0 home__bannerSubTitle vendin text-center">
                {findContent('homepage_faq_heading')}
                </h4>

              </Col>
              <Col md={6} sm={8} xs={10}>

                <p className="m-0 poppins home__bannerHint text-center mt-2">
                {findContent('homepage_faq_text')}
                </p>
              </Col>
            </Row>
            <Row className="mt-5 justify-content-between pb-4">
              <Col md={4} className="mb-5 mb-md-0">
                <img src={Images.mask} className="img-fluid" />
              </Col>
              <Col md={8}>
                <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                  <Accordion defaultActiveKey={1} className="home__faqAccord" flush>
                    {accordionall.map((e, i) =>
                      <Accordion.Item className="mb-3" eventKey={i + 1}>
                        <Accordion.Header className="poppins pos" onClick={() => toggleAccordion(i + 1)}>{e.question} {accordionOpen === i + 1 ? <div className="bclr"><IoIosArrowUp /></div> : <div className="bclr"><IoIosArrowForward /></div>} </Accordion.Header>
                        <Accordion.Body>
                          <span className="m-0 poppins home__faqHint">
                            <div dangerouslySetInnerHTML={{ __html: removeAllTags(e.answer) }} />
                          </span>
                        </Accordion.Body>
                      </Accordion.Item>
                    )}

                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container></div>
        <Footer />
      </Container>
    </>
  );
};

export default Home;











// import React, { useEffect, useState } from "react";
// import { Accordion, Col, Container, Row } from "react-bootstrap";
// import Header from "../Components/Header";
// import { Images } from "../Components/Images";
// import Marquee from "react-fast-marquee";
// import { allData } from "../Components/Data";
// import CreatorCard from "../Components/CreatorCard";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Autoplay } from 'swiper/modules';
// import Footer from "../Components/Footer";
// import { Link } from "react-router-dom";
// import Walletmodal from "../Components/walletmodal";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { IoIosArrowForward } from "react-icons/io";
// import { IoIosArrowUp } from "react-icons/io";

// import { getFaqListItems } from "../Hooks/FAQ";
// import { getCMScontentList } from "../Hooks/Home";

// const Home = () => {


//   const [accordionOpen, setAccordionOpen] = useState(1);

//   const [homeContent, setHomeContent] = useState();

//   const toggleAccordion = (i) => {
//     setAccordionOpen(i === accordionOpen ? null : i);
//   };

//   const [accordionall, setAccordionall] = useState([
//     { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }
//   ])
//   const [walletmodal, setWalletmodal] = useState(false)
//   useEffect(() => {
//     window.scrollTo({
//       top: 0,
//       left: 0,
//       behavior: "smooth",
//     });
//     AOS.init({
//       once: true,
//       disable: "phone",
//     });
//   }, []);

//   useEffect(() => {

//     getFaqList();

//     getCMScontent();

//   }, [])

//   const getFaqList = async () => {

//     try {
//       const resp = await getFaqListItems();

//       console.log("FAQ List", resp);

//       if (resp?.status) {
//         setAccordionall(resp.data);
//       } else {
//         console.error('Failed to fetch social media data.');
//       }
//     } catch (error) {
//       console.error('An error occurred while fetching social media data:', error);
//     }
//   }

//   const removePTags = (html) => {
//     // Create a temporary DOM element to use browser's parsing capabilities
//     const tempDiv = document.createElement('div');
//     tempDiv.innerHTML = html;

//     // Check if it contains <p> tags
//     const paragraphs = tempDiv.querySelectorAll('p');

//     // Remove <p> tags but keep the inner text
//     paragraphs.forEach(p => {
//       const text = p.textContent || p.innerText;
//       p.replaceWith(text);
//     });

//     return tempDiv.innerHTML;
//   };

//   const getCMScontent = async () => {
//     try {
//       const resp = await getCMScontentList();

//       console.log("CMS List", resp);

//       if (resp?.status) {
//         setHomeContent(resp.data);
//       } else {
//         console.error('Failed to fetch social media data.');
//       }
//     } catch (error) {
//       console.error('An error occurred while fetching social media data:', error);
//     }
//   }


  


//   return (
//     <>
//       {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
//       <Container fluid className="p-0 common_bg homeheader">


//         <Header />



//         <div className="home_banner">
//           <Container className="home__bannerSec">
//             <Row>
//               <Col md={7} lg={6} xxl={6}>

//                 <h1 className="m-0 home__bannerTitle stxt vendin text-left mt-4">
//                   Everything Gaming
//                 </h1>
//                 <h1 className="m-0 home__bannerTitle stxt vendin text-left mt-2">
//                   Buy and Sell Safely
//                 </h1>
//                 <p className="m-0 poppins home__bannerHint mt-3">
//                   Discover Game by category, track the latest drops and follow the
//                   collections you love. Enjoy it
//                 </p>
//                 <Link to="/explore"><button className="home__outlineBtn rounded-5 poppins mt-4">Let's Explore</button></Link>
//               </Col>
//             </Row>
//           </Container>
//           <img src={Images.spaceman} className="spaceman" />

//           <img src={Images.underworld} className="underworld" />
//           <img src={Images.bliss} className="bliss1" />
//           <img src={Images.bliss} className="bliss" />
//           <img src={Images.shake} className="shake" />
//           <img src={Images.glitch} className="glitch" />
//           <img src={Images.glitch} className="glitchup" />
//         </div>

//         <Container className="home__bannerSec">
//           <div className="mt-4">
//             <Marquee direction="right">
//               {allData.homeMarquee.map((item) =>
//                 <img src={item.img} className="img-fluid home__marqueeImg mx-4" alt="" />
//               )}
//             </Marquee>

//             <Marquee direction="left">
//               {allData.homeMarquee.map((item) =>
//                 <img src={item.img} className="img-fluid home__marqueeImg mx-4" alt="" />
//               )}
//             </Marquee>
//           </div>
//         </Container>

//         <div className="pos blur pt-4 pb-3">
//           <img src={Images.glitch} className="sec_glitch" />
//           <img src={Images.glitch} className="sec_glitch1" />
//           <img src={Images.widestar} className="widestar" />
//           <Container className="home__bannerSec">
//             <Row className="flex-column justify-content-center align-items-center mt-5 mb-5 pt-sm-5">
//               <Col md={8} sm={10}>
//                 <h4 className="m-0 home__bannerSubTitle vendin text-center">
//                   How to Become G2G Creator
//                 </h4>

//               </Col>
//               <Col md={8} sm={8} xs={10}>

//                 <p className="m-0 poppins home__bannerHint text-center mt-2">
//                   Discover Game by category, track the latest drops and follow the
//                   collections you love. Enjoy it
//                 </p>
//               </Col>
//             </Row>
//             <Row className="justify-content-center">
//               <Col xs={12} sm={12} lg={10} xl={10} xxl={9}>
//                 <Row className="align-items-center pb-5 centerboxrow">
//                   <Col lg={6} className="order-1 order-lg-0">
//                     {/* <img src={Images.walletimg} className="img-fluid" /> */}
//                     <div className="centerbox">
//                       <img src={Images.walletimgicon} className="img-fluid centerimg" />
//                     </div>
//                   </Col>
//                   <Col lg={6} className="order-2 order-lg-0">
//                     <h4 className="m-0 home__bannerSubTitle vendin mb-4">
//                       Setup and Connect your Wallet.
//                     </h4>
//                     <p className="m-0 poppins home__bannerHint mt-2">
//                       We are a community based collection project featuring
//                       3D artwork. 3D sneaker Heads comes in all kinds of evil
//                       shapes, traits and sizes with a few surprise along
//                       the way
//                     </p>
//                   </Col>
//                   <Col lg={6} className="order-4 order-lg-0">
//                     <h4 className="m-0 home__bannerSubTitle vendin mb-4">
//                       Select your sell Game.
//                     </h4>
//                     <p className="m-0 poppins home__bannerHint mt-2">
//                       We are a community based collection project featuring
//                       3D artwork. 3D sneaker Heads comes in all kinds of evil
//                       shapes, traits and sizes with a few surprise along
//                       the way
//                     </p>
//                     <Link to="/sellingtab"><button className="home__outlineBtn rounded-5 poppins mt-4">Sell Game item</button></Link>
//                   </Col>
//                   <Col lg={6} className="order-3 order-lg-0">
//                     {/* <img src={Images.sellimg} className="img-fluid" /> */}
//                     <div className="centerbox">
//                       <img src={Images.sellimgicon} className="img-fluid centerimg" />
//                     </div>
//                   </Col>
//                   <Col lg={6} className="order-5 order-lg-0">
//                     {/* <img src={Images.profit} className="img-fluid" /> */}
//                     <div className="centerbox">
//                       <img src={Images.profiticon} className="img-fluid centerimg" />
//                     </div>
//                   </Col>
//                   <Col lg={6} className="order-6 order-lg-0">
//                     <h4 className="m-0 home__bannerSubTitle vendin mb-4">
//                       Get your profit
//                     </h4>
//                     <p className="m-0 poppins home__bannerHint mt-2">
//                       We are a community based collection project featuring
//                       3D artwork. 3D sneaker Heads comes in all kinds of evil
//                       shapes, traits and sizes with a few surprise along
//                       the way
//                     </p>
//                   </Col>
//                 </Row>
//               </Col>
//             </Row>
//           </Container>
//         </div>

//         <div className="img_sec pos pb-4">
//           <img src={Images.glitch} className="sec_glitch" />
//           <img src={Images.glitch} className="sec_glitch_l" />
//           <Container className="home__bannerSec">
//             <Row className="flex-column justify-content-center align-items-center mt-5">
//               <Col md={8} sm={10}>
//                 <h4 className="m-0 home__bannerSubTitle vendin text-center">
//                   ROBLOX: PETS, ROBUX AND MORE...
//                 </h4>

//               </Col>
//               <Col md={6} sm={8} xs={10} className="d-flex flex-column align-items-center">

//                 <p className="m-0 poppins home__bannerHint text-center mt-2">
//                   Discover Game by category, track the latest drops and follow the
//                   collections you love. Enjoy it.
//                 </p>
//                 <Link to="/explore"><button className="home__outlineBtn rounded-5 home__btnOutlineHider poppins mt-4">Let's Explore</button></Link>
//               </Col>
//             </Row>
//             {/* <Row className="mt-5">
//               <Col md={12} lg={6} className="mb-4 mb-md-0 mt-3">
//                 <div className="borders"><img src={Images.dino} className="img-fluid dinoimg" alt="" /></div>             
//               </Col>
//               <Col md={12} lg={6} className="mt-3">
//                 <Row>
//                   <Col lg={6} xs={12} className="mb-4">
//                   <div className="borders">
//                     <img src={Images.messi} className="img-fluid smallimg" alt="" /></div>
//                   </Col>
//                   <Col lg={6} xs={12} className="mb-4">
//                   <div className="borders">
//                     <img src={Images.himan} className="img-fluid smallimg" alt="" /></div>
//                   </Col>
//                   <Col lg={12} xs={12}>
//                   <div className="borders">
//                     <img src={Images.barbie} className="img-fluid barbieimg" alt="" /></div>
//                   </Col>
//                 </Row>

//               </Col>
//             </Row> */}
//             <div className="mt-5">
//               <Marquee direction="right" className="mb-4">
//                 {allData.categoryimgs.map((item) =>
//                   <div className="borders mx-2">
//                     <img src={item.cimg} className="img-fluid home__bannerSec_img" alt="" />
//                   </div>
//                 )}
//               </Marquee>

//               <Marquee direction="left">
//                 {allData.categoryimgs.map((item) =>
//                   <div className="borders mx-2">
//                     <img src={item.cimg} className="img-fluid home__bannerSec_img" alt="" />
//                   </div>
//                 )}
//               </Marquee>
//             </div>
//           </Container>
//         </div>

//         <div className="item_gold pos pt-5 pb-5">
//           <img src={Images.glitch} className="sec_glitch1" />
//           <Container className="home__bannerSec">
//             <Row className="flex-column justify-content-center align-items-center mt-5">
//               <Col md={8} sm={10}>
//                 <h4 className="m-0 home__bannerSubTitle vendin text-center">
//                   ITEMS SOLD
//                 </h4>

//               </Col>
//               <Col md={6} sm={8} xs={10}>

//                 <p className="m-0 poppins home__bannerHint text-center mt-2 mb-2">
//                   Discover Game by category, track the latest drops and follow the
//                   collections you love. Enjoy it
//                 </p>
//               </Col>
//             </Row>
//             <div className="item_back rounded-2 py-5 mt-4">
//               <Row className="mt-0 align-items-center">
//                 <Col sm={4} className="mb-5 mb-sm-0">
//                   <h3 className="m-0 orbitron home__counterTitle text-center">4.5/5</h3>
//                   <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">APP STORE RATING</h5>
//                   <p className="m-0 orbitron home__counterHint text-center mt-2">22K+ REVIEWS</p>
//                 </Col>
//                 <Col sm={4} className="mb-5 mb-sm-0">
//                   <h3 className="m-0 orbitron home__counterTitle text-center">12,586,246</h3>
//                   <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">SOLD</h5>
//                 </Col>
//                 <Col sm={4} className="mb-5 mb-sm-0">
//                   <h3 className="m-0 orbitron home__counterTitle text-center">6M+</h3>
//                   <h5 className="m-0 orbitron home__counterSubitle text-center mt-3">REGISTERED USERS</h5>
//                 </Col>
//               </Row>
//             </div>

//           </Container></div>

//         <div className="swipe_sec pos pt-5 pb-5">
//           <img src={Images.glitch} className="sec_glitch" />
//           <Container className="home__bannerSec">
//             <Row className="flex-column justify-content-center align-items-center mt-0">
//               <Col md={8} sm={10}>
//                 <h4 className="m-0 home__bannerSubTitle vendin text-center">
//                   POPULAR GAME ITEMS
//                 </h4>

//               </Col>
//               <Col md={6} sm={8} xs={10}>

//                 <p className="m-0 poppins home__bannerHint text-center mt-2">
//                   Find deals for the latest and most popular games
//                   across all available platforms.
//                 </p>
//               </Col>
//             </Row>
//             <div className="mt-5">
//               <Swiper
//                 loop={true}
//                 pagination={{
//                   dynamicBullets: true,
//                 }}
//                 breakpoints={{
//                   320: {
//                     slidesPerView: 1,
//                     spaceBetween: 20,
//                   },
//                   450: {
//                     slidesPerView: 2,
//                     spaceBetween: 20,
//                   },
//                   576: {
//                     slidesPerView: 2,
//                     spaceBetween: 20,
//                   },
//                   768: {
//                     slidesPerView: 3,
//                     spaceBetween: 20,
//                   },
//                   992: {
//                     slidesPerView: 3,
//                     spaceBetween: 20,
//                   },
//                   1200: {
//                     slidesPerView: 4,
//                     spaceBetween: 20,
//                   },
//                 }}
//                 autoplay={{
//                   delay: 2500,
//                   disableOnInteraction: false,
//                 }}
//                 modules={[Pagination, Autoplay]}
//                 className="mySwiper home__gameSwiper"
//               >
//                 {allData.games.map((item) =>
//                   <SwiperSlide><img src={item.img} className="img-fluid" alt="" /></SwiperSlide>
//                 )}

//               </Swiper>
//             </div>
//           </Container></div>

//         <div className="faq_sec pos pt-5 pb-5">
//           <img src={Images.glitch} className="sec_glitch_l" />
//           <Container className="home__bannerSec">
//             <Row className="flex-column justify-content-center align-items-center mt-0">
//               <Col md={8} sm={10}>
//                 <h4 className="m-0 home__bannerSubTitle vendin text-center">
//                   Frequently Asked Question
//                 </h4>

//               </Col>
//               <Col md={6} sm={8} xs={10}>

//                 <p className="m-0 poppins home__bannerHint text-center mt-2">
//                   The FAQ Page structured data indicates that the page is
//                   considered to have a section with Frequently Asked
//                   Question (FAQ) and answers to those questions.
//                 </p>
//               </Col>
//             </Row>
//             <Row className="mt-5 justify-content-between pb-4">
//               <Col md={4} className="mb-5 mb-md-0">
//                 <img src={Images.mask} className="img-fluid" />
//               </Col>
//               <Col md={8}>
//                 <div className="overflow-auto" style={{ maxHeight: '400px' }}>
//                   <Accordion defaultActiveKey={1} className="home__faqAccord" flush>
//                     {accordionall.map((e, i) =>
//                       <Accordion.Item className="mb-3" eventKey={i + 1}>
//                         <Accordion.Header className="poppins pos" onClick={() => toggleAccordion(i + 1)}>{e.question} {accordionOpen === i + 1 ? <div className="bclr"><IoIosArrowUp /></div> : <div className="bclr"><IoIosArrowForward /></div>} </Accordion.Header>
//                         <Accordion.Body>
//                           <span className="m-0 poppins home__faqHint">
//                             <div dangerouslySetInnerHTML={{ __html: removePTags(e.answer) }} />
//                           </span>
//                         </Accordion.Body>
//                       </Accordion.Item>
//                     )}

//                   </Accordion>
//                 </div>
//               </Col>
//             </Row>
//           </Container></div>


//         <Footer />
//       </Container >
//     </>
//   );
// };

// export default Home;
