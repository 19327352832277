import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import { getCMScontentList } from "../Hooks/Home";


function Privacypolicy() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        getCMScontent();
    }, []);

    const [homeContent, setHomeContent] = useState([]);


    const getCMScontent = async () => {
        try {
            const payload = {
                page: "privacy-policy"
            }
            const resp = await getCMScontentList(payload);

            console.log("RESP DATA_2", resp.data);

            if (resp?.status) {
                setHomeContent(resp.data);
            } else {
                console.error('Failed to fetch CMS content.');
            }
        } catch (error) {
            console.error('An error occurred while fetching CMS content:', error); // Also stop loading if there's an error
        }
    };


    const removeAllTags = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        return tempDiv.textContent || tempDiv.innerText || '';
    };



    return (

        <>

            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages pb-4'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center mb-5'>Privacy Policy</h2>

                        {homeContent.map((data, i) => (

                            <>
                                <div key={i}>
                                    {data.question == 'privacypolicy_top' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }
                                    {data.question == 'privacypolicy_content1' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }
                                    {data.question == 'privacypolicy_content2' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }
                                    {data.question == 'privacypolicy_content3' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }
                                    {data.question == 'privacypolicy_content4' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }
                                    {data.question == 'privacypolicy_content5' &&
                                        <p className='poppins whtclr'>
                                            {removeAllTags(data.description)}
                                        </p>
                                    }

                                </div>
                                <br /><br />
                            </>


                        ))}

                    </div>

                </div>

                <Footer />
            </Container>
        </>

    )
}

export default Privacypolicy