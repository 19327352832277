import './App.css';
import { Navigate, Route, Router, Routes } from 'react-router-dom'
import { onMessage } from 'firebase/messaging';
import { addDoc, collection } from "@firebase/firestore"
import { getToken } from 'firebase/messaging';
import Home from './Screens/Home';
import './Assets/css/Style.css'
import 'swiper/css';
import 'swiper/css/pagination';
import Profile from './Screens/profile';
import Explore from './Screens/explore';
import Carddetail from './Screens/carddetail';
import Support from './Screens/support';
import Sellingtab from './Screens/sellingtab';
import Gamelist from './Screens/gamelist';
import Uploadimg from './Screens/uploadimg';
import Messagepage from './Screens/messagepage';
import Notifications from './Screens/notification';
import Chating from './Screens/chating';
import { ToastContainer } from "react-toastify";
import Privacypolicy from './Screens/privacypolicy';
import Terms from './Screens/terms';
import Aboutus from './Screens/aboutus';
import Login from './Screens/Login';
import Contact from './Screens/Contact';
import Partnership from './Screens/Partnership';
import Detailhistory from './Screens/Detailhistory';
import Chathitorytabless from './Screens/chathistorypage';
import { useEffect } from 'react';
import { GetUserdataHooks, UpdateNotifyTokenHooks } from './Hooks/UserHooks';
import { GetUserAcccount, GetUserCode, GetUserEmail } from './Hooks/UseWallet';

import { messaging } from './Notification/Firebase';

import logo from "./Assets/Images/fav_ico.png"

import { socket } from "./Config/SocketConnectivity";
import SocketContext from "./Context/SocketContext";
import HelperRoute from './Routes/HelperRoutes';


function App() {

  useEffect(() => {
    Getuserdata()
  }, [])

  // console.log=()=>{};
  // console.warning = ()=>{}
  // console.error=()=>{}  
  // console.info =()=>{}


  // useEffect(() => {
  //   messaging.requestPermission()
  //     .then(() => messaging.getToken())
  //     .then(token => {
  //       console.log('Token:', token);
  //       // Send the token to your server to save it for later use
  //     })
  //     .catch(err => {
  //       console.error('Error getting permission or token:', err);
  //     });

  //   messaging.onMessage(payload => {
  //     console.log('Message received. ', payload);
  //     // Display notification here
  //   });
  // }, []);



  useEffect(() => {
    // if (!(Notification in window)) {
    //   alert('This browser does not support notifications.');
    //   return;
    // }
    const requestPermission = async () => {
      try {
        let permission = await Notification.requestPermission();
        console.log("permission", permission);
        if (permission === 'granted') {
          const token = await getToken(messaging, { vapidKey: 'BDNEv0B_x7XgvitYMrviiVd8Jcm7NOJMmEzwrVKZmCeKD1jSGc1OOKb2MM-99sNfZCEodaWCyGGMlpJbvCf56pM' });
          console.log('Token:', token);
          localStorage.setItem("NOTIFY", token);
          if (GetUserCode()) {
            let result = await UpdateNotifyTokenHooks({ id: GetUserCode(), token: token });
          }
          // Send the token to your server to save it for later use
        } else {
          console.error('Permission not granted');
        }
        // Send the token to your server to save it for later use
      } catch (error) {
        console.error('Error getting permission or token:', error);
      }
    };

    requestPermission();

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // Display notification here
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: logo,
        color: '#6563a4',
      };
      const { title, body } = payload.notification;
      new Notification(title, notificationOptions);

    });
  }, []);



  const Getuserdata = async () => {
    if (GetUserCode()) {
      let result = await GetUserdataHooks({ id: GetUserCode() });
      console.log("usercode" , GetUserCode());
    }
  }

  return (
    <>
      <SocketContext.Provider value={{ socket }}>
        <ToastContainer />
        <HelperRoute />
        <Routes>
      
          <Route path='/' element={<Home />} />
          <Route path='/profile' element={GetUserCode() ? <Profile /> : <Navigate replace to={"/"} />} />
          <Route path='/explore' element={<Explore />} />
          <Route path='/explore/:id' element={<Explore />} />
          <Route path='/carddetail/:id' element={<Carddetail />} />
          <Route path='/support' element={GetUserCode() ? <Support /> : <Navigate replace to={"/"} />} />
          <Route path='/sellingtab' element={<Sellingtab />} />
          <Route path='/gamelist' element={<Gamelist />} />
          <Route path='/upload/:id' element={GetUserCode() ? <Uploadimg /> : <Navigate replace to={"/"} />} />
          <Route path='/message' element={<Messagepage />} />
          <Route path='/notification' element={GetUserCode() ? <Notifications /> : <Navigate replace to={"/"} />} />
          <Route path='/chating' element={GetUserCode() ? <Chating /> : <Navigate replace to={"/"} />} />
          <Route path='/chating/:id' element={GetUserCode() ? <Chating /> : <Navigate replace to={"/"} />} />
          <Route path='/privacy' element={<Privacypolicy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/about' element={<Aboutus />} />
          <Route path='/chathistory' element={GetUserCode() ? <Chathitorytabless /> : <Navigate replace to={"/"} />} />
          {/* <Route path='/login' element={<Login />} /> */}
          <Route path='/contact' element={<Contact />} />
          <Route path='/partnership' element={<Partnership />}></Route>
          <Route path='/detailhistory/:id' element={<Detailhistory />}></Route>
        </Routes>
      </SocketContext.Provider>
    </>
  );
}

export default App;
