import axios from "axios"
import config from "../Config/env";

import { FAQ_Item_List } from "../Routes/UserRoutes";

export const getFaqListItems = async () => {
    try{
        let result = await axios.get(config?.API_URL+FAQ_Item_List)
        
        return result?.data;
    }
    catch(e){
        console.log("Error on register new user hooks" , e);
    }
}

