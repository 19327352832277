import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import OtpInput from 'react-otp-input';
import { Images } from './Images';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Walletmodal(props) {

    const [walletmodal, setWalletmodal] = useState(true);
    const handlechanges = () =>{
        localStorage.setItem("metamask",true);
        props.onDismiss();
        // window.location.reload();
        toast.success("Connected Successfully")
    }
    return (

        <div>

            <Modal show={walletmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>
                    <h3 className='whtclr poppins text-center mt-3 mb-4'>Connect Wallet</h3>
                   <div className='d-flex align-items-center justify-content-center'>
                    <div className='walletborder py-3 px-3 me-4 rounded-2' onClick={()=>handlechanges()}><img src={Images.meta} className='img-fluid'/></div>
                    <div className='walletborder py-3 px-3 rounded-2' onClick={()=>handlechanges()}><img src={Images.trust} className='img-fluid'/></div>
                   </div>
                  
                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Walletmodal;