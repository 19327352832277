import React,{ useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Notificationmodal from '../Components/notificationmodal';
import { useLocation } from 'react-router-dom';
import { CHAINS } from '../Config/Network.';
import { GetChain, GetUserAcccount, GetUserCode } from '../Hooks/UseWallet';
import { SetAmountreceived, SetReceivedAsset, SetTransferredAsset, WithdrawFunds } from '../Hooks/UseContract';
import { Refresh } from '../Config/SocketConnectivity';
import { toast } from 'react-toastify';
import { UpdateOrderWithdrawStatusHooks } from '../Hooks/GameHooks';
import { useWallets } from '@privy-io/react-auth';

function Stepperss(props) {

    const { wallets } = useWallets();
    const steps = [
        {
            label: 'Buyer send amount',

        },
        {
            label: `${parseFloat(props?.saledetail?._price)/10**18} ${CHAINS[GetChain()]?.nativeCurrency.symbol} locked in Contract`,

        },
        {
            label : 'Seller Received Amount',
            btnname : "Amount Received"
        },
        {
            label: 'Seller Transferred Assets',
            btnname : "Asset Transferred"

        },
        {
            label: 'Buyer Received Asset',
            btnname : "Asset Received"

        },
        {
            label: 'Seller can withdraw the amount',
            btnname : "Withdraw"

        }
    ];
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeStep, setActiveStep] = React.useState(2);
    const [amtreceive , setAmtreceive] = useState(false);
    const [sendproduct , setSendproduct] = useState(false);
    const [receiveproduct ,setReceiveproduct] = useState(false);
    const [withdraw , setWithdraw] = useState(false);

console.log("active step" , activeStep);

    const handleAmountReceived = async() => {
        try{setAmtreceive(true);
            if(GetUserAcccount() == props?.orderdetail?.createrWallet){
                const wallet = wallets[0];
                const provider = await wallet.getEthereumProvider();
                let result = await SetAmountreceived(props?.saledetail?._gameAddress , provider);
                if(result){
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    let data = props?.orderdetail?.createrID == GetUserCode() ?  props?.orderdetail?.buyerId : props?.orderdetail?.createrID
                    Refresh(data);
                    props?.refresh()
                }
            }
            else{
                toast.error("Connected wrong wallet address");
            }
            
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }

    const handleAssetTransferred = async() => {
        try{setSendproduct(true)
            const wallet = wallets[0];
            const provider = await wallet.getEthereumProvider();
            let result = await SetTransferredAsset(props?.saledetail?._gameAddress , provider)
            if(result)
                {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    let data = props?.orderdetail?.createrID == GetUserCode() ?  props?.orderdetail?.buyerId : props?.orderdetail?.createrID
                    Refresh(data);
                    props?.refresh()
                }
            
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }
    

    const handleAssetReceived = async() => {
        try{setReceiveproduct(true);
            const wallet = wallets[0];
            const provider = await wallet.getEthereumProvider();
            let result = await SetReceivedAsset(props?.saledetail?._gameAddress , props?.orderdetail?._id , provider);
            if(result){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                let data = props?.orderdetail?.createrID == GetUserCode() ?  props?.orderdetail?.buyerId : props?.orderdetail?.createrID
                Refresh(data);
                props?.refresh()
            }
            
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }

    const handleWithdraw = async() => {
        try{setWithdraw(true);
            const wallet = wallets[0];
            const provider = await wallet.getEthereumProvider();
            let result = await WithdrawFunds(props?.saledetail?._gameAddress , provider)
            if(result){
                let upstatus = await UpdateOrderWithdrawStatusHooks({id : window.location.href?.split("/")[4] })
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                let data = props?.orderdetail?.createrID == GetUserCode() ?  props?.orderdetail?.buyerId : props?.orderdetail?.createrID
                Refresh(data);
                props?.refresh()
            }
            
        }
        catch(e){
            console.log("Error on handle amount received" , e);
        }
    }
    

    useEffect(() => {console.log("props?.saledetail?.isWithdraw" , props?.saledetail);
        if(props?.saledetail?.isWithdraw){
            setActiveStep(7);
        }
       else if(props?.saledetail?.isAssetReceived){
            setActiveStep(5)
        }
        else if(props?.saledetail?.isTransferredAsset){
            setActiveStep(4);
        }
        else if(props?.saledetail?.isAmountReceived){
            setActiveStep(3);
       }
         
    } , [props?.saledetail])






    const handleNext = () => {
        if(activeStep == 0){
            setNotifymodal(true);
        }
        if(activeStep == 2){
            handleAmountReceived()
        }
        if(activeStep == 3){
            handleAssetTransferred()
        }
       if(activeStep == 4){
        handleAssetReceived()
       }
       if(activeStep == 5){
        handleWithdraw()
       }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const location = useLocation();
      {console.log(location,"lolo");}
    const [notifymodal, setNotifymodal] = useState(false);

    return (

        <div>
            {/* !props?.saledetail?.isRefund  */}
            {notifymodal && <Notificationmodal onDismiss={()=>setNotifymodal(false)} pathname={location.pathname}/>}

            { !props?.saledetail?.isRefund  && !props?.saledetail?.reason ?    
            <Box sx={{ maxWidth: "100% " }}>
                <Stepper activeStep={activeStep} 
                // orientation={windowWidth > 991 ? "horizontal" :"vertical"}
                orientation="vertical"
                >
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                // optional={
                                //     index === 3 ? (
                                //         <Typography variant="caption">Last step</Typography>
                                //     ) : null
                                // }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {/* <Typography>{step.description}</Typography> */}
                                {activeStep == 2 && <Box sx={{ mb: 2 }}>
                                    {props?.orderdetail?.createrID == GetUserCode() && <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled = {amtreceive}
                                        >
                                            {step?.btnname}
                                            {/* {index === steps.length - 1 ? 'Complete' : 'Next'} */}
                                        </Button>
                                        {/* <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button> */}
                                    </div>}
                                </Box>}

                                {activeStep == 3 && props?.orderdetail?.createrID == GetUserCode() && <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled = {sendproduct}
                                        >
                                            {step?.btnname}
                                            {/* {index === steps.length - 1 ? 'Complete' : 'Next'} */}
                                        </Button>
                                        {/* <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button> */}
                                    </div>
                                </Box>}

                                {activeStep == 4 && props?.orderdetail?.buyerId == GetUserCode() && <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled = {receiveproduct}
                                        >
                                            {step?.btnname}
                                            {/* {index === steps.length - 1 ? 'Complete' : 'Next'} */}
                                        </Button>
                                        {/* <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button> */}
                                    </div>
                                </Box>}

                                {activeStep == 5 && props?.orderdetail?.createrID == GetUserCode() && <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled = {withdraw}
                                        >
                                            {step?.btnname}
                                            {/* {index === steps.length - 1 ? 'Complete' : 'Next'} */}
                                        </Button>
                                        {/* <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button> */}
                                    </div>
                                </Box>}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className='mt-4' sx={{ p: 3 }}>
                        <Typography>Received your Asset - Completed.</Typography>
                       <Link to="/explore"><Button 
                    //    onClick={handleReset} 
                       sx={{ mt: 1, mr: 1 }}>
                           Explore
                        </Button></Link>
                    </Paper>
                )}
            </Box> :

            <>
            <div className='buyrefund'>
            <h5 className='whtclr poppins fw-600 text-center'> Refunded</h5>
            </div>
             {/* <h5 className='whtclr poppins text-center'>Buyer Refunded</h5> */}
            </> }
            
           
        </div>

    )
}

export default Stepperss