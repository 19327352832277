import { SiKnowledgebase } from 'react-icons/si';

export const Images = {
    logo: require('../Assets/Images/logo.png'),
    leftModal: require('../Assets/Images/leftModal.svg').default,
    rightModal : require('../Assets/Images/rightModal.svg').default,
    middleModal : require('../Assets/Images/midModal.png'),
    huobi: require('../Assets/Images/huobi.svg').default,
    bitcoin: require('../Assets/Images/bitcoin.svg').default,
    ripple: require('../Assets/Images/ripple.svg').default,
    gemini: require('../Assets/Images/gemini.svg').default,
    ethereum: require('../Assets/Images/ethereum.svg').default,
    binance: require('../Assets/Images/binance.svg').default,
    payeer: require('../Assets/Images/payeer.svg').default,
    wallet: require('../Assets/Images/wallet.svg').default,
    game: require('../Assets/Images/game.svg').default,
    trade: require('../Assets/Images/trade.svg').default,
    dino: require('../Assets/Images/collection1.png'),
    blackd: require('../Assets/Images/blackd.svg').default,
    barbie: require('../Assets/Images/collection4.png'),
    himan: require('../Assets/Images/collection3.png'),
    messi: require('../Assets/Images/collection2.png'),
    pubg: require('../Assets/Images/pubg.svg').default,
    daylight: require('../Assets/Images/daylight.svg').default,
    godofwar: require('../Assets/Images/godofwar.svg').default,
    freefire: require('../Assets/Images/freefire.svg').default,


    // innerpages
    curvebimg: require('../Assets/Images/curveblack.png'),
    curvevimg: require('../Assets/Images/curve.png'),

    pimg: require('../Assets/Images/pimgs.png'),
    newbanner:require('../Assets/Images/newbanner.png'),
    bannerimg: require('../Assets/Images/bannerimg.svg').default,
    memberimg: require('../Assets/Images/member.svg').default,
    cardimg: require('../Assets/Images/cardimg.svg').default,
    imgicon: require('../Assets/Images/imgicon.svg').default,
    game1: require('../Assets/Images/game1.png'),
    game2: require('../Assets/Images/game2.png'),
    game3: require('../Assets/Images/game3.png'),
    game4: require('../Assets/Images/game4.png'),
    game5: require('../Assets/Images/game5.png'),
    game6: require('../Assets/Images/game6.png'),
    game7: require('../Assets/Images/game7.png'),
    folder: require('../Assets/Images/Folder.png'),
    knowledge: require('../Assets/Images/acknowledgement.png'),
    raise: require('../Assets/Images/raise.png'),
    pipeline: require('../Assets/Images/pipeline.png'),
    meta: require('../Assets/Images/metmask.png'),
    trust: require('../Assets/Images/trust.png'),

    cardimg1: require('../Assets/Images/cardimg1.svg').default,
    cardimg2: require('../Assets/Images/cardimg2.svg').default,
    cardimg3: require('../Assets/Images/cardimg3.svg').default,


    underworld: require('../Assets/Images/under_world.png'),
    bliss: require('../Assets/Images/bliss.gif'),
    shake: require('../Assets/Images/shake.gif'),
    glitch: require('../Assets/Images/glitch.gif'),
    spaceman: require('../Assets/Images/spaceman.png'),

    walletimg: require('../Assets/Images/walletimg.png'),
    sellimg: require('../Assets/Images/sellnft.png'),
    profit: require('../Assets/Images/profit.png'),
    widestar: require('../Assets/Images/wide.png'),
    mask: require('../Assets/Images/mask.png'),

    // new

    walletimgicon: require('../Assets/Images/walletimgicon.png'),
    sellimgicon: require('../Assets/Images/sellimgicon.png'),
    profiticon: require('../Assets/Images/profiticon.png'),

    categoryimgone: require('../Assets/Images/categoryimgone.png'),
    categoryimgtwo: require('../Assets/Images/categoryimgtwo.png'),
    categoryimgthree: require('../Assets/Images/categoryimgthree.png'),
    categoryimgfour: require('../Assets/Images/categoryimgfour.png'),
    categoryimgfive: require('../Assets/Images/categoryimgfive.png'),
    categoryimgsix: require('../Assets/Images/categoryimgsix.png'),
    categoryimgseven: require('../Assets/Images/categoryimgseven.png'),
    categoryimgeight: require('../Assets/Images/categoryimgeight.png'),

    visa: require('../Assets/Images/visa.png'),
    mastercard: require('../Assets/Images/mastercard.png'),
    matic: require('../Assets/Images/matic.png'),


}