import React, { useState, useEffect } from 'react';
import StarRatings from 'react-star-ratings';
import ReactHtmlParser from 'react-html-parser';
import ReactStars from "react-rating-stars-component";
import { Alert, Container } from "react-bootstrap";
import Header from '../Components/Header';
import { Images } from "../Components/Images";
import Footer from '../Components/Footer';
import Nofificationmodal from '../Components/notificationmodal';
import { Link, useLocation } from 'react-router-dom';
import EditCardModal from '../Components/EditCardModal';
import Reviewmodal from '../Components/reviewmodal';
import { GetSingleGameDetailHooks } from '../Hooks/GameHooks';
import config from "../Config/env";
import { CHAINS } from '../Config/Network.';
import { GetChain, GetUserCode } from '../Hooks/UseWallet';
import { toFixedNumber } from '../Hooks/UseContract';
import { GetReviewDetaillistHooks } from '../Hooks/UserHooks';
import Showreview from '../Components/showreviewmodal';

function Carddetail(props) {
    const [notifymodal, setNotifymodal] = useState(false);
    const location = useLocation();
    { console.log(location, "lolo"); }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    const [editmodal, setEditmodal] = useState(false);
    const [reviewmodals, setReviewmodals] = useState(false);
    const [viewrev, SetViewRev] = useState(false);

    //dev-murugavel
    const [gamedetail, setGamedetail] = useState({});
    // const [reviewlist , setReviewlist] = useState([]);
    const [rating , setRating] = useState(0);
    const [reviewlist , setReviewlist] = useState([]);


    useEffect(() => {
        fetchdata()
    }, [])
    const fetchdata = async () => {

        console.log("_id", window.location.href.split("/")[4]);

        try {
            let data = await GetSingleGameDetailHooks({ id: window.location.href.split("/")[4] });

            console.log("DAtA", data);
            
            if (data?.type == "Success") {
                setGamedetail(data?.record)
                let reviewdata = await GetReviewDetaillistHooks({id : data?.record?.createrId?._id});
                if(reviewdata?.type == "Success"){
                    setReviewlist(reviewdata?.record)
                }
                let val = data?.record?.createrId?.review?.reduce((accumulator, currentValue) => accumulator + currentValue?.rating,0,)
                setRating(val/ data?.record?.createrId?.review?.length);
            }
        }
        catch (e) {
            console.log("Error on fetchdata", e);
        }
    }

    const handleReload = () => {
        fetchdata();
    }
    const [showreviewmodal, setShowreviewmodal] = useState(false);

    return (

        <>
            {notifymodal && <Nofificationmodal onDismiss={() => setNotifymodal(false)} pathname={"Buy_Request"} 
                assetname = {gamedetail?.name} price = {gamedetail?.price?.toString().split('.')[1].length > 10 ? parseFloat(gamedetail?.price)?.toFixed(10) : gamedetail?.price} gamedetail = {gamedetail}
                />}
            {editmodal && <EditCardModal fn={handleReload} data={gamedetail?._id} onDismiss={() => setEditmodal(false)} />}
            {reviewmodals && <Reviewmodal data = {gamedetail?.createrId?.review?.find(e => e?.userid == GetUserCode())} onDismiss={() => setReviewmodals(false)} fn={handleReload}  vendorid = {gamedetail?.createrId?._id}/>}
            {showreviewmodal && <Showreview onDismiss={() => setShowreviewmodal(false)} reviewlist={reviewlist}/>}

            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center pt-5 pt-sm-0 mb-3 mb-sm-4'>Description</h2>

                        <div className='row'>
                            <div className='col-md-5 mt-4'>
                                <div className='carddetails py-3 px-3'>
                                    <img src={config?.API_URL + gamedetail?.imagePath + gamedetail?.image} className='img-fluid w-100 h-100' alt='image' />
                                </div>
                            </div>
                            <div className='col-md-7 mt-4'>
                            
                               {gamedetail?.approveStatus == "Rejected" && <div className='bglightbox cdet'>
                                    <p className="whtclr poppins mb-2 violettext">Admin Comment:</p>
                                    <p className="whtclr poppins fw-300 mb-0">
                                        {gamedetail?.rejectReason}
                                       
                                    </p>
                                </div>}

                               <div className='ms-3'>
                                <p className='whtclr poppins mb-4'>{gamedetail?.gameDescription}</p>
                                <div className='cardlistdetail_desc'>
                                    <ul>
                                        <li>
                                            <p className='label poppins'>Title</p>
                                            <p className='value poppins'>{gamedetail?.name}</p>
                                        </li>
                                        <li>
                                            <p className='label poppins'>Category</p>
                                            <p className='value poppins'>{gamedetail?.category_id?.name}</p>
                                        </li>
                                        <li>
                                            <p className='label'>Quantity</p>
                                            <p className='value poppins'>{gamedetail?.quantity}</p>
                                        </li>
                                        <li>
                                            <p className='label poppins'>Availability</p>
                                            <p className='value poppins'>Yes</p>
                                        </li>
                                    </ul>

                                </div>
                                <div>
                                    <h4 className='whtclr vendin mb-4'>Item Details</h4>
                                    <p className='whtclr poppins fw-300'>{ReactHtmlParser(gamedetail?.gameDetail)}
                                    </p>
                                </div>


                                <div className='d-flex align-items-start mt-4 mb-4'>
                                    <img src={gamedetail?.createrId?.profileImage ? config?.API_URL+gamedetail?.createrId?.profilePath + gamedetail?.createrId?.profileImage : Images.pimg} className='img-fluid imgicon me-2' alt='images' />
                                    <div>
                                        <h5 className='whtclr'>Owner</h5>
                                        <p className='whtclr poppins mb-0'>{gamedetail?.createrId?.name ? gamedetail?.createrId?.name : ""}</p>
                                        {console.log("ratingv" , rating)}
                                        {rating ?  <p className='whtclr poppins mb-0 d-flex align-items-center gap-2'>
                                            <StarRatings
                                                    // rating={e.rating}
                                                    rating={rating && parseFloat(rating)}
                                                    starRatedColor="gold"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="20px"
                                                    starSpacing="5px"
                                                />
                                            {/* <ReactStars  value={rating && rating} count={5} size={20}  isHalf={true} activeColor="#FFBB0D" /> */}
                                        <span className='ratetext'>({parseFloat(rating).toFixed(1)})</span></p>:
                                        <p className='whtclr poppins mb-0 d-flex align-items-center gap-2'>
                                            <StarRatings
                                                    // rating={e.rating}
                                                    rating={0}
                                                    starRatedColor="gold"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="20px"
                                                    starSpacing="5px"
                                                />
                                        <span className='ratetext'>(0)</span></p>    
                                    }
                                    </div>
                                </div>

                                <h5 className='whtclr poppins fw-600'>Price</h5>
                                <h3 className='whtclr poppins fw-600'>{gamedetail?.price?.toString()?.split('.')[1]?.length > 10 ?  parseFloat(gamedetail?.price)?.toFixed(10) : gamedetail?.price} <span className='spanvioclr vioclr fw-300'>{CHAINS[GetChain()]?.nativeCurrency.symbol}</span></h3>

                                {GetUserCode() && gamedetail?.createrId?._id == GetUserCode() && gamedetail?.approveStatus == "Rejected" && 
                                    
                                    <button className='header__connectBtn rounded-3 px-4 vendin mt-4 me-2' onClick={() => setEditmodal(true)}>Edit / ReUpload</button>}

                                {GetUserCode() && gamedetail?.createrId?._id != GetUserCode() && gamedetail?.status == "New" && 
                                
                                (parseFloat(gamedetail?.quantity) > 0  ?  <button className='header__connectBtn rounded-3 px-4 vendin mt-4' onClick={() => setNotifymodal(true)}>Buy Now</button>: 
                                <button className='header__connectBtn rounded-3 px-4 vendin mt-4' disabled>Sold</button>
                                )}

                                {GetUserCode() && gamedetail?.createrId?._id != GetUserCode() && !gamedetail?.createrId?.review?.find(e => e?.userid == GetUserCode()) &&
                                
                                <button className='header__connectBtn rounded-3 px-4 vendin mt-4 ms-2' onClick={() => setReviewmodals(true)}>Add Review</button>}
                                {GetUserCode() && gamedetail?.createrId?._id != GetUserCode() && gamedetail?.createrId?.review?.find(e => e?.userid == GetUserCode()) &&
                                
                                <button className='header__connectBtn rounded-3 px-4 vendin mt-4 ms-2' onClick={() => setReviewmodals(true)}>Edit Review</button>}

                                <button className='header__connectBtn rounded-3 px-4 vendin mt-4 ms-2' 
                                // onClick={() => SetViewRev(!viewrev)}
                                onClick={()=>setShowreviewmodal(true)}
                                >Show Review</button>
                                {/* {viewrev &&
                                    <div className='reviewlisted mt-5'>
                                        <ul>
                                            {console.log("reviewlist" , reviewlist)}
                                            {reviewlist?.review?.map((e , i) => (
                                                <li>
                                                    <div>
                                                        <p className='name'>{reviewlist?.detail[i]?.name}</p>
                                                        <p className='desc'>{e?.comment}</p>
                                                    </div>
                                                    <div>
                                                        <ReactStars value={e?.rating} count={5} size={24} edit={true} isHalf={false} activeColor="#FFBB0D" />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                                } */}
</div>

                            </div>
                        </div>

                    </div>

                </div>

                <Footer />
            </Container>
        </>

    )
}

export default Carddetail;