import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';

function Timemodal(props) {

    const [timesmodal, setTimesmodal] = useState(true);


    return (

        <div>
            <Modal show={timesmodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>

                            <h3 className='vendin whtclr text-center'>Do you want to the time?</h3>
                            <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' onClick={() => props.onDismiss()}>Yes</button><button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => props.onDismiss()}>No</button></div>
                               

                </Modal.Body>
            </Modal>

        </div>

    )
}

export default Timemodal;