const BASE_URL = "user";

const ADMIN_URL = "admin"

const GAME_URL = "game";

export const RegisteruserApi = BASE_URL + "/register-user";

export const UpdateUserdataApi = BASE_URL + "/update-userdata";

export const GetUserdataApi = BASE_URL + "/get-userdata";

export const RegisterUserEmailApi = BASE_URL + "/register-user-email";

export const VerifyRegisterEmailApi = BASE_URL + "/verify-register-email";

export const LoginApi = BASE_URL + "/login";

export const ChangeEmailApi = BASE_URL + "/change-email";

export const VerifyOldEmailApi = BASE_URL + "/verify-old-email";

export const VerifyNewEmailApi = BASE_URL + "/verify-new-email";

export const SocialMediaApi = BASE_URL + '/get-social-media';

export const FAQ_Item_List = BASE_URL + '/get-FAQ-List';

export const GetNotificationApi = BASE_URL + "/get-notification";

export const GetAllNotificationApi = BASE_URL + "/get-all-notification";

export const GET_CMS_ITEMS = ADMIN_URL + '/get-all-cms-list';


export const GetDeliveryMethodApi = ADMIN_URL + "/get-deliverymethod";

export const ReadOneNotificationApi = BASE_URL + "/read-one-notification";

export const GetGameItemsList = GAME_URL + "/get-user-game-list";

export const GetUserOrderItemsList = BASE_URL + "/get-user-order";

export const GetOrderItemsList = BASE_URL + "/get-user-order-list";

export const UpdateNotifyTokenApi = BASE_URL + "/update-notify-token";

export const UpdateGameStatus = GAME_URL + "/update-status";

export const UpdateOrderStatus = GAME_URL + "/update-user-order-status";

export const AddReviewApi = BASE_URL + "/add-review";

export const RestoreQuantityApi = GAME_URL + "/restore-quantity"

export const LandingDataApi = BASE_URL + "/get-landing-data";

export const GetReviewDetailListApi = BASE_URL+"/get-user-review-detail";

export const EditReviewApi = BASE_URL + "/edit-review";

