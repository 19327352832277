import axios from "axios"
import config from "../Config/env";

import { GET_CMS_ITEMS } from "../Routes/UserRoutes";

import { Decryptdata } from "./Authenticate";

export const getCMScontentList = async (payload) => {
    try{
        let resp = await axios.get(config?.API_URL+GET_CMS_ITEMS, {params: payload})

        let response = Decryptdata(resp?.data)
        return response;
    }
    catch(e){
        console.log("Error on register new user hooks" , e);
    }
}

