import React, { useEffect, useMemo, useState } from 'react';
import { Container, Button, Accordion, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form } from 'react-bootstrap';
import Select from 'react-select'
import { GetAllGameItemHooks, GetSingleGameDetailHooks } from '../Hooks/GameHooks';
import { CHAINS } from '../Config/Network.';
import { GetChain } from '../Hooks/UseWallet';
import { DecryptString, EncryptString } from '../Hooks/Authenticate';
import { useNavigate } from 'react-router-dom';
function Filterpage(props) {
    const navigate = useNavigate();
    const [popularlist, setPopularlist] = useState([
        { name: "Dragon" }, { name: "Llama" }, { name: "Evil unicorn" }, { name: "Dragon Unicorns" }, { name: "Llama" }, { name: "Evil unicorn" }
    ]);

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const customStyles = {
        control: (base, state, isSelected, isFocused) => ({
            ...base,
            background: "transparent",
            color: "#fff",
            // match with the menu
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "#383838" : "#383838",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "#383838" : "#383838"
            }
        }),

        option: (styles, { isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isFocused ? "#6e4794" : "#000",
            // backgroundColor: isSelected ? "#6e4794" : "#000",
            zIndex: 1,
            color: "#fff",
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            background: '#000',
            color: '#fff',
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            background: 'transparent'
        })
    };

    //dev-murugavel
    const [categorylist , setCategorylist] = useState([]);
    const [selectedcategory , setSelectedcategory] = useState();
    const [selectedcategoryid , setSelectedcategoryid] = useState("");
    const [backupcategory , setBackupcategory] = useState([])
    const [pricelist , setPricelist] = useState([{value : "10 And Under" , label : "10 And Under" , val : [0 , 10]} , 
        {value : "11 - 20" , label :"11 - 20" ,   val : [11 , 20]} , {value : "21 - 40" , label :"21 - 40" , val : [21 , 40]} , {value : "41 - 60" , label :"41 - 60"  , val : [41 , 60]} , 
        {value : "61 - 100" , label :"61 - 100"  , val : [61 , 100]} , {value : "100 And Above" , label :"100 And Above"  ,  val : [100 , 0]}]);
    const [selectedprice , setSelectedprice] = useState();
    const [availabilitylist , setAvailabilitylist] = useState([{value : "New" , label : "OnSale"} , {value : "Sold" , label : "Sold"}]);
    const [availability , setAvailability] = useState();
    const [load , setLoad] = useState(false);

    const fetchdata = async() => {
        try{
            let filter = false;
            let payload = {}
            const query = new URLSearchParams(window.location?.search);
            console.log("queeraesfasfas" ,query.get(selectedprice));
            let result = await GetAllGameItemHooks();
            if(result?.type == "Success"){
                let finaldata = result?.record?.reduce((acc , cuu) => {acc.push({value : cuu?.name , label : cuu?.name}) ; return acc;}, []);
                setCategorylist(finaldata);
                setBackupcategory(result?.record);
                if(query.get("catid")){
                    setSelectedcategory({value : result?.record?.find(e => e?._id == query.get("catid"))?.name , label : result?.record?.find(e => e?._id == query.get("catid"))?.name})
                    filter = true
                    setSelectedcategoryid(result?.record?.find(e => e?._id == query.get("catid"))?._id)
                    payload.selectedcategory = result?.record?.find(e => e?._id == query.get("catid"))?._id //{value : query.get("catid") , label : query.get("catid")}
                }
                if(query.get("availability")){
                    setAvailability(DecryptString(query.get("availability") , "pay"));
                    filter = true;
                    payload.availability = DecryptString(query.get("availability") , "pay");
                }
                if(query.get("selectedprice")){
                    filter = true;
                    setSelectedprice(DecryptString(query.get("selectedprice") , "pay"));
                    payload.selectedprice = DecryptString(query.get("selectedprice") , "pay")
                }
                // if(filter){
                console.log("Filter payload", payload , query.get("selectedprice") , query.get("availability"));
                    props.filter(payload);
                    setTimeout(() => {
                        setLoad(true);
                    } , 2000)
                    
                // }
            }
        }
        catch(e){
            console.log("error on fetchdata" , e);
        }
    }

    const ClearAll = async() => {
        try{
            setSelectedcategory();
            setAvailability();
            setSelectedcategoryid();
            setSelectedprice();
            props.filter();
            navigate("/explore");
            // window.location.reload();
        }
        catch(e){
            console.log("Error on clearall" , e);
        }
    }

    useState(() => {
        fetchdata()
    } , [])

    useMemo(() => {
        const params = new URLSearchParams();
        if(availability || selectedcategory || selectedprice){
            let payload = {
                availability,
                selectedcategory : backupcategory?.find(e => e?.name == selectedcategory?.value)?._id ? backupcategory?.find(e => e?.name == selectedcategory?.value)?._id : "",
                selectedprice
            }
            params.set('catid', backupcategory?.find(e => e?.name == selectedcategory?.value)?._id ? backupcategory?.find(e => e?.name == selectedcategory?.value)?._id : "",);
            let av;
            let pr;
            if(availability){
                av = EncryptString(JSON.stringify(availability) , "pay");
                params.set('availability', av);
            }
            if(selectedprice){
                pr = EncryptString(JSON.stringify(selectedprice) , "pay")
                params.set('selectedprice', pr);
            }
            console.log("sdfsdafsadfa" , av , pr , availability , selectedprice);
            // let encrypt = EncryptString(JSON.stringify(payload) , "payload");
            // console.log("encrypt" , JSON.parse(window.location.href.split("/")[4]) , window.location.href.split("/")[4]);
            // navigate(`/explore?catid=${backupcategory?.find(e => e?.name == selectedcategory?.value)?._id?backupcategory?.find(e => e?.name == selectedcategory?.value)?._id:""}&availability=${av}):""}&selectedprice=${pr}`)
            navigate({
                pathname: '/explore',
                search: params.toString(),
              });
            props.filter(payload);
        }
        
    } , [availability , selectedcategory , selectedprice])



    return (
        <>
            <div>
                <div className='d-flex justify-content-between align-items-center brbb mb-3'>
                    <h4 className='whtclr vendin mb-0'>FILTER BY</h4>
                    <p className='all vendin mb-0' onClick={ClearAll}>Clear all</p>
                </div>

                {/* <Accordion defaultActiveKey={1}>
                {props.list.map((e, i) =>
                    <Accordion.Item eventKey={i + 1}>
                        <Accordion.Header onClick={() => props.toggleAccordion(i + 1)}><h4 className="whtsclr fw-300 mb-0">{e.cat}</h4> </Accordion.Header>
                        <Accordion.Body>
                            <input type="search" className="form-control profinput mb-2" placeholder="Search" />
                            {e.filters.map((val, i) =>
                                <div className="d-flex align-items-center justify-content-between pt-3">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(event) => props.handlechange(val.name, e.cat, event)} checked={val.checked} />
                                        <label class="form-check-label whtsclr fw-200 f-20 ms-2" for="flexCheckDefault">
                                            {val.name}
                                        </label>
                                    </div>
                                </div>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion> */}

                {/* <Accordion>
                    {props.datas.map((ai, i) => (
                        <Accordion.Item eventKey={i}>
                            <Accordion.Header>{ai.filhead}</Accordion.Header>
                            <Accordion.Body>
                                <Form.Control placeholder='search'></Form.Control>
                                <div className='bglightbox'>
                                    {ai.filters.map((af) => (
                                        <div className='filtext'>{af}</div>
                                    ))}

                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion> */}

                <div className="explore_gameFilter">
                    {/* <div className="cmn_sec ">
                        <p className='label_txt mb-0'>ASSET TYPE</p>
                        <Select options={options}
                            styles={customStyles}
                        />
                    </div> */}
                    <div className="cmn_sec ">
                        <p className='label_txt mb-0'>CATEGORY</p>
                        <Select options={categorylist}
                        className='selecttext'
                            styles={customStyles}
                            value={selectedcategory}
                            onChange={setSelectedcategory}
                        />
                       { console.log("selectedcategory" , selectedcategory)}
                    </div>
                    <div className="cmn_sec ">
                        <p className='label_txt mb-0'>PRICE IN {CHAINS[GetChain()]?.nativeCurrency?.symbol}</p>
                        <Select options={pricelist}
                         className='selecttext'
                            styles={customStyles}
                            value={selectedprice}
                            onChange={setSelectedprice}
                        />
                    </div>
                    <div className="cmn_sec">
                        <p className='label_txt mb-0'>AVAILABILITY</p>
                        <Select options={availabilitylist}
                         className='selecttext'
                            styles={customStyles}
                            value={availability}
                            onChange={setAvailability}
                        />
                    </div>
                </div>


                {/* <div className='d-flex justify-content-between align-items-center brbb mb-3 mt-4'>
                    <h4 className='whtclr vendin mb-0'>Popular search</h4>
                    <p className='all vendin mb-0'>Clear all</p>
                </div>

                <div className='popularflex'>
                    {popularlist.map((e, i) =>
                        <p className='popular px-2 py-2 mb-0 rounded-2 poppins'>{e.name}</p>
                    )}
                </div> */}
            </div>

        </>
    )
}

export default Filterpage;