// import package
import io from 'socket.io-client';

// import lib
import config from './env';

const socket = io(config.API_URL);
socket.on('connect', function() {
});

const createSocketUser = (userId) => {
    socket.emit('USERCONNECT', userId)
}

const RemoveSocketUser = (userId) => {console.log("Disconnect");
    socket.emit('USERDISCONNECT', userId)
}

const SendSupportMessage = (data) => {
    socket.emit('SENDSUPPORTMESSAGE' , data);
}

//game chat
const CreateRoom = (data) => {
    socket.emit("CREATEROOM" , data)
}

const JoinRoom = (data) => {
    socket.emit("JOINROOM" , data)
}

const SendMessage = (data) => {
    socket.emit("SENDMESSAGE" , data);
}

const Refresh = (data) => {
    socket.emit("REFRESH" , data);
}

export {
    socket,
    createSocketUser, RemoveSocketUser , SendSupportMessage , CreateRoom , SendMessage ,JoinRoom , Refresh
}