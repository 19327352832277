import React, { useEffect, useState } from 'react'
import browser from 'browser-detect';
import Countdown from 'react-countdown';
import { Col, Container, Offcanvas, Row, Dropdown, DropdownItem, Modal } from 'react-bootstrap';
import { Images } from './Images';
import { NavLink } from 'react-router-dom';
import { IoCloseSharp } from "react-icons/io5";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import OtpInput from 'react-otp-input';
import { ValidateLogin, ValidateRegister } from '../Validation/UserValidation';
import { LoginHooks, RegisterUserEmailHooks, VerifyRegisterEmailHooks, getGeoInfoData } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';

const Formpage = (props) => {

    const [formsmodal, setFormsmodal] = useState(true);
    const [formactive, setFormactive] = useState(props?.logdata == "login" ?  "login":"register");
    const [resen , setResen] = useState(true);

    
    const [showPassword, setShowPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    
    const [showPassword1, setShowPassword1] = useState(false);
    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };

    const [registerstep, setRegisterstep] = useState(1)
    const [otp, setOtp] = useState('');
    const [forceRender, setForceRender] = useState(false);

    //dev-murugavel
    const [email , setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [error , setError] = useState({});
    const [expired , setExpired] = useState("");
    const [loginhistory , setLoginHistory] = useState({});
    const [name , setName] = useState("");

    const getGeoInfo = async () => {
        try {
            let { result } = await getGeoInfoData();
            const browserResult = browser();
            if(!result.ip){
                getGeoInfo();
            }
            console.log("login history", result, browserResult);
            setLoginHistory({
                countryName: result.country_name,
                countryCode: result.country_calling_code,
                ipaddress: result.ip, //ipString
                region: result.region,
                broswername: browserResult.name,
                ismobile: browserResult.mobile,
                os: browserResult.os,
            })
        }
        catch (err) {
        }
    };
    
    useEffect(() => {
        // if(props?.logdata == "login"){
        //     setFormactive("login")
        // }
        console.log("props?.logdata" , props);
      getGeoInfo();
    } , [])

    const handleRegister = async(e) => {
        try{
            let payload = {
                email : email,
                password : password,
                confirmpassword : password1,
                name : name
            }
            let validate = ValidateRegister(payload, "" , true);
            console.log("validater" , validate);
            if(Object.keys(validate)?.length > 0){console.log("if");
                setError(validate);
            }
            else{
                let register = await RegisterUserEmailHooks(payload);
                if(register.type == "Success"){
                    setExpired(register?.expired) //register?.expired
                    toast.success(register?.message);
                    setRegisterstep(2);
                }
                else{
                    toast.error(register?.message);
                }
            }
        }
        catch(e){
            console.log("Errror on handle register" , e);
        }
    }
   const handleVerify = async() => {
    if(otp?.length >= 4){
        let payload = {
            email : email,
            otp : otp
        };
        let verify = await VerifyRegisterEmailHooks(payload);
        if(verify?.type == "Success"){
            toast.success("Registered Successfully!")
            setRegisterstep(1);
            setFormactive("login");
            setOtp("");
            setEmail("");
            setPassword("")        
            setPassword1("")
        }
        else if(verify?.type == "Failed"){
            toast.error(verify?.message);
        }
    }
    else{
        let err = {};
        if(otp?.length > 0)
            err.otp = "Invalid OTP"
        else
            err.otp = "Field is required"

        setError(err)
    }
   }
    const handleLogin = async() => {
        try{
            let payload = {
                email : email,
                password : password,
                loginHistory : loginhistory,
                notifytkn : localStorage.getItem("NOTIFY")
            }
            let validate = ValidateLogin(payload , "" , true)
            if(Object.keys(validate)?.length > 0){
                setError(validate)
            }
            else{
                setError({});
                let result = await LoginHooks(payload);
                console.log("result" , result);
                if(result?.type == "Success"){
                    toast.success("Login Successfully!. Please Connect Your Wallet");
                    setTimeout(() => {
                        props?.onLogin();
                        props?.onDismiss();
                    } , 1000)
                    
                }
                else if(result?.type == "Failed"){
                    toast.error(result?.message);
                }
            }
            
        }
        catch(e){
            console.log("Error on handle login" , e);
        }
    }
    

    const handleResend = async(e) => {
        try {
            let payload = {
                email: email,
                password: password,
                confirmpassword: password1
            }
            let register = await RegisterUserEmailHooks(payload);
            if (register.type == "Success") {
                setExpired(register?.expired); //register?.expired
                setResen(true);
                setForceRender(!forceRender);
            }
            else {
                toast.error(register?.message);
            }
        }
        catch(e){
            console.log("Errror on handle register" , e);
        }
    }
    

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed stateYou are good to go
            setResen(false);
            return <span className='whtclr'>Expired!</span>;
        } else {
            // Render a countdown
            return <span className='whtclr'>{minutes?.toString()?.padStart(2 , "0")} : {seconds?.toString()?.padStart(2 , "0")} </span>;
        }
    };
    
    return (
        <>
            <div>
                <Modal show={formsmodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                    <Modal.Body className='py-5 px-4'>
                        <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>

                        {registerstep != 2 &&
                         <>
                        <ul className='d-flex  ps-0 formlist mb-4'>
                            <li className={formactive === "register" ? "active" : ""} onClick={() => {
                                setFormactive("register");
                                setEmail("");
                                setPassword("");
                                setPassword1("")
                                }}>Register</li>
                                <li className={formactive === "login" ? "active" : ""} onClick={() => {
                                    setFormactive("login");

                                    setEmail("");
                                    setPassword("");
                                    setPassword1("")
                                }}>Log In</li>
                        </ul>
                        {formactive === "register" &&
                            <>
                                {registerstep === 1 &&
                                    <>
                                        <div className='mb-4'>
                                            <h6 className='whtclr poppins mb-3'>Email Address</h6>
                                            <input type='mail' 
                                                name = "email"
                                                onChange={(e) => {
                                                    setEmail(e?.target?.value)
                                                    let data = {email : e?.target?.value}
                                                    let validate = ValidateRegister(data , "email")
                                                    if(validate){
                                                        setError(validate);
                                                    }
                                                }}
                                            className='form-control g_input supportinput poppins mb-1' placeholder='email address' />
                                            <span className='text-danger mt-1'>{error?.email}</span>
                                        </div>

                                        <div className='mb-4'>
                                            <h6 className='whtclr poppins mb-3'>User Name</h6>
                                            <input type='text' 
                                                name = "name"
                                                onChange={(e) => {
                                                    setName(e?.target?.value)
                                                    let data = {name : e?.target?.value}
                                                    let validate = ValidateRegister(data , "name")
                                                    if(validate){
                                                        setError(validate);
                                                    }
                                                }}
                                            className='form-control g_input supportinput poppins mb-1' placeholder='Username' />
                                            <span className='text-danger mt-1'>{error?.name}</span>
                                        </div>
                                        <div className='mb-4'>
                                            <h6 className='whtclr poppins mb-3'>Password</h6>
                                            <div className='pos'>
                                                <input type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value)
                                                        let data = {password : e.target.value}
                                                        let validate = ValidateRegister(data , "password")
                                                        if(validate){
                                                            setError(validate);
                                                        }
                                                        }} className='form-control g_input supportinput poppins' placeholder='password' />
                                                <span className='eyelist' onClick={togglePasswordVisibility}>{showPassword ? <IoIosEyeOff className='eyes' /> : <IoIosEye className='eyes' />}</span>
                                                <span className='text-danger mt-1'>{error?.password}</span>
                                            </div>
                                        </div>

                                        <div className='mb-4'>
                                            <h6 className='whtclr poppins mb-3'>Confirm Password</h6>
                                            <div className='pos'>
                                                <input type={showPassword1 ? 'text' : 'password'}
                                                    value={password1}
                                                    onChange={(e) => {
                                                        setPassword1(e.target.value);
                                                        let data = {confirmpassword : e.target.value}
                                                        let validate = ValidateRegister(data , "confirmpassword")
                                                        console.log("Validateion" , validate);
                                                        if(validate){
                                                            setError(validate);
                                                        }
                                                        }} className='form-control g_input supportinput poppins' placeholder='password' />
                                                <span className='eyelist' onClick={togglePasswordVisibility1}>{showPassword1 ? <IoIosEyeOff className='eyes' /> : <IoIosEye className='eyes' />}</span>
                                                <span className='text-danger mt-1'>{error?.confirmpassword}</span>
                                            </div>
                                        </div>
                                        
                                        <span className='text-white mb-3'>Note: <br></br>Password must contain at least 8 characters long , one uppercase letter , one lowercase letter , one number , one special character (e.g., !, @, #, $, %, ^, &, *).</span>
                                        
                                        <div className='text-center'> 
                                            <button className='header__connectBtn rounded-3 poppins px-5 fw-600 mt-3' onClick={handleRegister}>Submit</button>
                                        </div>
                                    </>}



                            </>
                        }
                        {formactive === "login" &&
                            <>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Email Address</h6>
                                    <input type='mail' className='form-control g_input supportinput poppins' placeholder='email address' 
                                    onChange={(e) => {
                                        setEmail(e?.target?.value)
                                        let data = {email : e?.target?.value}
                                        let validate = ValidateLogin(data , "email")
                                        if(validate){
                                            setError(validate);
                                        }
                                    }}
                                    />
                                    <span className='text-danger mt-1'>{error?.email}</span>
                                </div>
                                <div className='mb-4'>
                                    <h6 className='whtclr poppins mb-3'>Password</h6>
                                    <div className='pos'>
                                        <input type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => {setPassword(e.target.value)
                                                let data = {password : e?.target?.value}
                                                let validate = ValidateLogin(data , "password")
                                                if(validate){
                                                    setError(validate);
                                                }
                                            }} className='form-control g_input supportinput poppins' placeholder='password' />
                                        <span className='eyelist' onClick={togglePasswordVisibility}>{showPassword ? <IoIosEyeOff className='eyes' /> : <IoIosEye className='eyes' />}</span>
                                        <span className='text-danger mt-1'>{error?.password}</span>
                                    </div>
                                </div>
                                <div className='text-center'>
                                    <button className='header__connectBtn rounded-3 poppins px-5 fw-600 mt-2' onClick={handleLogin}>Submit</button>
                                </div>
                            </>
                        }
                        </>
                        }

                        {registerstep === 2 &&
                            <>
                                <div className='text-center otpdiv pt-4'>
                                    <h2 className='whtclr poppins mb-3'>OTP</h2>
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    {expired && <Countdown key={forceRender} date={parseFloat(expired)} renderer={renderer} className='mt-3 counts'/>}
                                  
                                </div>
                                <div className='text-center mt-2'><span className='text-danger mt-1'>{error?.otp}</span></div>
                                
                                <div className='text-center pt-4'>
                                <button className='header__connectBtn rounded-3 poppins px-4 fw-600 mt-2 me-2'  disabled={resen}onClick={handleResend}>Resend</button>
                                    <button className='header__connectBtn rounded-3 poppins px-4 fw-600 mt-2' onClick={handleVerify}>Submit</button>
                                </div>
                            </>
                        }

                    </Modal.Body>
                </Modal>
            </div>




        </>

    )
}

export default Formpage