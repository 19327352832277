import { GetChain, GetUserAcccount, UseWeb3 } from "./UseWallet";
import config from "../Config/env";
import { GAMECONTRATC_ABI, GAME_ABI } from "../Config/contract";
import { toast } from 'react-toastify';
import { CHAINS } from "../Config/Network.";
import Web3 from "web3";
import { UpdateContractAddressHooks, UpdateOrderStatusHooks } from "./GameHooks";


export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}

export const UseProxy = async (provider) => {
    try {
        const web3 = await UseWeb3(provider);
        const contract = new web3.eth.Contract(GAME_ABI, config.CONTRACT);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseProxy", err.code, err)
    }
}

export const UseGame = async (address , provider) => {
    try {
        const web3 = await UseWeb3(provider);
        const contract = new web3.eth.Contract(GAMECONTRATC_ABI, address);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseGame", err.code, err)
    }
}

export const GetSaleaddress = async() => {
    try{
        let gamecontract = await UseProxy();
        let games = await gamecontract.methods.GetTotalGames().call();
        return games[games?.length-1];        
    }
    catch(e){
        console.log("Error on get sale address" , e);
    }
}

export const CreateGame =  async(price , productid , address , provider) => {
    try{
        // debugger
        let gamecontract = await UseProxy(provider);
        let fee = await gamecontract.methods.GetDeploymentFee().call();
        let feepercent = parseFloat(fee)/10**18
        let adminprofit = (parseFloat(price)/100) * feepercent;                    //(parseInt(fee)*parseFloat(price)*10**18)/100
        let amount = parseFloat(price) + parseFloat(adminprofit)                    //(parseFloat(price)*10**18) + adminprofit*10**18;
        amount = amount.toFixed(7)
        console.log("Amount" , price , productid , address , toFixedNumber(parseFloat(price)*10**18)  , amount);

        let creategame =  gamecontract?.methods.CreateGame(toFixedNumber(parseFloat(price)*10**18) , productid , address , true).send({from : GetUserAcccount() , value : toFixedNumber(amount*10**18)});
         await toast.promise(creategame , {
            pending: 'Creating New Order ...',
            success: 'Order Created Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        let gamaddress = await GetSaleaddress();
        console.log("GAme created" , gamaddress);
        let result = await UpdateContractAddressHooks({id : productid , address : gamaddress?._gameAddress});
        return result
    }
    catch(e){
        console.log("Error on create game" , e);
    }
}


// export const CreateGame =  async(price , productid , address , provider) => {
//     try{
//         let gamecontract = await UseProxy(provider);
//         let fee = await gamecontract.methods.GetDeploymentFee().call();
//         let adminprofit = (parseInt(fee)*parseFloat(price)*10**18)/100
//         console.log("Amount" , price , productid , address , toFixedNumber(parseFloat(price)*10**18));
//         let amount = (parseFloat(price)*10**18) + adminprofit;
//         let creategame =  gamecontract?.methods.CreateGame(toFixedNumber(parseFloat(price)*10**18) , productid , address , true).send({from : GetUserAcccount() , value : toFixedNumber(amount)});
//          await toast.promise(creategame , {
//             pending: 'Creating New Order ...',
//             success: 'Order Created Successfully',
//             error: (err) => `Error : ${err.toString()}`,
//         }
//         );
//         let gamaddress = await GetSaleaddress();
//         console.log("GAme created" , gamaddress);
//         let result = await UpdateContractAddressHooks({id : productid , address : gamaddress?._gameAddress});
//         return result
//     }
//     catch(e){
//         console.log("Error on create game" , e);
//     }
// }



export const UserRaiseRefund = async(gameaddress , provider) => {
    try{
        let gamecontract = await UseGame(gameaddress , provider);
        let creategame = gamecontract?.methods.RefundFunds().send({from : GetUserAcccount()});
        await toast.promise(creategame , {
            pending: 'Raise Refund Amount ...',
            success: 'Amount Refunded Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        return true
    }
    catch(e){
        console.log("Error on user raise refund" , e);
    }
}

export const SetAmountreceived = async(gameaddress , provider) => {
    try{
        let gamecontract = await UseGame(gameaddress , provider);
        let creategame = gamecontract?.methods.SetAmountReceived().send({from : GetUserAcccount()});
        await toast.promise(creategame , {
            pending: 'Amount Received ...',
            success: 'Set Amount Received Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        return true
    }
    catch(e){
        console.log("Error on user raise refund" , e);
    }
}

export const SetTransferredAsset = async(gameaddress , provider) => {
    try{
        let gamecontract = await UseGame(gameaddress , provider);
        let creategame = gamecontract?.methods.SetTransferredAsset().send({from : GetUserAcccount()});
        await toast.promise(creategame , {
            pending: 'Asset Transfered ...',
            success: 'Set Asset Transferred Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        return true
    }
    catch(e){
        console.log("Error on user raise refund" , e);
    }
}

export const SetReceivedAsset = async(gameaddress , orderid , provider) => {
    try{
        let gamecontract = await UseGame(gameaddress , provider);
        let creategame = gamecontract?.methods.SetAssetReceived().send({from : GetUserAcccount()});
        await toast.promise(creategame , {
            pending: 'Asset Received ...',
            success: 'Set Asset Received Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        let data = await UpdateOrderStatusHooks({id : orderid});
        return data
    }
    catch(e){
        console.log("Error on user raise refund" , e);
    }
}

export const WithdrawFunds = async(gameaddress , provider) => {
    try{
        // let proxy = await UseProxy(provider);
        // let fee = await proxy.methods.GetPlatformFee().call();
        let gamecontract = await UseGame(gameaddress);
        let withdraw = gamecontract.methods.WithdrawFunds().send({from : GetUserAcccount()});
       
        await toast.promise(withdraw , {
            pending: 'Amount Withdrawing ...',
            success: 'Withdraw Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        return true
    }
    catch(e){
        console.log("Error on user raise refund" , e);
        return false
    }
}

export const Expandtime = async(gameaddress , provider) => {
    try{
        let gamecontract = await UseGame(gameaddress , provider);
        let creategame = gamecontract?.methods.SetExtendTime().send({from : GetUserAcccount()});
        await toast.promise(creategame , {
            pending: 'Expand Timing ...',
            success: 'Time Extend Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
        return true
    }
    catch(e){
        console.log("Error on user raise refund" , e);
        return false
    }
}

export const GetDeployfee = async() => {
    try{
        const httpProvider = new Web3.providers.HttpProvider(config.RPC_URL)
        let gamecontract = await UseProxy(httpProvider);
        let fee = await gamecontract.methods.GetDeploymentFee().call();
        let feepercent = parseFloat(fee)/10**18;
        return feepercent;
    }
    catch(e){
        console.log("Error on deployfee" , e);
    }
}

export const GetGameInfo = async(gameaddress) => {
    try{
        const httpProvider = new Web3.providers.HttpProvider(config.RPC_URL)
        let web3 = new Web3(httpProvider);
        let gamecontract = new web3.eth.Contract(GAMECONTRATC_ABI, gameaddress);
        let gameinfo = await gamecontract?.methods?.GetGameInfo().call();
        return gameinfo;
    }
    catch(e){
        console.log("Error on get game info" , e);
    }
}