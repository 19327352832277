import { React, useState } from 'react';
import { Table } from "react-bootstrap";
import { Link } from 'react-router-dom';

const ChatHistoryTable = ({ chatHistory, currentPage, itemsPerPage, totalItems, onPageChange }) => {

    // const[chathistablelist,setChathistablelist] = useState([
    //     {sellername: "Lorem", buyername: "lorem", purchasestatus: "Completed"},
    //     {sellername: "Lorem", buyername: "lorem", purchasestatus: "Completed"},
    //     {sellername: "Lorem", buyername: "lorem", purchasestatus: "Pending"},
    //     {sellername: "Lorem", buyername: "lorem", purchasestatus: "Completed"},
    //     {sellername: "Lorem", buyername: "lorem", purchasestatus: "yet to start"},
    // ]);
console.log("chatHistorychatHistory" , chatHistory);
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // const formatTimestamp = (timestamp) => {
    //     const date = new Date(timestamp);
    //     return date.toLocaleString();
    // };

    return (

        <div className='allordertabel pb-4'>

            {chatHistory.length > 0 ? (
                <>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>Seller Name</th>
                                <th>Game Category</th>
                                <th>Game Name</th>
                                <th>Chat Status</th>
                                <th>Sale Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chatHistory.map((e) =>
                                <tr>
                                    <td>{e?.createrID?.name}</td>
                                    <td>{e?.gameId?.category}</td>
                                    <td>{e?.gameId?.name}</td>
                                    <td>{e?.ownerAccepted}</td>
                                    <td>{e?.status}</td>
                                    {e?.ownerAccepted == "Accepted" ? <td>
                                        <Link to={`/chating/${e?._id}`}  class="header__connectBtn rounded-3 px-3">View</Link>
                                    </td> : <td></td>}
                                </tr>
                            )}

                        </tbody>
                    </Table>

                    {totalPages > 1 && (
                        <nav className='d-flex justify-content-end'>
                            <ul className="pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(0)}
                                        disabled={currentPage === 0}
                                    >
                                        First
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                    >
                                        Prev
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="page-link">
                                        {currentPage + 1}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(totalPages - 1)}
                                        disabled={currentPage === totalPages - 1}
                                    >
                                        Last
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    )}

                </>
            ) : (

                <>
                    <Table hover responsive>
                        <tbody>
                            <tr>
                                <td colSpan="6" className="text-center">No Record Found</td>
                            </tr>
                        </tbody>
                    </Table>
                </>

            )


            }

        </div>
    )
}

export default ChatHistoryTable;