import React, { useEffect, useState } from 'react'
import { FaXTwitter, FaInstagram, FaFacebookF } from "react-icons/fa6";
import { BiLogoTelegram } from "react-icons/bi";
import { Images } from './Images';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { getSocialMediaList } from '../Hooks/SocialMedia';

import config from '../Config/env'

const Footer = () => {


  useEffect(() => {
    getSocialMedia();
  }, []);

  const getSocialMedia = async () => {
    try {
      const resp = await getSocialMediaList(); 

      console.log("status", resp);
      
      // Assuming this function is defined elsewhere
      if (resp?.status) {
        setMedia(resp.data);
      } else {
        console.error('Failed to fetch social media data.');
      }
    } catch (error) {
      console.error('An error occurred while fetching social media data:', error);
    }
  };

  const [media, setMedia] = useState([]);

  console.log("MEDIA ", media);

  const renderIcon = (name) => {
    switch (name) {
      case 'Twitter':
        return <FaXTwitter />;
      case 'Instagram':
        return <FaInstagram />;
      case 'Facebook':
        return <FaFacebookF />;
      case 'Telegram':
        return <BiLogoTelegram />;
      default:
        return null;
    }
  };



  return (

    <>
      <Container className='mt-5 footer py-5'>
        <Row>
          <Col lg={4} md={12} xs={12} className='mb-5 mb-lg-0 d-flex justify-content-center justify-content-md-start'>
            <div className='footer__left'>
              <h5 className='m-0 vendin text-uppercase footer__communityTitle'>Join Our Community</h5>
              <div className='footer__socialLinksHolder mt-3 mb-4'>
                {/* <ul className='d-flex justify-content-start align-items-center gap-2 m-0'>
          <a href='https://twitter.com' target='_blank' className='header__links'><li className='pointer'><FaXTwitter/></li></a>
          <a href='https://www.instagram.com/' target='_blank' className='header__links'><li className='pointer'><FaInstagram/></li></a>
          <a href='https://www.facebook.com/' target='_blank' className='header__links'><li className='pointer'><FaFacebookF/></li></a>
          <a href='https://telegram.org/' target='_blank' className='header__links'><li className='pointer'><BiLogoTelegram /></li></a>
        </ul> */}

                <ul className='d-flex justify-content-start align-items-center gap-2 m-0'>
                  {media.map((item) => (
                    <a href={item.url} target='_blank' rel='noopener noreferrer' className='header__links' key={item._id}>
                      <div className=''>
                        <img className='socialimg' src={`${config?.API_URL}${item.imagePath}${item.image}`} alt="Social Media" />
                      </div>
                      {/* <li className='pointer'>
                        {renderIcon(item.name)}
                      </li> */}
                    </a>
                  ))}
                </ul>
              </div>
              <img src={Images.logo} className='img-fluid footer__logo mt-3' alt='' />

              <div className='footer__socialLinksHolder mt-4'>
                <ul className='d-flex justify-content-start align-items-center gap-3 m-0'>
                  {/* <NavLink className='header__links'><li className='poppins pointer'>Blog</li></NavLink> */}
                  <NavLink className='header__links' to="/about"><li className='poppins pointer'>About Us</li></NavLink>
                  <NavLink className='header__links' to="/terms"><li className='poppins pointer'>Terms of Use</li></NavLink>
                  <NavLink className='header__links' to="/privacy"><li className='poppins pointer'>Privacy Policy</li></NavLink>
                </ul>
              </div>
              <p className='m-0 poppins footer__copyrights mt-2'>Copyrights © 2024 inc. All rights Reserved</p>
            </div>
          </Col>

          <Col lg={8} md={12} xs={12}>
            <div className='row'>
              <div className='col-12 col-sm-12 col-md-3 mb-4 mb-lg-0'>
                <h5 className='m-0 vendin text-uppercase footer__rightTitle'>Sell</h5>
                <div className='footer__socialLinksHolder mt-4'>
                  <ul className='m-0'>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Become a Seller</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>How to Sell</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Selling Questions</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Sell Game Items</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Sell Video Games</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Sell Gift Cards</li></NavLink>
                  </ul>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-3 mb-4 mb-lg-0'>
                <h5 className='m-0 vendin text-uppercase footer__rightTitle'>Buy</h5>
                <div className='footer__socialLinksHolder mt-4'>
                  <ul className='m-0'>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Buying Questions</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>How to Buy</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Buy Game Items</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Buy Video Games</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Buy Gift Cards</li></NavLink>
                    <NavLink className='header__links'><li className='poppins pointer mt-3'>Buy Movies</li></NavLink>
                  </ul>
                </div>
              </div>

              <div className='col-12 col-sm-12 col-md-6'>
                <div className="row">
                  <div className='col-12 col-sm-12 col-md-7 mb-4 mb-lg-1 mb-xxl-0'>
                    <h5 className='m-0 vendin text-uppercase footer__rightTitle'>FAQ</h5>
                    <div className='footer__socialLinksHolder mt-4'>
                      <ul className='m-0'>
                        <NavLink className='header__links'><li className='poppins pointer mt-3'>How Does PlayForge
                          Work?</li></NavLink>
                        <NavLink className='header__links'><li className='poppins pointer mt-3'>What is the PlayForge
                          Guarantee?</li></NavLink>
                        <NavLink className='header__links'><li className='poppins pointer mt-3'>How does PlayForge
                          protect sellers?</li></NavLink>
                      </ul>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-5 mb-4 mb-lg-1 mb-xxl-0'>
                    <h5 className='m-0 vendin text-uppercase footer__rightTitle'>Resources</h5>
                    <div className='footer__socialLinksHolder mt-4'>
                      <ul className='m-0'>
                        <NavLink className='header__links' to="/support"><li className='poppins pointer mt-3'>Help Center</li></NavLink>
                        <NavLink className='header__links'><li className='poppins pointer mt-3'>Forum</li></NavLink>
                        <NavLink className='header__links'><li className='poppins pointer mt-3'>Return Policy</li></NavLink>
                        <NavLink className='header__links' to="/contact"><li className='poppins pointer mt-3'>Contact Us</li></NavLink>
                      </ul>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <h5 className='mb-0 vendin text-uppercase footer__rightTitle mt15'>Pay With</h5>
                    <ul className='footer__socialLinksHolder ps-0 mb-0 mt-2 d-flex align-items-center flex-wrap gap-4 paylinks'>
                      <NavLink className='header__links'>
                        <li className='poppins pointer d-flex align-items-center gap-2'>
                          <img src={Images.matic} alt='Matic' width="30" height="30" />
                          <span>Matic</span>
                        </li>
                      </NavLink>
                      <NavLink className='header__links'>
                        <li className='poppins pointer d-flex align-items-center gap-2'>
                          <img src={Images.visa} alt='visa' width="40" height="40" />
                        </li>
                      </NavLink>
                      <NavLink className='header__links'>
                        <li className='poppins pointer d-flex align-items-center gap-2'>
                          <img src={Images.mastercard} alt='Mastercard' width="32" height="32" />
                          <span>Mastercard</span>
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>

  )
}

export default Footer