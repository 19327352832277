
export const ValidateFile = (data) => {
    try{
        var fileName = data.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
            return ""
        }
        else{
            return "Invalid file format"
        }
    }
    catch(e){
        console.log("Error on validate filer" , e);
    }
}

export const ValidateEmail = (data) => {
    var emailRegx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(data?.email){
        if (!emailRegx.test(data?.email)){
            return "Invalid Email"
        }
        else{
            return "";
        }
    }
    else{
        return "Field is required"
    }
}


export const ValidatePassword = (pw) => {
    try{
        var error = ""
        if(!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{8,}$/.test(pw))){
            error= "Passcode must satisfy below condition";
        }
        return error;
    }
    catch(e){
        console.log("Error on validate password" , e);
    }
}

export const ValidateRegister = (data, key, ref) => {
    try {console.log("dataaaaaa" , data);
        let error = {};
        let isValid = true;

        if (key == "email" || ref) {
            let val = ValidateEmail(data)
            if (!data?.email) {
                error.email = "Field is required"
                isValid = false;
            }
            else if (val) {
                error.email = val;
                isValid = false;
            }
            else {
                error.email = "";
            }
        }

        if(key == "name" || ref){
            if (!data?.name) {
                isValid = false;
                error.name = "Field is required"
            }
            else{
                error.name = ""
            }
        }

        if (key == "password" || ref) {
            let val = ValidatePassword(data?.password)
            if (!data?.password) {
                isValid = false;
                error.password = "Field is required"
            }
            else if (val) {
                isValid = false;
                error.password = val
            }
            else {
                error.password = ""
            }
        }

        if (key == "confirmpassword" || ref) {
            let val = ValidatePassword(data?.confirmpassword)
            if (!data?.confirmpassword) {
                isValid = false;
                error.confirmpassword = "Field is required"
            }else if(data?.password && data?.confirmpassword && data?.password != data?.confirmpassword){
                isValid = false;
                error.confirmpassword = "Password and confirm password must be same"
            }
            else if (val) {
                isValid = false;
                error.confirmpassword = val
            }
            else {
                error.confirmpassword = ""
            }
        }

        if (isValid) {
            return {}
        }
        else {
            return error;
        }
    }
    catch (e) {
        console.log("Error on validate register", e);
    }
}


export const ValidateLogin = (data , key , ref) => {
    try{
        let error = {};
        let isValid = true;
        if (key == "email" || ref) {
            let val = ValidateEmail(data)
            if (!data?.email) {
                error.email = "Field is required"
                isValid = false;
            }
            else if (val) {
                error.email = val;
                isValid = false;
            }
            else {
                error.email = "";
            }
        }

        if (key == "password" || ref) {
            let val = ValidatePassword(data?.password)
            if (!data?.password) {
                isValid = false;
                error.password = "Field is required"
            }
            else if (val) {
                isValid = false;
                error.password = val
            }
            else {
                error.password = ""
            }
        }

        if (isValid) {
            return {}
        }
        else {
            return error;
        }
    }
    catch(e){
        console.log("Error on validate login" , e);
    }
}

export const ValidateContactus = (data , key , ref) => {
    try{
        let isValid = true;
        let errors = {};
        
        if(key == "name" || ref){
            if(!data?.name){
                isValid = false
                errors.name = "Field is required"
            }
        }

        if(key == "country" || ref){
            if(!data?.country){
                isValid = false
                errors.country = "Field is required"
            }
        }

        if(key == "description" || ref){
            if(!data?.description){
                isValid = false
                errors.description = "Field is required"
            }
        }

        if(key == "email" || ref){
            let emailvalidate = ValidateEmail(data);
            console.log("Validate va"  , emailvalidate ,data?.email);
            if(emailvalidate){
                isValid = false;
                errors.email = emailvalidate
            }
        }

        if(!isValid){
            return errors;
        }
        else{
            return {}
        }
    }
    catch(e){
        console.log("Error on validate contact us", e);
    }
}

export const ValidateSupportcommon = (data , key , ref) => {
    try{
        let isValid = true;
        let errors = {};
        if(key == "title" || ref){
            if(!data?.title){
                isValid = false
                errors.title = "Field is required"
            }
        }

        if(key == "description" || ref){
            if(!data?.description){
                isValid = false
                errors.description = "Field is required"
            }
        }

        if(key == "image" || ref){
            if(data?.image){
                let validate = ValidateFile(data?.image);
                if(validate){
                    isValid = false
                    errors.image = "Field is required"
                }
            }
            else{
                isValid = false
                errors.image = "Field is required"
            }
        }

        if(!isValid){
            return errors;
        }
        else{
            return {}
        }
    }
    catch(e){
        console.log("Erroron validate support common");
    }
}


export const ValidateCreateGameDetail = (data , key , ref) => {
    try{}
    catch(e){
        console.log("Error on validate create Game Detail" , e);
    }
}