import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import OtpInput from 'react-otp-input';
import { GetUserdataHooks, UpdateUserdataHooks } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';
import { GetUserCode, GetUserEmail } from '../Hooks/UseWallet';

function Changenamemodals(props) {

    const [changenamemodal, setChangenamemodal] = useState(true);
    const [name , setName] = useState("");
    const [error , setError] = useState("");

    useEffect(() => {
        setName(props?.name)
    } , [props])
    const Getuserdata = async() => {
        if(GetUserCode()){
          let result = await GetUserdataHooks({id : GetUserCode()});
        }
      }
    const HandleSave = async() => {
        try{
            if(name){
                let payload = {name : name , email : GetUserEmail()};
                let result = await UpdateUserdataHooks(payload);
                if(GetUserEmail()){
                    let res = await GetUserdataHooks({id : GetUserCode()});
                  }
                if(result?.type == "Success"){
                    toast.success("User Name Changed Successfully!")
                    window.location.reload();
                    props.onDismiss()
                }
                else if(result?.type == "Failed"){
                    toast.error(result?.message);
                }
            }
            else{
                setError("Field is required.")
            }
        }
        catch(e){
            console.log("Error on handle save" , e);
        }
    }


    return (

        <div>

            <Modal show={changenamemodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>
                    <h3 className='whtclr poppins mt-4 mb-4'>Change Your Name</h3>
                    <input type='mail' className='form-control g_input mb-3' placeholder='Name' value={ name}
                     onChange={(e) => {
                        setName(e?.target?.value)
                        if(e?.target?.value?.length > 0){
                            setError("")
                        }
                     }}
                    />  
                    <p className='text-danger'>{error}</p>                
                    <div className='d-flex justify-content-between'>
                    <button className='header__connectBtn rounded-2 vendin' onClick={() => props.onDismiss()}>Cancel</button>
                    <button className='header__connectBtn rounded-2 vendin' onClick={HandleSave}>Save</button>
                    </div>
                  
                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Changenamemodals