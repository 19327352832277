
// Local config

// export const API_URL = "http://localhost:2056/";

var key = "demo"

var keyvalue = {};
if (key == "production") {
    keyvalue.API_URL = "http://localhost:2056/";
    keyvalue.getGeoInfo = "https://ipapi.co/json/";
    keyvalue.IMAGE_URL = "http://localhost:2056";
    keyvalue.ENCODEKEY = "encodenft@x"
    keyvalue.CONTRACT = "0x8E1Ec1402df918d595C2897A10C482eAB725E3F2"
    keyvalue.RPC_URL =  "https://bsc-testnet-rpc.publicnode.com"
}
else if (key == "demo") {
    keyvalue.API_URL = "https://backend-playforce.maticz.in/";
    keyvalue.getGeoInfo = "https://ipapi.co/json/";
    keyvalue.IMAGE_URL = "https://backend-playforce.maticz.in";
    keyvalue.ENCODEKEY = "encodenft@x";
    keyvalue.CONTRACT = "0x98eF334f43DB9170D3A9bC73Ab1fEA2fAe25668D" //"0xB8FE23cBDb8ec706C3FE5A67F56C21EDBf3725EA" //"0x84830aA4E0a0B350A67C3971Cd46D793Fc19a983"
    keyvalue.RPC_URL =  "https://bsc-testnet-rpc.publicnode.com"
}
else {
    keyvalue.API_URL = "http://localhost:2056/";
    keyvalue.getGeoInfo = "https://ipapi.co/json/";
    keyvalue.IMAGE_URL = "http://localhost:2056";
    keyvalue.ENCODEKEY = "encodenft@x"
    keyvalue.CONTRACT =  "0x98eF334f43DB9170D3A9bC73Ab1fEA2fAe25668D" //"0x150f57D927C054Dd97a646760D1826801130e89a" //"0x7FE88c39D918262fB6bB6CeCC7EE43D92d4e44D4"//"0x8E1Ec1402df918d595C2897A10C482eAB725E3F2" //"0x4B034ab37ED35a80bB95D8Dcc69FA9815B95E2DD"//"0xc7977218fE7Aa127319856C730F5A942974f7360"//"0xaCBe1B560AE428Fe6fCBC6d37bD06aFF09Fc2cf6" //"0xD9F02669aF9AFB3a4EcDbfE2b5EFeE321083e931" //"0xC3000DD688B6076FE13a0DB2EF835Fc5ba9295c3"  //"0x714080cC4eeE4dEB88AE736D3743a712970E30Fe"
    keyvalue.RPC_URL =  "https://bsc-testnet-rpc.publicnode.com"
}

export default keyvalue;