    import React, { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function Sellingtab() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);

    return (

        <>

            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages pb-5'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center pt-5 pt-sm-0 mb-sm-5'>Start Selling on Gift2Game</h2>

                        <div className="row">
                            <div className="col-xl-11 col-xxl-9 mx-auto">
                                <div className="row">
                                    <div className="col-md-6 mt-5">
                                        <Link to="/gamelist">
                                            <div className="sellcard px-3 px-sm-5 px-md-3 px-lg-5 py-5 text-center">
                                                <h4 className="whtclr text-uppercase poppins fw-600">I have something to sell</h4>
                                                <p className="whtclr poppins mb-0">You can sell video games, gift card, in-game items & skins, movies, and more.</p>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-md-6 mt-5">
                                        <Link to="/gamelist">
                                            <div className="sellcard px-3 px-sm-5 px-md-3 px-lg-5 py-5 text-center">
                                                <h4 className="whtclr text-uppercase poppins fw-600">I am a skilled competitive
                                                    player (Play with Pros)</h4>
                                                <p className="whtclr poppins mb-0">You can earn by offering training through gaming
                                                    sessions in a game you’re expert at..</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <Footer />
            </Container>

        </>
    )
}

export default Sellingtab