import {initializeApp} from 'firebase/app';
import { getMessaging , getToken} from 'firebase/messaging';
// import { Firestore } from '@google-cloud/firestore';
import {getFirestore} from "@firebase/firestore"
import 'firebase/messaging';

const firebaseConfig = {
    // apiKey: "AIzaSyDpeJ9dkPYP2VYfirBEGMK3nd-OxoWd-EU",
    // authDomain: "maticz-playforge.firebaseapp.com",
    // projectId: "maticz-playforge",
    // storageBucket: "maticz-playforge.appspot.com",
    // messagingSenderId: "635068605878",
    // appId: "1:635068605878:web:07b71550fe5bbd40f04d17",
    // measurementId: "G-C3TECPFC4F"
    apiKey: "AIzaSyBXWOmim737n4Mz68_Kbw2gqSXYzrT0H_I",
    authDomain: "newplayforge.firebaseapp.com",
    projectId: "newplayforge",
    storageBucket: "newplayforge.appspot.com",
    messagingSenderId: "138060382790",
    appId: "1:138060382790:web:f53fe0544334ff121c0047"
};

const app =  initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const firestore = getFirestore(app);

export { messaging , getToken};


