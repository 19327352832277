import { React, useEffect, useState, useMemo } from "react";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Container } from "react-bootstrap";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { ValidateContactus } from "../Validation/UserValidation";
import { colors } from "@mui/material";
import { AddContactusHooks } from "../Hooks/contactus-supportHooks";
import { toast } from "react-toastify";

const Contact = () => {

  const options = useMemo(() => countryList().getData(), []);


  const customStyles = {
    control: (base, state, isSelected, isFocused) => ({
      ...base,
      background: "transparent",
      color: "#fff",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#7B3FB7" : "#7B3FB7",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? "#7B3FB7" : "#7B3FB7"
      }
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused ? "#6e4794" : "#000",
      // backgroundColor: isSelected ? "#6e4794" : "#000",
      zIndex: 1,
      color: "#fff",
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      background: 'transparent'
    })
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [country, setCountry] = useState("");
  const [errors, setError] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const HandleSubmit = async () => {
    try {
      let payload = {
        name: name,
        email: email,
        country: country,
        description: description
      }
      let validate = ValidateContactus(payload, "", true);
      if (Object.keys(validate)?.length > 0) {
        setError(validate);
      }
      else {
        let result = await AddContactusHooks(payload);
        if (result?.type == "Success") {
          toast.success(result?.message);
          setName("");
          setEmail("");
          setCountry(null);
          setDescription("");
        }
        else if (result?.type == "Failed") {
          toast.error(result?.message);
        }
      }
    }
    catch (e) {
      console.log("Error on handle submit", e);
    }
  }


  return (
    <>
      <Container fluid className="p-0 common_bg">
        <Header />
        <div className="innerpages">
          <div className="container">
            <h2 className="whtclr vendin text-center pt-5 pt-sm-0 mb-3 mb-sm-5">Contact Us</h2>

            <div className="row justify-content-center pt-3">
              <div className="col-12 col-sm-12 col-lg-7 col-xxl-7">
                <div className="mb-4">
                  <h6 className="whtclr poppins mb-3">Name</h6>
                  <input
                    type="text"
                    value={name}
                    className="form-control g_input supportinput poppins"
                    placeholder="name"
                    onChange={(e) => {
                      setName(e?.target?.value);
                      let validate = ValidateContactus({ name: e?.target?.value }, "name")
                      let err = { ...errors, ...validate }
                      console.log("err", err);
                      setError(validate)
                    }}
                  />
                  <span className="text-danger">{errors?.name}</span>
                </div>

                <div className="mb-4">
                  <h6 className="whtclr poppins mb-3">Email</h6>
                  <input
                    type="text"
                    value={email}
                    className="form-control g_input supportinput poppins"
                    placeholder="email"
                    onChange={(e) => {
                      setEmail(e?.target?.value);
                      let validate = ValidateContactus({ email: e?.target?.value }, "email")
                      let err = { ...errors, ...validate }
                      setError(validate)

                    }}
                  />
                  <span className="text-danger">{errors?.email}</span>
                </div>
                <div className="mb-4">
                  <h6 className="whtclr poppins mb-3">Country</h6>
                  <div className="pos">
                    {/* <input
                      type="text"
                      className="form-control g_input supportinput poppins"
                      placeholder="country"
                    /> */}
                    <Select
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          text: '#fff',
                          primary25: '#fff',
                          primary: '#Fff',
                        },
                      })}
                      styles={customStyles}
                      options={options}
                      value={options?.find(e => e?.label == country) || null } 
                      onChange={(e) => {
                        setCountry(e?.label);
                        let validate = ValidateContactus({ country: e?.label }, "country")
                        let err = { ...errors, ...validate }
                        setError(validate)
                      }} />
                    <span className="text-danger">{errors?.country}</span>
                  </div>
                </div>
                <div className="mb-4">
                  <h6 className="whtclr poppins mb-3">Description</h6>
                  <textarea
                    value={description}
                    type="text"
                    className="form-control g_input supportinput poppins"
                    placeholder="Description"
                    rows="5"
                    onChange={(e) => {
                      setDescription(e?.target?.value);
                      let validate = ValidateContactus({ description: e?.target?.value }, "description")
                      let err = { ...errors, ...validate }
                      setError(validate)
                    }}
                  />
                  <span className="text-danger">{errors?.description}</span>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    className="header__connectBtn rounded-3 poppins px-5 fw-600"
                    onClick={HandleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </Container>
    </>
  );
};

export default Contact;
