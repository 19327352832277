import axios from "axios";

import config from "../Config/env";

import store from "../Redux/Store/Store";

import { AppenData, DecryptString, EncryptString, Decryptdata, GenerateSecretKey } from "./Authenticate";
import { AddContactusApi, CreateSupport, GetAdminId, GetChatHistoryList, GetSingleSupportInfo, GetSupportList } from "../Routes/ContactusSupportRoutes";


export const AddContactusHooks = async (data) => {
    try {
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                'Authorization': `Bearer ${atob(localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = { ...data }
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload), secret)
        let result = await axios.post(config?.API_URL + AddContactusApi, datas, { headers: headers?.headers });
        return result?.data;
    }
    catch (e) {
        console.log("Error on RegisterUserEmailHooks", e);
    }
}

export const CreateNewSupport = async (payload) => {

    try {
        let secret = GenerateSecretKey()


        let appendData = AppenData(payload)
        console.log("appendData", appendData)
        var resp = await axios({
            "method": "POST",
            "url": config?.API_URL + CreateSupport,
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${atob(localStorage.getItem("USRDT"))}`,
            },
            "data": appendData[0],
        });

        let response = Decryptdata(resp?.data);
        return response;

        // let appendData = AppenData(payload)

        // const headers = {
        //     headers: {
        //         'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
        //         'Custom-Header': secret
        //     }
        // }
        // var payload = {...data}
        // var datas = {}
        // datas.input = AppenData(data)
        // let result = await axios.post(config?.API_URL+AddContactusApi,datas ,{headers : headers?.headers});
        // return result?.data;

    } catch (e) {
        console.log("Error on Create New Support", e);
    }

}

export const getAllSupportList = async (payload) => {

    try {
        // GetSupportList

        console.log("PAYLOAD  ", payload);

        var resp = await axios ({
            'method': 'GET',
            "url": config?.API_URL + GetSupportList,
            'params': payload,
        })
        
        console.log("resp",resp)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (e) {
        console.log("Error on Get All Support Hooks", e);
        
    }

}

export const getAllChatHistory = async (payload) => {

    try {
        // GetSupportList

        console.log("PAYLOAD  ", payload);

        var resp = await axios ({
            'method': 'GET',
            "url": config?.API_URL + GetChatHistoryList,
            'params': payload,
        })
        
        console.log("resp",resp)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (e) {
        console.log("Error on Get All Support Hooks", e);
        
    }

}

export const getSingleSupportInfo = async (payload) => {

    try {
        // GetSupportList

        console.log("PAYLOAD  ", payload);

        var resp = await axios ({
            'method': 'GET',
            "url": config?.API_URL + GetSingleSupportInfo,
            'params': payload,
        })
        
        console.log("resp",resp)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (e) {
        console.log("Error on Get All Support Hooks", e);
        
    }

}

export const getAdminInfo = async (payload) => {

    try {
        // GetSupportList

        console.log("PAYLOAD  ", payload);

        var resp = await axios ({
            'method': 'GET',
            "url": config?.API_URL + GetAdminId,
            'params': payload,
        })
        
        console.log("resp",resp)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (e) {
        console.log("Error on Get All Support Hooks", e);
        
    }

}