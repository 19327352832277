import { React, useState } from 'react';
import { Table } from "react-bootstrap";
import CancelModal from '../Components/CancelModal';
import ReactStars from "react-rating-stars-component";
import EditCardModal from './EditCardModal';
import { PiSealCheckFill } from "react-icons/pi";
import { TiCancel } from "react-icons/ti";
import { CHAINS } from '../Config/Network.';
import { GetChain } from '../Hooks/UseWallet';
import config from "../Config/env";
import { useNavigate } from 'react-router-dom';

const Tablelist = (props) => {
    
    const tablehead = ["item", "item name", "current price", "ratings", "action"];
    const [canmodal, setCanmodal] = useState(false);
    const [editmodal, setEditmodal] = useState(false);
    const navigate = useNavigate();

  return (
    <>
    { canmodal && <CancelModal onDismiss={() => setCanmodal(false)} /> }
    { editmodal && <EditCardModal onDismiss={() => setEditmodal(false)} /> }

      <Table responsive>
        <thead>
          {tablehead.map((th) => (
            <th>{th}</th>
          ))}
        </thead>
        <tbody>
          {props.cardlist.map((td) => (
            <tr>
              <td onClick={() => navigate(`/carddetail/${td?._id}`)}>
                <div className='verifybox'>
                    <img src={config.API_URL + td?.imagePath + td?.image} className="tabcardimg" alt='image' />
                    { td.approve == "approved" && 
                      <span className='verifyicon'><PiSealCheckFill /></span>
                    }
                    { td.approve == "rejected" && 
                      <span className='verifyicon reject'><TiCancel /></span>
                    }
                </div> 
              </td>
              <td onClick={() => navigate(`/carddetail/${td?._id}`)}>
                <p className="whtclr fw-600 poppins mb-0">{td?.name}</p>
              </td>
              <td>
                <p className="whtclr poppins mb-0">{td?.price } {CHAINS[GetChain()]?.nativeCurrency?.symbol}</p>
              </td>
              <td>
                <ReactStars value={td?.createrId?.review?.reduce((accumulator, currentValue) => accumulator + currentValue?.rating,0,) / td?.createrId?.review?.length} count={5} size={24} edit={false} isHalf={true} activeColor="#FFBB0D" />
              </td>
              <td>             
                    { td.approve == "approved" && 
                        <div className='d-flex justify-content-between align-items-center mt-3'>
                            <button type='button' className='header__connectBtn rounded-3 poppins w-50 me-3' onClick={() => setEditmodal(true)}>Edit</button>  
                            <button type='button' className='header__connectBtn rounded-3 poppins w-50'>Delete</button>
                        </div>
                        
                    }                      
                    { td.approve == "rejected" &&    
                        <div className='mt-3'>
                            <button type='button' className='header__connectBtn rounded-3 poppins w-50' onClick={() => setEditmodal(true)}>Edit</button>
                        </div>                                 
                    }

                      <div className='mt-3'>
                            <button type='button' className='header__connectBtn rounded-3 poppins w-50' onClick={() => navigate(`/carddetail/${td?._id}`)}>View</button>
                      </div>  
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Tablelist;
