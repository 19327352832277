import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import Countdown from 'react-countdown';
import { IoCloseSharp } from "react-icons/io5";
import OtpInput from 'react-otp-input';
import { VerifyRegisterEmailHooks } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';

function Verifymodal(props) {

    const [verifymodal, setVerifymodal] = useState(true)
    const [otp, setOtp] = useState('');
    const [error , setError] = useState({});

    const handleVerify = async() => {
        if(otp?.length >= 4){
            let payload = {
                email : props?.email,
                otp : otp
            };
            let verify = await VerifyRegisterEmailHooks(payload);
            if(verify?.type == "Success"){
                toast.success("Registered Successfully!")
                setOtp("");
                setError({});
                window.location.reload();
                props.onDismiss();
            }
            else if(verify?.type == "Failed"){
                toast.error(verify?.message);
            }
        }
        else{
            let err = {};
            if(otp?.length > 0)
                err.otp = "Invalid OTP"
            else
                err.otp = "Field is required"
    
            setError(err)
        }
       }

    return (

        <div>

            <Modal show={verifymodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>
                    {/* <button className='header__connectBtn rounded-2 vendin w-100'>Send OTP</button> */}
                    <h3 className='whtclr poppins mt-4'>Verify your code</h3>
                    <p className='whtclr poppins'>Enter the OTP you just received on your email address 
                    { props?.email && props?.email?.substring(0 , 3) + Array(props?.email?.length - 14).join("*")+ props?.email?.substring(props?.email?.length-10 , props?.email?.length)}
                    </p>
                    <div className='d-flex justify-content-center pt-2 pb-2'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} className='otpinputs'/>}
                    />
                    </div>
                    <p className='text-danger text-center mb-0'>{error?.otp}</p>
                    <div className='text-center mt-2 mb-3'>{props?.expired && <Countdown date={parseFloat(props?.expired)} className='mt-3 counts'/>}</div>
                    
                    <button className='header__connectBtn rounded-2 vendin w-100' onClick={handleVerify}>Verify</button>
                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Verifymodal