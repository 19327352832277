const BASE_URL = "game";

export const GetgameItemListApi =  BASE_URL + "/get-game-item-list"

export const GetSingleGameItemApi = BASE_URL + "/get-single-gameitem";

export const GetGameDetailListApi = BASE_URL + "/get-game-detail-list";

export const FilterGameDetailListApi = BASE_URL + "/filter-game-detail";

export const GetSingleGameDetailApi = BASE_URL + "/get-single-game-detail"

export const GetAllGameItemListApi = BASE_URL + "/getall-gameitem";

export const OwnerApproveorRejectApi = BASE_URL + "/owner-approve-or-reject";

export const GetSingleOrderDetailApi = BASE_URL + "/get-single-order-detail";

export const UpdateContactaddressApi = BASE_URL + "/update-contract-address";

export const UpdateOrderStatusApi = BASE_URL + "/update-order-status";

export const UpdateOrderwithdrawStatusApi = BASE_URL + "/update-order-withdraw-status";

export const UserAvialableBalanceApi = BASE_URL + "/user-avialable-balance";