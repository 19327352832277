import { React, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { Images } from "../Components/Images";
import ReactQuill from 'react-quill';
import { GetSingleGameDetailHooks } from "../Hooks/GameHooks";
import { toast } from "react-toastify";
import { EditGameItem } from "../Hooks/UploadGameItems";

import { useNavigate } from "react-router-dom";

import config from '../Config/env'
import { GetUserCode } from "../Hooks/UseWallet";
import { GetDeployfee } from "../Hooks/UseContract";

const EditCardModal = (props) => {

  const navigate = useNavigate();

  const [filess, setFiless] = useState();

  const id = props?.data;

  const [image, setImage] = useState();

  const [editmodal, setEditmodal] = useState(true);

  const [initialdata, setInitialdata] = useState({
    category: '',
    name: "",
    gameDescription: "",
    price: "",
    gameDetail: "",
    quantity: null,
    image: '',
    imagePath: ''
  });

  const [img, setImg] = useState();

  const [errors, setErrors] = useState({});
  const [percentage , setPercentage] = useState(0);


  useEffect(() => {
    fetchdata()
  }, [])
  const fetchdata = async () => {

    console.log("_id1", props?.data);

    try {
      let data = await GetSingleGameDetailHooks({ id: props?.data });

      console.log("DAtA1", data);

      if (data?.type == "Success") {
        setInitialdata(data?.record)

        setImg(`${config.API_URL}` + data?.record?.imagePath + data?.record?.image);
      }

      let percent = await GetDeployfee();
        setPercentage(percent);
    }
    catch (e) {
      console.log("Error on fetchdata", e);
    }
  }

  const OnChange = (e) => {
    try {
      let { name, value } = e?.target;

      console.log("ITEME ", name, value);

      let filteredvalue;

      if (name === "quantity" || name === "deliverydays") {
        // Allow only numbers or empty strings
        filteredvalue = value.replace(/[^0-9]/g, '');
      }
      else if (name === "price") {
        // Allow numbers and decimal points
        filteredvalue = value.replace(/[^0-9.]/g, '');
        // Ensure only one decimal point is allowed
        if (filteredvalue.split('.').length > 2) {
          filteredvalue = filteredvalue.slice(0, -1);
        }
      }
      else {
        filteredvalue = value;
      }
      setInitialdata({ ...initialdata, [name]: filteredvalue });
    } catch (e) {
      console.log("Error on on change", e);
    }
  };

  const isValidImageType = (file) => {
    const validTypes = ['image/png', 'image/jpeg'];
    return validTypes.includes(file.type);
  };

  const handleFile = (e) => {

    const file = e.target.files[0];
    e.target.value = null; // Clear the file input value

    if (file) {
      if (isValidImageType(file)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setInitialdata({
            ...initialdata,
            image: "",
            imagePath: ""
          });
          setFiless(file);
          setImg(reader.result); // Set the image data URL in the state
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        setErrors((prev) => ({
          ...prev,
          image: "Upload Only PNG and JPG Image"
        }));;
      }
    }

    // setFiless(URL.createObjectURL(e.target.files[0]));
  }

  const HandleSubmit = async () => {

    let error = {};
    let pricepercent = parseFloat(initialdata.price) + ((parseFloat(initialdata.price)/100)*parseFloat(percentage))
    if (initialdata.name == "")
      error.name = "Name Required";

    if (initialdata.gameDescription == "")
      error.gameDescription = "Description Required"

    if (!initialdata.price)
      error.price = "Price Required"

    if (initialdata.price && initialdata.price <= 0)
      error.price = "Invalid Price"
    if(pricepercent?.toString()?.split(".")[1]?.length > 7){
      error.price = "Price is Very low"
  }


    if (!initialdata.quantity)
      error.quantity = "Quantity Required"

    if (initialdata.quantity && initialdata.quantity <= 0)
      error.quantity = "Invalid Quantity"

    if (!img)
      error.Image = "Image Required"


    setErrors(error);



    try {
      if (Object.keys(error).length == 0) {

        let payload = {
          id: id,
          category: initialdata.category,
          name: initialdata.name,
          gameDescription: initialdata.gameDescription,
          price: initialdata.price,
          new_image: filess,
          image: initialdata.image,
          imagePath: initialdata.imagePath,
          gameDetail: initialdata.gameDetail,
          quantity: initialdata.quantity,
          approveStatus: "Pending",
          chainId: "",
          createrId: GetUserCode(),
        }

        console.log("new_payload", payload);

        // deliveryMethod: selecteddeliverymethod?.name,
        // deliveryType: selectfilter.name,
        // deliveryDays: initialdata.deliverydays,

        var resp = await EditGameItem(payload);

        if (resp?.status) {
          toast.success(resp.msg);
          props.onDismiss();
          navigate('/profile');
          // props.fn();
        }
        else return toast.error(resp.msg)

        console.log("PAYload", payload, error);
      }
    }
    catch (e) {
      console.log("Error on handle submit", e);
    }
  }

  console.log("INIT", initialdata);

  console.log("ERR", errors);


  return (
    <>
      <Modal
        show={editmodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="py-5 px-4">
          <span className="closebtn" onClick={() => props.onDismiss()}>
            <IoCloseSharp />
          </span>

          <h3 className="vendin whtclr text-center">Edit Card</h3>

          <div className="row pt-3">
            <div className="col-xxl-12 mx-auto">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Category Name</h6>
                <input
                  type="text"
                  disabled={true}
                  className="form-control g_input supportinput poppins"
                  placeholder="Game name"
                  value={initialdata.category}
                />
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Game Item</h6>
                <input
                  type="text"
                  className="form-control g_input supportinput poppins"
                  placeholder="Game name"
                  name="name"
                  value={initialdata.name}
                  onChange={(e) => {
                    OnChange(e)
                    setErrors({ ...errors, name: "" })
                  }}
                />
                {errors.name && <p className='text-danger fs-6'>{errors.name}</p>}

              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Game Description</h6>
                <input
                  type="text"
                  className="form-control g_input supportinput poppins"
                  placeholder="Game Description"
                  name="gameDescription"
                  value={initialdata.gameDescription}
                  onChange={(e) => {
                    OnChange(e)
                    setErrors({ ...errors, gameDescription: "" })
                  }}
                />
                {errors.gameDescription && <p className='text-danger fs-6'>{errors.gameDescription}</p>}

              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Price</h6>
                <input
                  type="text"
                  className="form-control g_input supportinput poppins"
                  placeholder="Price"
                  name="price"
                  value={initialdata.price}
                  onChange={(e) => {
                    OnChange(e)
                    setErrors({ ...errors, price: "" })
                  }}
                />
                {errors.price && <p className='text-danger fs-6'>{errors.price}</p>}
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Sell Quantity</h6>
                <input
                  type="text"
                  className="form-control g_input supportinput poppins"
                  placeholder="Quantity"
                  name="quantity"
                  value={initialdata.quantity}
                  onChange={(e) => {
                    OnChange(e)
                    setErrors({ ...errors, quantity: "" })
                  }}
                />
                {errors.quantity && <p className='text-danger fs-6'>{errors.quantity}</p>}
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Game Detail</h6>
                <ReactQuill theme="snow"
                  // modules = {mod}
                  value={initialdata.gameDetail} onChange={(e) => {
                    setInitialdata({ ...initialdata, gameDetail: e })
                  }} />
              </div>

              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Image</h6>
                <div className="pos imgupload">
                  <input className="form-control" type="file" onChange={handleFile} />
                  <button type="button" className="header__connectBtn rounded-3 poppins px-5 fw-600">Upload Image</button>
                </div>
                {errors.image && <p className='text-danger fs-6'>{errors.image}</p>}
                <img src={img ? img : Images.bannerimg} className="img-fluid imgset mt-2" />
              </div>

              <div className="d-flex justify-content-center gap-3 flex-wrap">
                <button
                  type="button"
                  className="header__connectBtn rounded-3 poppins px-5 fw-600"
                  onClick={() => props.onDismiss()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="header__connectBtn rounded-3 poppins px-5 fw-600"
                  onClick={HandleSubmit}
                >
                  Reupload
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCardModal;
