import React, { useState, useEffect, useRef } from 'react';
import { Accordion, Button, Col, Container, Row, Table } from "react-bootstrap";
import { IoSearchSharp } from "react-icons/io5";
import stars from "../Assets/Images/star.svg";
import { GetUserCode } from '../Hooks/UseWallet';
import { GetOrderListHooks } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';

function Orders() {

    // const [ordertablelist, setOrdertablelist] = useState([
    //     { id: "45A45", asset: "Asus TUF Controller", mode: "buy", buyer: "Mars", price: "150 MAT", thash: "fef84984asf4545", created: "25/03/2024 3:00PM", sold: "Sold Date", admin: "5.3034 MAT", rating: "4.5", status: "completed" },
    //     { id: "45A45", asset: "Asus TUF Controller", mode: "sell", buyer: "Mars", price: "150 MAT", thash: "fef84984asf4545", created: "25/03/2024 3:00PM", sold: "Sold Date", admin: "5.3034 MAT", rating: "4.5", status: "completed" },
    //     { id: "45A45", asset: "Asus TUF Controller", mode: "buy", buyer: "Mars", price: "150 MAT", thash: "fef84984asf4545", created: "25/03/2024 3:00PM", sold: "Sold Date", admin: "5.3034 MAT", rating: "4.5", status: "completed" },
    //     { id: "45A45", asset: "Asus TUF Controller", mode: "buy", buyer: "Mars", price: "150 MAT", thash: "fef84984asf4545", created: "25/03/2024 3:00PM", sold: "Sold Date", admin: "5.3034 MAT", rating: "4.5", status: "completed" },
    //     { id: "45A45", asset: "Asus TUF Controller", mode: "sell", buyer: "Mars", price: "150 MAT", thash: "fef84984asf4545", created: "25/03/2024 3:00PM", sold: "Sold Date", admin: "5.3034 MAT", rating: "4.5", status: "completed" },
    // ]);

    var usr_id = GetUserCode();


    const [orderTableList, setOrderTableList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    const [totalPages, setTotalPages] = useState(0);

    // var totalPages = 0;




    const [orderList, setOrderList] = useState([]);

    const skip = useRef(0);
    const limit = useRef(10);
    const [add, setAdd] = useState(10); //as like limit
    const [count, setCount] = useState(0);

    const [listingsearch, setListingsearch] = useState('');

    useEffect(() => {
        getUserOrder();
    }, [itemsPerPage, currentPage]);

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
    }, [totalItems, itemsPerPage]);


    const getUserOrder = async () => {

        try {

            const payload = {
                userid: GetUserCode(),
                skip: currentPage * itemsPerPage,
                limit: itemsPerPage,
            };

            const result = await GetOrderListHooks(payload);

            console.log("RESULT_ORDER", result);

            if (result?.type === "Success") {
                console.log("Data fetched:", result?.data);
                console.log("Total count from API:", result?.count);

                setOrderList(result?.data);
                setCount(result?.count);
                setTotalItems(result?.count);

                // setTotalPages(Math.ceil(totalItems/ itemsPerPage));
            }
            // else {
            //     toast.error("Error fetching game items.");
            // }

        } catch (error) {
            console.error("Error fetching game items:", error);
        }


    };

    // const handleSearchChange = (e) => {
    //     const searchValue = e.target.value;
    //     console.log("Search input changed to:", searchValue);
    //     setListingsearch(searchValue);
    // };

    // const loadmore = async () => {
    //     console.log("Loading more items. Current skip:", skip.current, "Current limit:", limit.current);

    //     setAdd(prev => prev + 10);
    //     skip.current += 10;

    //     console.log("New skip:", skip.current, "New limit:", limit.current);

    //     const payload = {
    //         userid: GetUserCode(),
    //         skip: skip.current,
    //         limit: limit.current,
    //     };

    //     try {
    //         const result = await GetOrderListHooks(payload);

    //         if (result?.type === "Success") {
    //             setOrderList(prevList => [...prevList, ...result?.data]);
    //             setCount(result?.count);
    //         } else {
    //             toast.error("Error fetching game items.");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching game items:", error);
    //     }
    // };

    // const filteredGameList = orderList.filter(item =>
    //     item.name.toLowerCase().includes(listingsearch.toLowerCase())
    // );

    const handlePageChange = (newPage) => {
        if (newPage >= 0 && newPage < totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to first page when items per page changes
    };


    return (
   <>
        <div className='allordertabel pb-4'>

            {/* <div className="input-group mb-3 wfull">
                <input type="search" className="form-control searchinput" placeholder="Search" name='listingsearch' value={listingsearch} onChange={handleSearchChange} aria-label="Recipient's username" aria-describedby="basic-addon2" />
                <div className="input-group-append">
                    <button className="btn btn-secondary" type="button"><IoSearchSharp /></button>
                </div>
            </div> */}

            <>
                {orderList.length > 0 ?
                    (
                        <>

                            <div className="mb-3 d-flex align-items-center gap-3">
                                <p htmlFor="itemsPerPage" className='text-light item_p mb-0'>Items per page:</p>
                                <select id="itemsPerPage" className='item_select' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                    <option value={5}>5</option>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={totalItems}>All</option>
                                </select>
                            </div>
                            
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        {/* <th>Order Id</th> */}
                                        <th>Asset Name</th>
                                        <th>Mode</th>
                                        <th>Buyer/Seller name</th>
                                        <th>Price</th>
                                        {/* <th>Contract Address</th> */}
                                        <th>Created Date</th>
                                        {/* <th>Sold Date</th>
                                            <th>Admin Fee</th>
                                            <th>Ratings</th> */}
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList.map((e, i) =>
                                        <tr>
                                            {console.log("MAP", e?.buyerId?._id, e?.createrID?._id, usr_id)}
                                            {/* <td>{e._id}</td> */}
                                            <td>{e.gameId.name}</td>
                                            <td>
                                                <span className={e?.buyerId?._id == usr_id ? "text-success" : "text-danger"}>{e?.buyerId?._id == usr_id ? "BUY" : "SELL"}</span>
                                            </td>
                                            <td>{e.buyerId == usr_id ? e.createrID?.name : e.buyerId?.name}</td>
                                            <td>{e?.gameId?.price}</td>
                                            {/* <td>{e?.contractAddress}</td> */}
                                            <td>{new Date(e.createdAt)?.toLocaleDateString()}</td>
                                            {/* <td>{e.sold}</td>
                            <td>{e.admin}</td>
                            <td>{e.rating} <img src={stars} className='img-fluid' /></td> */}
                                            <td>{e.status}</td>
                                        </tr>
                                    )}

                                </tbody>
                            </Table>

                        </>
                    ) : (

                        <>
                            <Table hover responsive>
                                <tbody>
                                    <tr>
                                        <td colSpan="6" className="text-center">No Record Found</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </>

                    )
                }
            </>
            <>
                {totalPages > 1 && (
                    <nav className='d-flex justify-content-end'>
                        <ul className="pagination">
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(0)}
                                    disabled={currentPage === 0}
                                >
                                    First
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 0}
                                >
                                    Prev
                                </button>
                            </li>

                            <li className="page-item">
                                <button className="page-link">
                                    {currentPage + 1}
                                </button>
                            </li>

                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages - 1}
                                >
                                    Next
                                </button>
                            </li>
                            <li className="page-item">
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(totalPages - 1)}
                                    disabled={currentPage === totalPages - 1}
                                >
                                    Last
                                </button>
                            </li>
                        </ul>
                    </nav>
                )}
            </>


        </div>
        </>
    )
}

export default Orders