import React, { useState, useEffect } from 'react';
import { Container } from "react-bootstrap";
import Header from "../Components/Header";
import Historytable from '../Components/histotytable';
import Footer from '../Components/Footer';
import SupportModal from '../Components/SupportModal';
import { toast } from 'react-toastify';
import { getAllSupportList } from '../Hooks/contactus-supportHooks';

import config from '../Config/env'
import { useSelector } from 'react-redux';
import { GetUserCode } from '../Hooks/UseWallet';

function Support() {

    const userdata = useSelector((state) => state?.User?.data[0]);

    const [orderTableList, setOrderTableList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [supportModal, setSupportModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });


    }, []);

    useEffect(() => {
        getSupportList();
    }, [currentPage, itemsPerPage])

    const getSupportList = async () => {

        console.log("USER", userdata);


        try {
            const payload = {
                userid: GetUserCode(),
                skip: currentPage * itemsPerPage,
                limit: itemsPerPage
            };
            const resp = await getAllSupportList(payload);

            if (resp?.status) {
                toast.success(resp.msg);

                // Extract items and total count from the response
                const { items, total } = resp.data;

                console.log("resp.data.items", items);

                setOrderTableList(items.reverse()); // Set the list of items
                setTotalItems(total); // Set the total number of items
            } else {
                toast.error(resp.msg);
            }
        } catch (error) {
            // toast.error('An error occurred while fetching data');
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to first page when items per page changes
    };

    return (
        <>
            {supportModal && <SupportModal type="commonsupport" onDismiss={() => {
                getSupportList()
                setSupportModal(false)
            }} />}

            <Container fluid className="p-0 common_bg">
                <Header />

                <div className='innerpages'>
                    <div className='container'>
                        <h2 className='whtclr vendin text-center pt-5 pt-sm-0 mb-4 mb-sm-5'>Support</h2>
                        <div className='text-end mt-4 mb-4'>
                            <button type="button" className="header__connectBtn rounded-3 poppins px-3 fw-600" onClick={() => setSupportModal(true)}>
                                Create
                            </button>
                        </div>


                        <div className='pt-4'>
                            <>
                                {totalItems > 0 &&

                                    <div className="mb-3 d-flex align-items-center gap-3">
                                        <p htmlFor="itemsPerPage" className='text-light item_p mb-0'>Items per page:</p>
                                        <select id="itemsPerPage" className='item_select' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={totalItems}>All</option>
                                        </select>
                                    </div>
                                    
                                }
                            </>
                            <Historytable
                                orderTableList={orderTableList}
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                totalItems={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>

                <Footer />
            </Container>
        </>
    );
}

export default Support;
