import { Images } from "./Images";

export const allData = {
    homeMarquee : [
        {
            img : Images.ripple
        },
        {
            img : Images.huobi
        },
        {
            img : Images.binance
        },
        {
            img : Images.bitcoin
        },
        {
            img : Images.ethereum
        },
        {
            img : Images.payeer
        },
        {
            img : Images.gemini
        },
    ],
    creatorCard : [
        {
            img : Images.wallet,
            title : 'connect wallet',
            hint : 'We are a community based collection project featuring 3D artwork. 3D sneaker Heads comes in all kinds of evil shapes, traits and sizes with a few surprise along the way'
         
        },
        {
            img : Images.game,
            title : 'Select Your Sell Game',
            hint : 'We are a community based collection project featuring 3D artwork. 3D sneaker Heads comes in all kinds of evil shapes, traits and sizes with a few surprise along the way',
            link:"/sellingtab",
        },
        {
            img : Images.trade,
            title : 'Get Your Profit',
            hint : 'We are a community based collection project featuring 3D artwork. 3D sneaker Heads comes in all kinds of evil shapes, traits and sizes with a few surprise along the way',
            link:"/",
        },
    ],
    games : [
        {
            img : Images.godofwar
        },
        {
            img : Images.pubg
        },
        {
            img : Images.daylight
        },
        {
            img : Images.freefire
        },
        {
            img : Images.godofwar
        },
        {
            img : Images.pubg
        },
        {
            img : Images.daylight
        },
        {
            img : Images.freefire
        }
    ],
    categoryimgs: [
        {
            cimg: Images.categoryimgone
        },
        {
            cimg: Images.categoryimgtwo
        },
        {
            cimg: Images.categoryimgthree
        },
        {
            cimg: Images.categoryimgfour
        },
        {
            cimg: Images.categoryimgfive
        },
        {
            cimg: Images.categoryimgsix
        },
        {
            cimg: Images.categoryimgseven
        },
        {
            cimg: Images.categoryimgeight
        }
    ]
}