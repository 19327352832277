import axios from "axios";

import config from "../Config/env";

import store from "../Redux/Store/Store";

import {AppenData, EncryptString, Decryptdata, Encryptdata, GenerateSecretKey } from "./Authenticate";

import { uploadNewGame, editGame } from "../Routes/UploadGameRoutes";

export const AddNewGameItems = async (payload) => {

    try {
        let appendData = AppenData(payload)
        console.log("appendData", appendData)
        var resp = await axios({
            "method": "POST",
            "url": config?.API_URL + uploadNewGame,
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${atob(localStorage.getItem("USRDT"))}`,
            },
            "data": appendData[0],
        });

        let response = Decryptdata(resp?.data);
        return response;


    } catch (e) {
        console.log("Error on Create New Support", e);
    }

}

export const EditGameItem = async (payload) => {

    try {
        let appendData = AppenData(payload)
        console.log("appendData", appendData)
        var resp = await axios({
            "method": "POST",
            "url": config?.API_URL + editGame,
            "headers": {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${atob(localStorage.getItem("USRDT"))}`,
            },
            "data": appendData[0],
        });

        let response = Decryptdata(resp?.data);
        return response;


    } catch (e) {
        console.log("Error on Create New Support", e);
    }

}