import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header";
import { Images } from "../Components/Images";
import Orders from '../Components/orders';
import Cardlist from '../Components/cardlist';
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import ChatHistoryTable from '../Components/ChatHistoryTable';
import { getAllChatHistory } from '../Hooks/contactus-supportHooks';

import { toast } from 'react-toastify';
import { GetUserCode } from '../Hooks/UseWallet';


function Chathitorytabless() {

    const [chatHistory, setChatHistory] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);


    useEffect(() => {
        getChatHistory();
    }, [currentPage, itemsPerPage]);


    const getChatHistory = async () => {

        // console.log("USER", userdata);


        try {
            const payload = {
                userid: GetUserCode(),
                skip: currentPage * itemsPerPage,
                limit: itemsPerPage
            };
            const resp = await getAllChatHistory(payload);

            if (resp?.status) {
                toast.success(resp.msg);

                // Extract items and total count from the response
                const { items, total } = resp.data;

                console.log("resp.data.items_chat", items);

                setChatHistory(items); // Set the list of items
                setTotalItems(total); // Set the total number of items
            } else {
                toast.error(resp.msg);
            }
        } catch (error) {
            // toast.error('An error occurred while fetching data');
        }
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to first page when items per page changes
    };



    return (

        <>

            <Container fluid className="p-0 common_bg">
                <Header />


                <div className='innerpages'>
                    <div className='container'>
                        <div className='chathistorytable mt-4'>
                            <h3 class="vioclr poppins fw-600 text-uppercase mb-3">Chat History</h3>

                            <>
                                {totalItems > 0 &&

                                    <div className="mb-3 d-flex align-items-center gap-3">
                                        <p htmlFor="itemsPerPage" className='text-light item_p mb-0'>Items per page:</p>
                                        <select id="itemsPerPage" className='item_select' value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                            <option value={5}>5</option>
                                            <option value={10}>10</option>
                                            <option value={20}>20</option>
                                            <option value={totalItems}>All</option>
                                        </select>
                                    </div>
                                }

                            </>

                            <ChatHistoryTable
                                chatHistory={chatHistory}
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                totalItems={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>

                    </div>
                </div>
                <Footer />

            </Container>


        </>

    )
}

export default Chathitorytabless;