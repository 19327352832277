import React, { useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import Countdown from 'react-countdown';
import { IoCloseSharp } from "react-icons/io5";
import OtpInput from 'react-otp-input';
import { ValidateEmail } from '../Validation/UserValidation';
import { ChangeEmailHooks, GetUserdataHooks, VerifyOldEmailHooks, VerifynewEmailHooks } from '../Hooks/UserHooks';
import { toast } from 'react-toastify';
import { GetUserCode } from '../Hooks/UseWallet';

function Changemodals(props) {

    const [changemodal, setChangemodal] = useState(true);
    const [otp, setOtp] = useState('');
    const [stepmail, setStepmail] = useState(1);

    //dev_murugavel
    const [error , setError] = useState({});
    const [newemail , setNewemail] = useState("");
    const [oldotpsend , setOldotpsend] = useState(false);
    const [expired , setExpired] = useState(0)



    const HandleSubmit = async() => {
        try{
            let payload = {
                oldemail : props?.email,
                newemail : newemail
            }
            let result = await ChangeEmailHooks(payload);
            if(result?.type == "Success"){
                setError({});
                setOldotpsend(true);
                setExpired(result?.expired);
                toast.success(result?.message);
            }
            else if(result?.type == "Failed"){
                toast.error(result?.message);
            }
        }
        catch(e){
            console.log("Error on handle submit" , e);
        }
    }

    const VerifyOldEmail = async() => {
        try{
            let payload = {
                email : props?.email,
                otp : otp
            };
            let result = await VerifyOldEmailHooks(payload);
            if(result?.type == "Success"){
                setStepmail(2);
                setExpired(result?.expired);
                setOtp("");
                toast.success(result?.message);
                
            }
            else if(result?.type == "Failed"){
                toast.error(result?.message);
            }
        }
        catch(e){
            console.log("Error on verify old email" , e);
        }
    }
    

    const VerifyNewEmail = async() => {
        try{
            let payload = {
                email : props?.email,
                otp : otp
            };
            let result = await VerifynewEmailHooks(payload);
            if(result?.type == "Success"){
                setStepmail(2);
                setExpired(result?.expired);
                setOtp("");
                toast.success(result?.message)
                window.location.reload();
                props?.onDismiss();
            }
            else if(result?.type == "Failed"){
                toast.error(result?.message);
            }
        }
        catch(e){
            console.log("Error on verify old email" , e);
        }
    }

    return (

        <div>

            <Modal show={changemodal} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>

                    {stepmail == 1 &&
                        <>
                            <h3 className='whtclr poppins mt-4 mb-4'>Change Your mail-iD</h3>

                            <div className='mb-3'>
                                {/* <p className='whtclr poppins mb-2'>Enter the OTP you just received on your email address 
                                {props?.email &&  props?.email?.substring(0 , 3) + Array(props?.email?.length - 14).join("*")+ props?.email?.substring(props?.email?.length-10 , props?.email?.length)}
                                </p> */}
                                <label className='whtclr poppins mb-2'>Enter New Email</label>
                                <input type='mail' className='form-control g_input mb-3' placeholder='fromto@gmail.com' 
                                readOnly = {oldotpsend}
                                onChange={(e) => {
                                    setNewemail(e?.target?.value)
                                    let validate = ValidateEmail({email : e?.target?.value});
                                    let err = {newemail : validate ? validate : ""}
                                    if(validate){
                                        setError(err)
                                    }
                                    else{
                                        setError({});
                                    }
                                }}
                                />
                                <span className='text-danger mt-1'>{error?.newemail}</span>
                            </div>
                            {!oldotpsend && <button className='header__connectBtn rounded-2 vendin w-100' disabled = {!newemail || Object.keys(error)?.length > 0} 
                            onClick={HandleSubmit}
                            >Submit</button>}
                        {oldotpsend && <>
                            <h4 className='whtclr poppins text-center mb-0 mt-4'>OTP</h4>
                            <div className='d-flex justify-content-center pt-2 pb-3'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} className='otpinputs' />}
                                />
                                <p className='text-danger text-center mb-0'>{error?.otp}</p>

                            </div>
                            <div className='text-center mt-2 mb-4'>{expired && <Countdown date={parseFloat(expired)} className='mt-3 counts' />}</div>

                            <button className='header__connectBtn rounded-2 vendin w-100' 
                            disabled = {!(otp?.length >= 4)} 
                            onClick={VerifyOldEmail}>Verify</button>
                        </>}
                        </>
                    }


                    {stepmail == 2 &&
                        <>
                         <div className='mb-3'>
                                <label className='whtclr poppins mb-2'>New Email Id</label>
                                <input type='mail' className='form-control g_input mb-3' placeholder='fromto@gmail.com' 
                                value={newemail} readOnly
                                />
                            </div>
                            {/* <button className='header__connectBtn rounded-2 vendin w-100'>Send OTP</button> */}
                            <h4 className='whtclr poppins text-center mb-0 mt-4'>OTP</h4>
                            <div className='d-flex justify-content-center pt-2 pb-4'>

                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} className='otpinputs' />}
                                />
                            </div>
                            <div className='text-center mt-2 mb-3'>{expired && <Countdown date={parseFloat(expired)} className='mt-3 counts' />}</div>

                            <button className='header__connectBtn rounded-2 vendin w-100' 
                            disabled = {!(otp?.length >= 4)} 
                            onClick={VerifyNewEmail}
                            >Verify</button>
                        </>}



                </Modal.Body>
            </Modal>


        </div>

    )
}

export default Changemodals