import React from 'react';
import { Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import config from '../Config/env';
import { useNavigate } from 'react-router-dom';

function Historytable({ orderTableList, currentPage, itemsPerPage, totalItems, onPageChange }) {

    const navigate = useNavigate();

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className='allordertabel pb-4 supporttable'>
            {orderTableList.length > 0 ? (
                <>
                    <Table hover responsive>
                        <thead>
                            <tr>
                                <th>S.no</th>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Image</th>
                                <th>Time Stamp</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderTableList.map((e, i) => (
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{e._id}</td>
                                    <td>{e.title}</td>
                                    <td>
                                        <div className=''>
                                            <img src={`${config.API_URL}${e.imagePath}${e.image}`} className='col-6 imgw' alt={e.title} />
                                        </div>
                                    </td>
                                    <td>{formatTimestamp(e.updatedAt)}</td>
                                    <td>{e.status}</td>
                                    <td>
                                        {/* <button className="header__connectBtn rounded-3 poppins w-50 me-4" 
                                        onClick={() => handleHistory(e)}
                                        >
                                            View
                                        </button> */}
                                        <Link to={`/detailhistory/${e._id}`}  className="header__connectBtn rounded-3 poppins w-50 me-3">
                                            View
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {totalPages > 1 && (
                        <nav className='d-flex justify-content-end'>
                            <ul className="pagination">
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(0)}
                                        disabled={currentPage === 0}
                                    >
                                        First
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                    >
                                        Prev
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="page-link">
                                        {currentPage + 1}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button
                                        className="page-link"
                                        onClick={() => onPageChange(totalPages-1)}
                                        disabled={currentPage === totalPages - 1}
                                    >
                                        Last
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    )}
                </>
            ) : (
                <Table hover responsive>
                    <tbody>
                        <tr>
                            <td colSpan="6" className="text-center">No Record Found</td>
                        </tr>
                    </tbody>
                </Table>
            )}
        </div>
    );
}

export default Historytable;




// import React, { useState, useEffect } from 'react';
// import { Table } from "react-bootstrap";
// import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { getAllSupportList } from '../Hooks/contactus-supportHooks';
// import ReactPaginate from 'react-paginate';

// function Historytable() {
//     const [ordertablelist, setOrdertablelist] = useState([]);
//     const [currentPage, setCurrentPage] = useState(0);
//     const [itemsPerPage, setItemsPerPage] = useState(10);
//     const [totalItems, setTotalItems] = useState(0);

//     useEffect(() => {
//         getSupportList();
//     }, [currentPage, itemsPerPage]);

//     const getSupportList = async () => {
//         const payload = {
//             skip: currentPage * itemsPerPage,
//             limit: itemsPerPage
//         };

//         try {
//             const resp = await getAllSupportList(payload);

//             if (resp?.status) {
//                 toast.success(resp.msg);

//                 // Extract items and total count from the response
//                 const { items, total } = resp.data;

//                 setOrdertablelist(items); // Set the list of items
//                 setTotalItems(total); // Set the total number of items
//             } else {
//                 toast.error(resp.msg);
//             }
//         } catch (error) {
//             toast.error('An error occurred while fetching data');
//         }
//     };

//     const handlePageClick = (data) => {
//         setCurrentPage(data.selected);
//     };

//     const handleItemsPerPageChange = (event) => {
//         setItemsPerPage(Number(event.target.value));
//         setCurrentPage(0); // Reset to first page when items per page changes
//     };

//     const formatTimestamp = (timestamp) => {
//         const date = new Date(timestamp);
//         return date.toLocaleString();
//     };

//     const totalPages = Math.ceil(totalItems / itemsPerPage);

//     return (
//         <div className='allordertabel pb-4 supporttable'>
//             <div className="mb-3">
//                 <label htmlFor="itemsPerPage">Items per page:</label>
//                 <select id="itemsPerPage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
//                     <option value={2}>2</option>
//                     <option value={5}>5</option>
//                     <option value={10}>10</option>
//                     <option value={20}>20</option>
//                     <option value={-1}>All</option>
//                 </select>
//             </div>
//             <Table hover responsive>
//                 <thead>
//                     <tr>
//                         <th>Asset Name</th>
//                         <th>Type</th>
//                         <th>Time Stamp</th>
//                         <th>Status</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {ordertablelist.map((e, i) => (
//                         <tr key={i}>
//                             <td>{e.assetName}</td>
//                             <td>{e.type}</td>
//                             <td>{formatTimestamp(e.updatedAt)}</td>
//                             <td>{e.status}</td>
//                             <td>
//                                 <Link to="/detailhistory" className="header__connectBtn rounded-3 poppins w-50 me-3">
//                                     View
//                                 </Link>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </Table>
//             {totalPages > 1 && (
//                 <ReactPaginate
//                     previousLabel={'<'}
//                     nextLabel={'>'}
//                     breakLabel={'...'}
//                     pageCount={totalPages}
//                     marginPagesDisplayed={2}
//                     pageRangeDisplayed={5}
//                     onPageChange={handlePageClick}
//                     containerClassName={'pagination'}
//                     activeClassName={'active'}
//                 />
//             )}
//         </div>
//     );
// }

// export default Historytable;









// // import React, { useState, useEffect } from 'react';
// // import { Table } from "react-bootstrap";
// // import { Link } from 'react-router-dom';
// // import { toast } from 'react-toastify';
// // import { getAllSupportList } from '../Hooks/contactus-supportHooks';
// // import ReactPaginate from 'react-paginate';

// // function Historytable() {

// //      const[ordertablelist,setOrdertablelist] = useState([]);


// //     useEffect(() => {
// //         getSupportList();
// //     }, []);

// //     const getSupportList = async () => {
        
// //         const payload = {
// //             skip: 0,
// //             limit: 10
// //         }

// //         var resp = await getAllSupportList(payload);

// //         if (resp?.status) {
// //             toast.success(resp.msg)

// //             console.log("resp.data ", resp.data);

// //             setOrdertablelist(resp.data);
// //             // props.onDismiss();
// //             // setTimeout(() => {
// //             //   history.push("/support")
// //             // }, 1000);
    
// //           }
// //           else return toast.error(resp.msg)

// //     }

// //     const formatTimestamp = (timestamp) => {
// //         const date = new Date(timestamp);
// //         return date.toLocaleString(); // Adjust this if you need specific formatting
// //     };

// //     return (

// //         <div className='allordertabel pb-4 supporttable'>
// //             <Table hover responsive>
// //                 <thead>
// //                     <tr>
// //                         <th>Asset Name</th>
// //                         <th>Type</th>
// //                         <th>Time Stamp</th>
// //                         <th>Status</th>
// //                         <th>Action</th>
// //                     </tr>
// //                 </thead>
// //                 <tbody>
// //                     {ordertablelist.map((e,i)=>
// //                     <tr>
// //                         <td>{e.assetName}</td>
// //                         <td>{e.type}</td>
// //                         <td>{formatTimestamp(e.updatedAt)}</td>             
// //                         <td>
// //                             {e.status}
// //                         </td>             
// //                         <td>
// //                             <Link to="/detailhistory" className="header__connectBtn rounded-3 poppins w-50 me-3">
// //                                 View
// //                             </Link>
// //                         </td>
// //                     </tr>
// //                     )}
                  
// //                 </tbody>
// //             </Table>

// //         </div>
// //     )
// // }

// // export default Historytable