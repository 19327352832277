import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import { Images } from './Images';

function Showreview(props) {
    const [showreviewmodal, setShowreviewmodal] = useState(true);
    const navigate = useNavigate();

    return (
        <div>
            <Modal show={showreviewmodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                <span className="closebtn" onClick={() => props.onDismiss()}>
            <IoCloseSharp />
          </span>
                <h3 className='vendin whtclr text-center'>Review</h3>
                <div className='reviewlisted mt-3'>
                                        <ul>
                                            {/* {console.log("reviewlist" , reviewlist)} */}
                                            {props.reviewlist?.review?.map((e , i) => (
                                                <li className='mb-4'>
                                                    <div className='d-flex gap-2'>
                                                    <img src={Images.pimg} className='img-fluid peimg'/>
                                                    <div>
                                                        <p className='name mb-1'>{props.reviewlist?.detail[i]?.name}</p>
                                                        <p className='desc mb-0'>{e?.comment}</p>
                                                    </div>
                                                    </div>
                                                    <div>
                                                        <ReactStars value={e?.rating} count={5} size={24} edit={true} isHalf={false} activeColor="#FFBB0D" />
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Showreview;