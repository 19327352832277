import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Offcanvas, Row, Dropdown, DropdownItem } from 'react-bootstrap'
import {PrivyProvider , useConnectWallet , useLogout, useWallets} from '@privy-io/react-auth';
import {base, baseGoerli, mainnet, sepolia, polygon, polygonMumbai} from 'viem/chains';
import {usePrivy} from '@privy-io/react-auth';
import { Images } from './Images'
import { NavLink, useNavigate } from 'react-router-dom'
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { IoIosWallet } from "react-icons/io";
import { Link } from 'react-router-dom';
import Walletmodal from './walletmodal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from "jquery";
import { ConnectPrivy, DisconnectPrivy, GetChain, GetUserAcccount, GetUserCode, GetUserEmail, Logout, SetChain } from '../Hooks/UseWallet';
import { CHAINS } from '../Config/Network.';
import { GetUserdataHooks } from '../Hooks/UserHooks';
import Formspagemodal from "./formpage";
import { GetAllGameItemHooks } from '../Hooks/GameHooks';
import Nofificationmodal from './notificationmodal';
import SocketContext from '../Context/SocketContext';
import config from "../Config/env";
import { useSelector } from 'react-redux';

const Header = () => {
  const navigate = useNavigate()
const networks = CHAINS; //[base, baseGoerli, mainnet, sepolia, polygon, polygonMumbai]
const {login , ready} = usePrivy();
const { connect, disconnect, wallet, connecting } = useConnectWallet();
const { wallets} = useWallets();
const {logout} = useLogout({
  onSuccess: () => {
    DisconnectPrivy();

  },
});
  const [show, setShow] = useState(false);
  const [walletmodal, setWalletmodal] = useState(false);
  const [formsmodal, setFormsmodal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [isMobile, setIsMobile] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [categorylist , setCategorylist] = useState([]);
  const [notifymodal , setNotifymodal] = useState(false);
  const socketContext = useContext(SocketContext);
  const [keyval , setKeyval] = useState("");
  const [keydata , setKeydata] = useState({});
  const [key , setKey] = useState();
  const userdata = useSelector((state) => state?.User?.data[0]);
  




  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    fetchdata()
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);
    console.log("privy read" , wallets);
    // if(GetUserAcccount()){
      if(wallets){
        ConnectPrivy(wallets);
      }
      else{
        DisconnectPrivy();
      }
      SwitchPrivy(networks[parseInt(GetChain())]);
    // }
    Getuserdata();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  } , [wallets]);

  const Getuserdata = async() => {
    if(GetUserCode()){
      let result = await GetUserdataHooks({id : GetUserCode()});
    }
  }

  const fetchdata = async() => {
    try{
        let result = await GetAllGameItemHooks();
        if(result?.type == "Success"){
            setCategorylist(result?.record);
        }
    }
    catch(e){
        console.log("error on fetchdata" , e);
    }
}
  // useEffect(() => {


  //   const handleResize = () => {
  //     console.log(window.innerWidth, "sadfsaf");

  //     if (window.innerWidth <= 576) {
  //       setIsMobile(true)
  //     } else setIsMobile(false)
  //   };



  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);



  // console.log(isMobile, "sadfsaf");

  const disconnects = () => {
    localStorage.clear();
    // navigate('/')
    // window.location.reload();
    window.location = "/"
    toast.error("Disconnected Successfully");

  };


   const SwitchPrivy = async(data) => {
    try{
        if(GetUserEmail()){
          console.log("Switch privy networkd");
          const wallet = wallets[0]; // Replace this with your desired wallet
          await wallet.switchChain(data?.id);
        }
    }
    catch(e){
        console.log("Error on switch privy" , e);
    }
}

const SwitchPrivyNetwork = async(data , i) => {
  try{
    if(GetUserEmail()){
      SetChain(i);
      const wallet = wallets[0]; // Replace this with your desired wallet
      let res =  wallet.switchChain(data?.id);
      await toast.promise(res, {
        loading: 'Switching Chain...',
        success: 'Switch Chain Successfully',
        error: 'Error ! When Switch Chain',
    }, 
    );
    window.location.reload();
    }
    
  }
  catch(e){
    console.log("Error on switch privy" , e);
  }
}

  const [showDropdown1, setShowDropdown1] = useState(false);
  const[menuss,setmenuss] = useState([
    {name:"ROBLEX"},{name:"WAR FRAME"},{name:"FALLOUT"},{name:"GTA"},{name:"PUBG"},
  ])

  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(this).scrollTop()) {
      $(".header").addClass("fixedTop");
    } else {
      $(".header").removeClass("fixedTop");
    }
    if (scroll >= 100) {
      $(".fixedTop").addClass('scroll');
      $("#scroll-top").addClass('show');
    }
    else {
      $(".fixedTop").removeClass('scroll');
      $("#scroll-top").removeClass('show');
    }
  });











  useEffect(() => {
    socketContext?.socket.on('ROOMCREATED' , (data) => {console.log("Socket created" , data);
        if(data?.creater == GetUserCode()){
            setKeyval("ownerapproval");
            setKeydata(data);
            setTimeout(() => {
              setNotifymodal(true)
            } , 3000)
            
        }
    })



    socketContext?.socket?.on('OWNERAPPROVE' , (data) => {
      if(data?.buyerId == GetUserCode()){
        setKeyval("ownerapproved");
            setKeydata(data);
            setTimeout(() => {
              setNotifymodal(true)
            } , 3000)
      }
    })

    socketContext?.socket?.on('OWNERREJECT' , (data) => {
      if(data?.buyerId == GetUserCode()){
        setKeyval("ownerrejected");
            setKeydata(data);
            setTimeout(() => {
              setNotifymodal(true)
            } , 3000)
      }
    })

} , [socketContext])











  return (
    <>
      {walletmodal && <Walletmodal onDismiss={() => setWalletmodal(false)} />}
      {formsmodal && <Formspagemodal onDismiss={() => setFormsmodal(false)} onLogin = {login} logdata = {key}/>}
        {notifymodal && <Nofificationmodal onDismiss={() => setNotifymodal(false)}   keyreg = {keyval && keyval} data = {keydata} />}


      <div className='header'>
        <Container >
          <div className='d-flex align-items-center justify-content-between py-3'>
            <div>
              <Link to="/"><img src={Images.logo} className='img-fluid header__logo' alt='' /></Link>
            </div>
            <div className='d-none d-lg-block'>
              <div className='header__linksHolder'>
                <ul className='d-flex justify-content-center align-items-center gap5 m-0'>
                  <NavLink to="/" className='header__links'>
                    <li className='vendin pointer'>home</li></NavLink>
                  {/* <NavLink to="/explore" className='header__links'><li className='vendin pointer'>Game Items</li></NavLink> */}
                  <Dropdown onMouseLeave={() => setShowDropdown1(false)}
                    onMouseOver={() => setShowDropdown1(true)}>
                    <Dropdown.Toggle
                      className="vendin pointer gamebtn p-0"
                      id="dropdown-basic"
                    >
                      Game Items
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showDropdown1} className='gamemenu headmenu py-3 px-3'>
                      <div className='row'>
                        {categorylist.map((e,i)=> 
                        (i < 6) &&
                        <div className='col-lg-6 mb-2'>
                          {/* `/explore?catid=${e?._id}` */}
                        <Link onClick = {() => window.location.href = window.location.origin + `/explore?catid=${e?._id}`} className='vendin'>
                        {/*  */}
                         {e.name}
                      </Link>
                        </div>)}
                      </div>
                     
                    </Dropdown.Menu>
                  </Dropdown>
                  <NavLink to="/sellingtab" className='header__links'><li className='vendin pointer'>Sell Game item</li></NavLink>
                  <NavLink to="/partnership" className='header__links'><li className='vendin pointer'>Partnership</li></NavLink>
                </ul>
              </div>
            </div>
            <div className='flex-grow-1 flex-md-grow-0'>
              <div className='d-flex justify-content-end'>
                <div className='d-none d-lg-block'>
              {!GetUserEmail() && <button className='header__connectBtn rounded-2 vendin me-2 me-md-2' onClick={() => {setFormsmodal(true); setKey("register")}}>Register</button> }

              {!GetUserEmail() && <button className='header__connectBtn rounded-2 vendin me-2 me-md-0' onClick={() => {setFormsmodal(true); setKey("login")}} >Log In</button> }
              </div>
             
              {/* Drop down for multiple networks */}
              {/* <Dropdown className="walletdrop netdrop me-1">
              <Dropdown.Toggle id="dropdown-basic" className="d-flex align-items-center py-1 header__connectBtn net rounded-2"><h6 className="poppins whtclr mb-0"><img src={Images.imgicon} className="netimg me-1" /></h6></Dropdown.Toggle>
              <Dropdown.Menu className='pt-2 pb-2 px-2'>
                {networks?.map((item  , i) => <Dropdown.Item onClick={()=> {
                  SwitchPrivyNetwork(item , i)
                  
                  }}><h6 className="poppins whtclr mb-0"><img src={Images.imgicon} className="netimg me-1" /> {item?.name}</h6></Dropdown.Item>)}
                </Dropdown.Menu>
              </Dropdown> */}
              
              {GetUserEmail() && !GetUserAcccount() ?
                <button className='header__connectBtn rounded-2 vendin me-2 me-md-0' 
                // onClick={() => setWalletmodal(true)}
                onClick={login}
                >{windowWidth < 768 ? <IoIosWallet /> : 'Connect Wallet'}</button> :
                GetUserEmail() && <Dropdown className="walletdrop" onMouseLeave={() => setShowDropdown(false)}
                  onMouseOver={() => setShowDropdown(true)}>
                  <Dropdown.Toggle id="dropdown-basic" className="d-flex align-items-center py-1 header__connectBtn rounded-2"><h6 className="poppins whtclr mb-0"><img src={userdata?.profileImage ? config?.API_URL+userdata?.profilePath + userdata?.profileImage : Images.pimg} className="profimg me-1" />{windowWidth < 768 ? " " :  `${GetUserAcccount()?.substring(0, 5)}...${GetUserAcccount()?.substring(38,42)}` } </h6></Dropdown.Toggle>
                  {/* Images.imgicon */}
                  <Dropdown.Menu className='pt-3 pb-2 px-3' show={showDropdown}>
                    <h6 className='whtclr vendin'>Wallet Address</h6>
                    <p className='whtclr poppins mb-3'>{GetUserAcccount() ? `${GetUserAcccount()?.substring(0, 5)}...${GetUserAcccount()?.substring(38,42)}` : ""}</p>
                    <Link to="/profile">Profile</Link>
                    {/* <Link to="/login">Login</Link> */}
                    <Link to="/chathistory">Chat History</Link>
                    <Link to="/support">Support</Link>
                    <Link onClick={() => {
                      logout();
                      
                      }}>Disconnect</Link>
                      <Link onClick={() => {
                      Logout()
                      window.location.reload();
                      
                      }}>Logout</Link>
                  </Dropdown.Menu>
                </Dropdown>
              }
              <button className='rounded-2 header__hamburger d-lg-none ms-2' onClick={handleShow}><GiHamburgerMenu /></button>
              {/* <Link to="/login" className='header__connectBtn rounded-2 vendin ms-2'>Login</Link> */}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* header canvas */}
      <Offcanvas show={show} onHide={handleClose} className='header__canvas' placement='end' backdrop="static">
        <Offcanvas.Body>
          <div className='header__canvasHead d-flex justify-content-between align-items-center'>
            <img src={Images.logo} className='img-fluid footer__logo' alt='' />
            <button className='header__transBtn' onClick={handleClose}><IoClose /></button>
          </div>
          <div className='header__canvaBody mt-5'>
            <div className='header__linksHolder'>
              <ul className='d-flex flex-column headinga gap-4 m-0'>
              {!GetUserEmail() && <button className='header__connectBtn rounded-2 vendin me-2 me-md-2' onClick={() => {setFormsmodal(true); setKey("register")}}>Register</button> }

{!GetUserEmail() && <button className='header__connectBtn rounded-2 vendin me-2 me-md-0' onClick={() => {setFormsmodal(true); setKey("login")}} >Log In</button> }
                <NavLink className='header__links' to="/">
                  <li className='vendin pointer'>home</li></NavLink>
                  <Dropdown onMouseLeave={() => setShowDropdown1(false)}
                    onMouseOver={() => setShowDropdown1(true)}>
                    <Dropdown.Toggle
                      className="vendin pointer gamebtn p-0"
                      id="dropdown-basic"
                    >
                      Game Items
                    </Dropdown.Toggle>
                    <Dropdown.Menu show={showDropdown1} className='gamemenu py-3 px-3'>
                      <div className='row'>
                        {menuss.map((e,i)=> 
                        <div className='col-lg-6 mb-2'>
                        <Link to="/explore" className='vendin'>
                         {e.name}
                      </Link>
                        </div>)}
                      </div>
                     
                    </Dropdown.Menu>
                  </Dropdown>
                <NavLink className='header__links' to="/sellingtab"><li className='vendin pointer'>Sell Game item</li></NavLink>
                <NavLink to="/partnership" className='header__links'><li className='vendin pointer'>Partnership</li></NavLink>
                <NavLink className='header__links' to="/contact"><li className='vendin pointer'>Contact us</li></NavLink>
              </ul>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      {/* end of header canvas */}
    </>
  )
}

export default Header