import React, { useState, useEffect, useContext } from 'react';
import { Accordion, Button, Col, Container, Row, Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import { CHAINS } from '../Config/Network.';
import { GetChain, GetUserAcccount, GetUserCode } from '../Hooks/UseWallet';
import { CreateRoom } from '../Config/SocketConnectivity';
import SocketContext from '../Context/SocketContext';
import { OwnerApproveorRejectHooks } from '../Hooks/GameHooks';
import { toast } from 'react-toastify';

function Nofificationmodal(props) {
    const socketContext = useContext(SocketContext);
    const [notifymodal, setNotifymodal] = useState(true);
    const [stepss, setStepss] = useState(1);
    const [buyexp , setBuyexp] = useState(true);
    const [wait , setWait] = useState(false);
    const navigate = useNavigate();
    const [key , setKey] = useState("");

    useEffect(() => {
        setKey(props?.keyreg);
    } , [props?.keyreg])


    useEffect(() => {
        socketContext.socket.on('ROOMCREATED' , (data) => { console.log("Socket created" , data);
            if(data?.buyer == GetUserCode()){
                setBuyexp(false);
                setWait(true);
            }
        })

        socketContext?.socket?.on('OWNERAPPROVE' , (data) => {
            if(data?.buyerId == GetUserCode()){
                props.onDismiss()
            }
          })
      
          socketContext?.socket?.on('OWNERREJECT' , (data) => {
            if(data?.buyerId == GetUserCode()){
              props.onDismiss()
            }
          })
    } , [socketContext])

    const HandleCreateRoom = () => {
        let payload = {
            roomid : Date.now()?.toString(), 
            creater : props?.gamedetail?.createrId?._id, 
            buyer : GetUserCode(), 
            buyerwallet : GetUserAcccount(), 
            gamdid : props?.gamedetail?._id, 
            createrwallet : props?.gamedetail?.createrwallet,
            price : props?.gamedetail?.price
        }
        console.log("create room payload" , payload);
        CreateRoom(payload);
    }

    const HandleSellerApproveorreject = async(status) => {
        try{
            let payload = {
                roomid : props?.data?.roomid,
                status : status
            }
            let result = await OwnerApproveorRejectHooks(payload);
            if(result?.type == "Success" ){
                if(status == "Accepted"){
                    // navigate(`/chating/${result?.record?._id}`);
                    window.location.href = window.location.origin + `/chating/${result?.record?._id}`
                }
                else{
                    toast.success("Request Rejected Successfully");
                    props.onDismiss();
                }
            }
        }
        catch(e){
            console.log("Error on handle seller approve" , e);
        }
    }


    return (

        <div>
            <Modal show={notifymodal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>

                    {buyexp && props.pathname == "Buy_Request" &&
                        <>
                            <h3 className='vendin whtclr text-center mb-3'>{props?.assetname}</h3>
                            <h5 className='whtclr vendin text-center mb-4'>{props?.price} {CHAINS[GetChain()]?.nativeCurrency.symbol}</h5>
                            <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin px-4' onClick={() => props.onDismiss()}>Close</button>
                            <button  onClick={HandleCreateRoom} className='header__connectBtn rounded-2 vendin ms-3 px-4'>Send Buy Request</button></div>
                        </>}

                        {console.log("props?.key" , props?.key)}
                    {wait &&
                         <>
                         <h3 className='vendin whtclr text-center'>Awaiting for Seller CHat Approval</h3>
                         <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' 
                         onClick={() => props.onDismiss()}>Ok</button>
                         {/* <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => setStepss(2)}>Chat</button> */}
                         </div>
                        </>
                    }

                    {key &&  key == "ownerapproval" &&
                        <>
                        <h3 className='vendin whtclr text-center'>Buyer wait for you Approval</h3>
                        <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' 
                        onClick={async() => await HandleSellerApproveorreject("Rejected")}>Reject</button>
                        <button className='header__connectBtn rounded-2 vendin ms-3' onClick={async() => await HandleSellerApproveorreject("Accepted")}>Approve</button>
                        </div>
                       </>
                    }

                    {key &&  key == "ownerapproved" &&
                        <>
                        <h3 className='vendin whtclr text-center'>Seller Approved your request</h3>
                        <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' 
                        onClick={() => props.onDismiss()}>Close</button>
                        <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => navigate(`/chating/${props?.data?._id}`)}>Chat Now</button>
                        </div>
                       </>
                    }

                    {key &&  key == "ownerrejected" &&
                        <>
                        <h3 className='vendin whtclr text-center'>Seller Rejected your request</h3>
                        <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' 
                        onClick={() => props.onDismiss()}>Close</button>
                        {/* <button className='header__connectBtn rounded-2 vendin ms-3' onClick={async() => await HandleSellerApproveorreject("Accepted")}>Chat Now</button> */}
                        </div>
                       </>
                    }

                    {props.pathname == "/carddetail" &&
                        <>
                            {stepss == 1 &&
                                <>
                                    <h3 className='vendin whtclr text-center'>Awaiting for Super Admin Apporoval</h3>
                                    <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' onClick={() => props.onDismiss()}>Close</button>
                                    <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => setStepss(2)}>Chat</button></div>
                                </>
                            }
                            {stepss == 2 &&
                                <>
                                    <h3 className='vendin whtclr text-center'>Awaiting for Seller CHat Approval</h3>
                                    <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' onClick={() => props.onDismiss()}>Requset Cancel</button>
                                    <button className='header__connectBtn rounded-2 vendin ms-3' onClick={() => setStepss(3)}>Approved</button></div>
                                </>
                            }
                            {stepss == 3 &&
                                <>
                                    <h3 className='vendin whtclr text-center'>Buyer want to connect with
                                        for asset card A</h3>
                                    <div className='text-center mt-3'><button className='header__connectBtn rounded-2 vendin' onClick={() => props.onDismiss()}>Cancel</button><Link to="/chating" state={{data:"yes"}}><button className='header__connectBtn rounded-2 vendin ms-3'>Accept</button></Link></div>
                                </>
                            }
                        </>
                    }

                </Modal.Body>
            </Modal>

        </div>

    )
}

export default Nofificationmodal;