import axios from "axios"
import config from "../Config/env";
import { ChangeEmailApi, GetAllNotificationApi, GetDeliveryMethodApi, GetNotificationApi, GetUserdataApi, LoginApi, ReadOneNotificationApi, RegisterUserEmailApi, RegisteruserApi, UpdateUserdataApi, VerifyNewEmailApi, VerifyOldEmailApi, VerifyRegisterEmailApi ,GetGameItemsList, UpdateNotifyTokenApi , UpdateGameStatus, AddReviewApi, GetOrderItemsList, LandingDataApi , GetUserOrderItemsList, UpdateOrderStatus, GetReviewDetailListApi, EditReviewApi} from "../Routes/UserRoutes";
import store from "../Redux/Store/Store";
import { DecryptString, EncryptString, GenerateSecretKey, Decryptdata, Encryptdata, } from "./Authenticate";



export const RegisterNewUserHooks = async (data) => {
    try{
        let result = await axios.post(config?.API_URL+RegisteruserApi,data)
        
        return result?.data;
    }
    catch(e){
        console.log("Error on register new user hooks" , e);
    }
}


export const UpdateUserdataHooks = async (data) => {
    try{
        let result = await axios.post(config?.API_URL+UpdateUserdataApi,data)
        if(result?.data?.record){
            store.dispatch({ type: 'USER_DATA', payload: result?.data?.record });
        }
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}



export const GetUserdataHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetUserdataApi,{params : data})
        if(result?.data?.record){
            store.dispatch({ type: 'USER_DATA', payload: result?.data?.record });
        }
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const RegisterUserEmailHooks = async (data) => {
    try{
        let result = await axios.post(config?.API_URL+RegisterUserEmailApi,data)
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const VerifyRegisterEmailHooks = async (data) => {
    try{
        let result = await axios.post(config?.API_URL+VerifyRegisterEmailApi,data)
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const getGeoInfoData = async (data) => {
    try {
      let respData = await axios({
        method: "get",
        url: `${config?.getGeoInfo}`,
      });
  
      return {
        status: "success",
        loading: false,
        result: respData.data,
      };
    } catch (err) {
      return {
        status: "failed",
        loading: false,
        error: err.response.data.errors,
      };
    }
};


export const LoginHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+LoginApi,datas ,{headers : headers?.headers});
        console.log("result" , result);

        if(result?.data?.type == "Success"){
            let decryptresult = DecryptString(result?.data?.record , secret);
            store.dispatch({ type: 'USER_DATA', payload: decryptresult?.userdoc });
            localStorage.setItem("email" , decryptresult?.userdoc?.email);
            localStorage.setItem("USRTKN" , btoa(decryptresult?.token));
            localStorage.setItem("usercode" , decryptresult?.userdoc?._id)
            return {type : "Success" , record : decryptresult}
        }
        else{
            return {type : "Failed" , message : result?.data?.message}
        }
        // return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const ChangeEmailHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+ChangeEmailApi ,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const VerifyOldEmailHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+VerifyOldEmailApi ,datas ,{headers : headers?.headers});
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const VerifynewEmailHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+VerifyNewEmailApi ,datas ,{headers : headers?.headers});
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const GetNotificationHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL+GetNotificationApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const GetAllNotificationHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL + GetAllNotificationApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const GetDeliveryMethodHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL + GetDeliveryMethodApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const ReadOneNotificationHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+ReadOneNotificationApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const GetGameItemHooks = async (data) => {
    try {

        console.log("PAYLOAD  ", data);
        
        var resp = await axios ({
            'method': 'GET',
            'url': config?.API_URL + GetGameItemsList,
            'params': data,
        })

        console.log("tokonwer resp",resp.data)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (error) {
        console.log("Error in Get Game Item", error)
    }
}

export const GetOrderItemHooks = async (data) => {
    try {

        console.log("PAYLOAD  ", data);
        
        var resp = await axios ({
            'method': 'GET',
            'url': config?.API_URL + GetUserOrderItemsList,
            'params': data,
        })

        console.log("tokonwer resp",resp.data)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (error) {
        console.log("Error in Get Game Item", error)
    }
}

export const GetOrderListHooks = async (data) => {
    try {

        console.log("PAYLOAD  ", data);
        
        var resp = await axios ({
            'method': 'GET',
            'url': config?.API_URL + GetOrderItemsList,
            'params': data,
        })

        console.log("tokonwer resp",resp.data)
        let response = Decryptdata(resp?.data)

        return response;

    } catch (error) {
        console.log("Error in Get Game Item", error)
    }
}

export const UpdateNotifyTokenHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+UpdateNotifyTokenApi,datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}


export const ChangeGameStatus = async (data) => {    
    
    console.log("Palyload ", data)

    try {
        var resp = await axios({
            "method": "POST",
            "url": config.API_URL + UpdateGameStatus,
            "headers": {
                'Content-Type': 'multipart/form-data',
                "Authorization": localStorage.getItem("token")
            },
            "data":{data:Encryptdata(data)},
        });

        let response = Decryptdata(resp?.data);
        return response;
        
    } catch (error) {
        console.log("Add Social Media Error", error);
    }

}
export const ChangeUserOrderStatus = async (data) => {    
    
    console.log("Palyload ", data)

    try {
        var resp = await axios({
            "method": "POST",
            "url": config.API_URL + UpdateOrderStatus,
            "headers": {
                'Content-Type': 'multipart/form-data',
                "Authorization": localStorage.getItem("token")
            },
            "data":{data:Encryptdata(data)},
        });

        let response = Decryptdata(resp?.data);
        return response;
        
    } catch (error) {
        console.log("Add Social Media Error", error);
    }

}

export const AddReviewHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+AddReviewApi , datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}

export const GetLandingdataHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL + LandingDataApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}

export const GetReviewDetaillistHooks = async (data) => {
    try{        
        let result = await axios.get(config?.API_URL + GetReviewDetailListApi,{params : data})
        return result?.data;
    }
    catch(e){
        console.log("Error on update user data hooks" , e);
    }
}


export const EditReviewHooks = async (data) => {
    try{
        let secret = GenerateSecretKey()
        const headers = {
            headers: {
                // 'Authorization':  `Bearer ${atob( localStorage.getItem("USRDT"))}`,
                'Custom-Header': secret
            }
        }
        var payload = {...data}
        var datas = {}
        datas.input = EncryptString(JSON.stringify(payload) , secret)
        let result = await axios.post(config?.API_URL+EditReviewApi , datas ,{headers : headers?.headers});
        console.log("result" , result);
        return result?.data;
    }
    catch(e){
        console.log("Error on RegisterUserEmailHooks" , e);
    }
}