import { React, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { GrAttachment } from "react-icons/gr";
import { LiaPasteSolid } from "react-icons/lia";
import { ValidateSupportcommon } from "../Validation/UserValidation";
import { toast } from 'react-toastify';
import { CreateNewSupport } from "../Hooks/contactus-supportHooks";
import { useSelector } from "react-redux";
import { GetUserCode } from "../Hooks/UseWallet";

const SupportModal = (props) => {

  const userdata = useSelector((state) => state?.User?.data[0]);

  const [supportmodal, setSupportmodal] = useState(true);

  const usr_id = GetUserCode();


  console.log("SupportModel", props.gamedetail, props.orderdetail);

  const order = props?.orderdetail;
  const game = props?.gamedetail;

  useEffect(() => {

    setData((prev) => ({
      ...prev,
      assetName: game?.name ? game?.name : '',
      orderId: order?._id,
      refundStatus: order?.status ? order?.status : '',
      refundAmount: game?.price ? game?.price : '',
      type: `${order?.createrID == usr_id ? "SELL" : "BUY"}`,
    }));

  }, [])


  const [attachfile, setAttachfile] = useState();
  const [attachfile1, setAttachfile1] = useState();
  const [file, setFile] = useState();

  function handleChange(e) {
    console.log(e.target.files[0].name, "fff");
    setAttachfile(e.target.files[0].name);
    setFile(e?.target?.files[0]);
  }

  //dev-murugavel
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [assetname, setAssername] = useState("");
  const [type, setType] = useState("");
  const [refundstatus, setRefundstatus] = useState("");
  const [refundamount, setRefundamount] = useState("");
  const [errors, setErrors] = useState({});

  const [data, setData] = useState({
    assetName: '',
    orderId: '',
    refundStatus: '',
    type: '',
    refundAmount: '',
    support: '',
    transHash: '',
    title: '',
    descreption: ''

  });

  const [img, setImg] = useState();
  const [img1, setImg1] = useState();

  const [supportImg, setSupportImg] = useState();
  const [supportImg1, setSupportImg1] = useState();

  const handlechange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log("CHANGE ", name, value);

    setData({ ...data, [name]: value });
  }

  const isValidImageType = (file) => {
    const validTypes = ['image/png', 'image/jpeg'];
    return validTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    e.target.value = null; // Clear the file input value

    if (file) {
      if (isValidImageType(file)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachfile(file.name);
          setSupportImg(file);
          setImg(reader.result); // Set the image data URL in the state
          setErrors((prev) => ({
            ...prev,
            image: ""
          }));
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        setErrors((prev) => ({
          ...prev,
          image: "Upload Only PNG or JPG Image"
        }));
      }
    }
  };

  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    e.target.value = null; // Clear the file input value

    if (file) {
      if (isValidImageType(file)) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setAttachfile1(file.name);
          setSupportImg1(file);
          setImg1(reader.result); // Set the image data URL in the state
          setErrors((prev) => ({
            ...prev,
            image1: ""
          }));
        };
        reader.readAsDataURL(file); // Read the file as a data URL
      } else {
        setErrors((prev) => ({
          ...prev,
          image1: "Upload Only PNG or JPG Image"
        }));
      }
    }
  };

  const handlePaste = async () => {
    try {
      // Get pasted text from clipboard
      const clipboardData = await navigator.clipboard.readText();
      if (isAlphanumeric(clipboardData)) {
        // Update input field if valid hexadecimal value
        setData({
          ...data,
          transHash: clipboardData,
        });
        setErrors({ ...errors, transHash: "" })
      } else {
        alert('The pasted content is not a valid hexadecimal value.');
      }
    } catch (error) {
      console.error('Failed to read clipboard contents:', error);
    }
  };

  const isAlphanumeric = (str) => /^[a-zA-Z0-9]+$/.test(str);

  const handleSubmit = async (e) => {
    e.preventDefault();

    var errors = {};
    if (data.assetName === "")
      errors.assetName = "Asset Name Required"

    if (data.refundAmount === "")
      errors.refundAmount = "Refund Amount Required"

    if (data.refundStatus === "")
      errors.refundStatus = "Refund Status Required"

    if (data.support === "")
      errors.support = "Support Required"

    if (data.transHash === "")
      errors.transHash = "Transaction Hash Required"

    if (data.type === "")
      errors.type = "Type Required"

    if (img == undefined)
      errors.image = "Image Required"

    setErrors(errors);


    if (Object.keys(errors).length == 0) {

      const payload = {
        userId: userdata._id,
        orderId: data.orderId,
        assetName: data.assetName,
        refundStatus: data.refundStatus,
        type: data.type,
        refundAmount: data.refundAmount,
        supportMessage: data.support,
        transactionHash: data.transHash,
        image: supportImg
      }
      console.log("PAYLOAD ", payload, userdata);

      var resp = await CreateNewSupport(payload);

      if (resp?.status) {
        toast.success(resp.msg)
        props.onDismiss();

        // setTimeout(() => {
        //   history.push("/support")
        // }, 1000);

      }
      else return toast.error(resp.msg)
    }



  }

  const handleSupportSubmit = async (e) => {
    e.preventDefault();

    var errors = {};

    if (data.title === "")
      errors.title = "Title Required"

    if (data.descreption === "")
      errors.descreption = "Description Required"

    if (img1 == undefined)
      errors.image1 = "Image Required"




    setErrors(errors);
    if (Object.keys(errors).length == 0) {

      const payload = {
        userId: userdata._id,
        title: data.title,
        supportMessage: data.descreption,
        image: supportImg1
      }
      console.log("PAYLOAD ", payload, userdata);

      var resp = await CreateNewSupport(payload);

      if (resp?.status) {
        toast.success(resp.msg)
        props.onDismiss();

        // setTimeout(() => {
        //   history.push("/support")
        // }, 1000);

      }
      else return toast.error(resp.msg)
    }

  }

  console.log("ERRORS ", errors)


  const HandleSupportCommon = async () => {
    try {
      let payload = {
        title: title,
        supportMessage: description,
        image: attachfile
      }
      let val = {
        title: title,
        description: description,
        image: attachfile
      }
      let validate = ValidateSupportcommon(val);
      if (Object.keys(validate)?.length > 0) {
        setErrors(validate)
      }
      else {
        setErrors({});
        props.onDismiss()
      }
    }
    catch (e) {
      console.log("Error on handle support common", e);
    }
  }
  //


  return (
    <>
      <Modal
        show={supportmodal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="py-5 px-4">
          <span className="closebtn" onClick={() => props.onDismiss()}>
            <IoCloseSharp />
          </span>

          <h3 className="vendin whtclr text-center">
            Support Details
          </h3>
          {props?.type == "chat" && <div className="row pt-5">
            <div className="col-12 col-sm-12 col-lg-6 col-xxl-6">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Asset Name</h6>
                <input
                  type="text"
                  name="assetName"
                  readOnly = {true}
                  value={data.assetName}
                  className="form-control g_input supportinput poppins"
                  placeholder="Asset name"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, assetName: "" })
                  }}
                />
                {errors?.assetName && <p className='text-danger fs-6'>{errors.assetName}</p>}
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Type</h6>
                <input
                  type="text"
                  name="type"
                  readOnly = {true}
                  value={data.type}
                  className="form-control g_input supportinput poppins"
                  placeholder="type"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, type: "" })
                  }}
                />
                {errors?.type && <p className='text-danger fs-6'>{errors.type}</p>}

              </div>
{/* 
              {data?.orderId &&
                <div className="mb-4">
                  <h6 className="whtclr poppins mb-3">Order ID</h6>
                  <input
                    type="text"
                    name="type"
                    disabled={true}
                    value={data.orderId}
                    className="form-control g_input supportinput poppins"
                    placeholder="type"
                  />
                </div>
              } */}
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Support</h6>
                <input
                  type="text"
                  name="support"
                  value={data.support}
                  className="form-control g_input supportinput poppins"
                  placeholder="support"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, support: "" })
                  }}
                />
                {errors?.support && <p className='text-danger fs-6'>{errors.support}</p>}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6 col-xxl-6">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Refund Status</h6>
                <input
                  type="text"
                  name="refundStatus"
                  readOnly = {true}
                  value={data.refundStatus}
                  className="form-control g_input supportinput poppins"
                  placeholder="Refund Status"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, refundStatus: "" })
                  }}
                />
                {errors?.refundStatus && <p className='text-danger fs-6'>{errors.refundStatus}</p>}
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Refund Amount</h6>
                <input
                  type="text"
                  readOnly = {true}
                  name="refundAmount"
                  value={data.refundAmount}
                  className="form-control g_input supportinput poppins"
                  placeholder="Refund Amount"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({...errors, refundAmount: ""})
                  }}
                />
                {errors?.refundAmount && <p className='text-danger fs-6'>{errors.refundAmount}</p>}
              </div>
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Transaction Hash</h6>

                <div className="pos">
                  <input
                    type="text"
                    name="transHash"
                    value={data.transHash}
                    className="form-control g_input supportinput poppins"
                    placeholder="Transaction Hash"
                    onChange={(e) => {
                      handlechange(e)
                      setErrors({ ...errors, transHash: "" })
                    }}
                  />
                  <LiaPasteSolid className="pasteicon" onClick={handlePaste} />

                </div>


                {errors?.transHash && <p className='text-danger fs-6'>{errors.transHash}</p>}

              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Attachment</h6>
                <div className="pos">
                  <input
                    type="file"
                    name=""
                    onChange={handleFileChange}
                    className="attach_input"
                  />
                  <span className="attachname poppins ps-2">
                    {attachfile ? attachfile : "Attachment name"}
                  </span>
                  <GrAttachment className="attach_icon" />
                </div>
                {errors?.image && <p className='text-danger fs-6'>{errors.image}</p>}
                {img ? (
                  <div className=''>
                    <img src={img} className='up_img mt-3 mb-3' alt='Game Image' />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3">
              <button
                className="header__connectBtn rounded-2 poppins"
                onClick={() => props.onDismiss()}
              >
                Cancel
              </button>
              <button
                className="header__connectBtn rounded-2 poppins"
                onClick={(e) => {
                  // props.onDismiss()
                  handleSubmit(e)
                }}>
                Submit
              </button>
            </div>
          </div>}

          {props?.type == "commonsupport" && <div className="row pt-5">
            <div className="col-12 col-sm-12 col-lg-12 col-xxl-12">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Support Title</h6>
                <input
                  type="text"
                  name="title"
                  value={data.title}
                  className="form-control g_input supportinput poppins"
                  placeholder="Support Title"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, title: "" })
                  }}
                />
                {errors?.title && <p className='text-danger fs-6'>{errors.title}</p>}
              </div>

              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Support Description</h6>
                <textarea
                  className="form-control g_input supportinput poppins"
                  name="descreption"
                  value={data.descreption}
                  placeholder="support"
                  onChange={(e) => {
                    handlechange(e)
                    setErrors({ ...errors, descreption: "" })
                  }}
                />
                {errors?.descreption && <p className='text-danger fs-6'>{errors.descreption}</p>}

              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12">
              <div className="mb-4">
                <h6 className="whtclr poppins mb-3">Attachment</h6>
                <div className="pos">
                  <input
                    type="file"
                    name=""
                    onChange={handleFileChange1}
                    className="attach_input"
                  />
                  <span className="attachname poppins ps-2">
                    {attachfile1 ? attachfile1 : "Attachment name"}
                  </span>
                  <GrAttachment className="attach_icon" />
                </div>
                {errors?.image1 && <p className='text-danger fs-6'>{errors.image1}</p>}
                {img1 ? (
                  <div className=''>
                    <img src={img1} className='up_img mt-3 mb-3' alt='Game Image' />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3">
              <button
                className="header__connectBtn rounded-2 poppins"
                onClick={() => props.onDismiss()}
              >
                Cancel
              </button>
              <button
                className="header__connectBtn rounded-2 poppins"
                onClick={handleSupportSubmit}
              >
                Submit
              </button>
            </div>
          </div>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SupportModal;
