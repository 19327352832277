import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

function Instrctmodal(props) {
  
    const[instmodals, setInstmodals] = useState(true)

    return (
        <div>
            <Modal show={instmodals} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body className='py-5 px-4'>
                    <span className='closebtn' onClick={() => props.onDismiss()}><IoCloseSharp /></span>
                    <h3 className='vendin whtclr text-center mb-4'>User Instruction</h3>
                    <p className='whtclr poppins'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        <br /> <br />
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    </p> 
                    </Modal.Body>
            </Modal>
        </div>
    );
}

export default Instrctmodal;